import { AxiosError, AxiosResponse } from 'axios';
import httpCommon from '@config/http-common';
import { ITicketCancel, ITicketCancelResponse } from '@models/TicketCancel.models';

export const cancelTicketApi = async (data: ITicketCancel) => {
  try {
    const response: AxiosResponse<ITicketCancelResponse> = await httpCommon.post(
      'TicketCancellation/cancel-ticket-by-id-and-guid',
      data,
    );
    return response?.data || [];
  } catch (error) {
    throw (error as AxiosError).response?.data;
  }
};
