import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chance4BetArray } from '@models/Chance4Digits.models';

interface IChancePayloadAction {
  simpleChanceBet: string[];
  betAmount: number;
}

const initialState: Chance4BetArray = {
  arrayNumbers: [],
  betAmount: 0,
};

const simpleChanceSlice = createSlice({
  name: 'simpleChanceFourBet',
  initialState,
  reducers: {
    actionSetSimpleChanceArray: (state, action: PayloadAction<IChancePayloadAction>) => {
      state.arrayNumbers = action.payload.simpleChanceBet;
      state.betAmount = action.payload.betAmount;
    },
    actionCleanSimpleChanceState: (state) => {
      state.arrayNumbers = [];
      state.betAmount = 0;
    },
  },
});

export const { actionSetSimpleChanceArray, actionCleanSimpleChanceState } =
  simpleChanceSlice.actions;

export const simpleChance4Reducer = simpleChanceSlice.reducer;
