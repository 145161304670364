import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const animalsLotteryStatusSelector = createSelector(
  (state: RootState) => state.animalsLottery.status,
  (status) => {
    return status;
  },
);

export const animalsLotterySelector = createSelector(
  (state: RootState) => state.animalsLottery.activeAnimalsByLottery,
  (activeAnimalsByLottery) => {
    return activeAnimalsByLottery;
  },
);

export const animalsLotteryLoadingSelector = createSelector(
  (state: RootState) => state.animalsLottery.isLoading,
  (isLoading) => {
    return isLoading;
  },
);

export const animalsLotterySelectorByLotteryId = createSelector(
  (state: RootState) => state.animalsLottery,
  (animalsLottery) => {
    return animalsLottery;
  },
);
