import React from 'react';
import Button from '@mui/material/Button';
import {
  buttonStyle,
  buttonStyleActive,
} from '@components/Toolbar/components/MenuItemApp/MenuItemAppStyles';
import { ACTION_LINKS } from '@components/Toolbar/components/MenuApp/links';
import { Menu, MenuItem } from '@mui/material';

import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';

import styles from './SignOutMenu.module.scss';
import { grey } from '@mui/material/colors';
import clsx from 'clsx';

interface SignOutMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  isActive: boolean;
  onClose: () => void;
  checkLinkIsActive: (link: string) => boolean;
  username: string | undefined;
  onOpenDialogSignOut: () => void;
}

const SignOutMenu = ({
  anchorEl,
  open,
  onClick,
  isActive,
  onClose,
  checkLinkIsActive,
  username,
  onOpenDialogSignOut,
}: SignOutMenuProps) => {
  const buttonStyleFinal = isActive ? { ...buttonStyle, ...buttonStyleActive } : buttonStyle;
  const menuItemStyle = {
    color: grey[50],
    fontWeight: 'bold',
    textDecoration: 'none',
  };

  const buildSignOutItems = ACTION_LINKS.map((menuLink, index) => {
    if (menuLink.path == '/sign-out') {
      return (
        <MenuItem
          className={clsx(styles.MenuList, {
            [styles.Selected]: checkLinkIsActive(menuLink.path),
          })}
          sx={{ height: '70px' }}
          onClick={onClose}
          key={`menu-sign-out-${menuLink.label}-${index}`}
        >
          <Link className={clsx(styles.Link)} to='#' onClick={onOpenDialogSignOut}>
            <Typography
              className={clsx({
                [styles.LinkSelected]: checkLinkIsActive(menuLink.path),
              })}
              sx={menuItemStyle}
              component='span'
            >
              {menuLink.label}
            </Typography>
          </Link>
        </MenuItem>
      );
    }
    <MenuItem
      className={clsx(styles.MenuList, {
        [styles.Selected]: checkLinkIsActive(menuLink.path),
      })}
      sx={{ height: '70px' }}
      onClick={onClose}
      key={`menu-sign-out-${menuLink.label}-${index}`}
    >
      <Link className={clsx(styles.Link)} to={menuLink.path}>
        <Typography
          className={clsx({
            [styles.LinkSelected]: checkLinkIsActive(menuLink.path),
          })}
          sx={menuItemStyle}
          component='span'
        >
          {menuLink.label}
        </Typography>
      </Link>
    </MenuItem>;
  });

  return (
    <div>
      <Button
        sx={buttonStyleFinal}
        variant='text'
        id='demo-positioned-sign-out-button'
        aria-controls={open ? 'demo-positioned-sign-out-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={onClick}
      >
        {username}
      </Button>
      <Menu
        className={styles.SignOutMenu}
        classes={{
          paper: styles.MenuPaper,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        {buildSignOutItems}
      </Menu>
    </div>
  );
};

export default SignOutMenu;
