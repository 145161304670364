import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuApp from '@components/Toolbar/components/MenuApp/MenuApp';

export default function ButtonAppBar() {
  return (
    <Box>
      <AppBar position='static'>
        <Toolbar>
          <Typography variant='h6' component='div' sx={{ fontWeight: 'bold', marginRight: 2 }}>
            GanaPaga
          </Typography>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <MenuApp />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
