import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { raffleReducer } from '@features/AnimalsGame/Raffles/RaffleSlice';
import { animalsLotteryReducer } from '@controllers/AnimalsLottery/AnimalsLotterySlice';
import { loginReducer } from '@features/Login/LoginSlice';
import { animalsReducer } from '@features/AnimalsGame/Animals/AnimalsSlice';
import { lotteryReducer } from '@features/AnimalsGame/Lottery/LotterySlice';
import { animalsBetReducer } from '@features/AnimalsGame/AnimalsBet/AnimalsBetSlice';
import { ticketReducer } from '@features/Ticket/TicketSlice';
import { currencyReducer } from '@features/Currency/Currency.slice';
import { swapBetReducer } from '@features/Chance3Digits/SwapBet/SwapBet.slice';
import { tripleSeriesBetReducer } from '@features/Chance3Digits/TripleSeriesBet/TripleSeriesBet.slice';
import { chance3DigitsReducer } from '@controllers/Chance3Digits/Chance3Digits.slice';
import { chanceRaffleReducer } from '@features/Chance3Digits/Chance3Raffles/Chance3Raffle.slice';
import { seriesBetReducer } from '@features/Chance3Digits/SeriesBet/SeriesBet.slice';
import { simpleChanceReducer } from '@features/Chance3Digits/SimpleChanceBet/SimpleChance.slice';
import { swapChance4BetReducer } from '@features/Chance4Digits/SwapBet/SwapBet.slice';
import { chance4RaffleReducer } from '@features/Chance4Digits/Chance4Raffles/Chance4Raffle.slice';
import { tripleSeriesChance4BetReducer } from '@features/Chance4Digits/TripleSeriesBet/TripleSeriesBet.slice';
import { seriesChance4BetReducer } from '@features/Chance4Digits/SeriesBet/SeriesBet.slice';
import { simpleChance4Reducer } from '@features/Chance4Digits/SimpleChanceBet/SimpleChance.slice';
import { chance4DigitsReducer } from '@controllers/Chance4Digits/Chance4Digits.slice';
import { ticketCancelReducer } from '@controllers/TicketCancel/TicketCancelSlice';
import { ticketCancelItemReducer } from '@features/TicketCancelItem/TicketCancelItem.slice';

import { chanceZodiacRaffleReducer } from '@features/ChanceZodiac/ChanceZodiacRaffles/ChanceZodiacRaffle.slice';
// import { seriesChanceZodiacBetReducer } from '@features/ChanceZodiac/SeriesBet/SeriesBet.slice';
// import { simpleChanceZodiacReducer } from '@features/ChanceZodiac/SimpleChanceBet/SimpleChance.slice';
import { chanceZodiacReducer } from '@controllers/ChanceZodiac/ChanceZodiac.slice';
import { ticketWinnerReducer } from '@controllers/TicketWinner/TicketWinnerSlice';
import { ticketWinnerStepperReducer } from '@features/TicketWinnerStepper/TicketWinnerStepper.slice';
import { customerReducer } from '@controllers/Customer/Customer.slice';
import { signOutReducer } from '@features/SignOut/SignOutSlice';
import { workShiftReducer } from '@controllers/CloseWorkShift/CloseWorkshiftSlice';
import { animalTypeGameReducer } from '@features/AnimalsGame/AnimalTypeGame/AnimalTypeGameSlice';

export const store = configureStore({
  reducer: {
    animalsLottery: animalsLotteryReducer,
    currency: currencyReducer,
    lottery: lotteryReducer,
    raffles: raffleReducer,
    animals: animalsReducer,
    animalsBet: animalsBetReducer,
    login: loginReducer,
    ticket: ticketReducer,
    chance3Digits: chance3DigitsReducer,
    chance3SwapBet: swapBetReducer,
    chance3TripleSeriesBet: tripleSeriesBetReducer,
    chance3Raffles: chanceRaffleReducer,
    chance3DigitsSeriesBet: seriesBetReducer,
    chance3SimpleChanceBet: simpleChanceReducer,
    chance4Digits: chance4DigitsReducer,
    chance4SwapBet: swapChance4BetReducer,
    chance4TripleSeriesBet: tripleSeriesChance4BetReducer,
    chance4Raffles: chance4RaffleReducer,
    chance4DigitsSeriesBet: seriesChance4BetReducer,
    chance4SimpleChanceBet: simpleChance4Reducer,
    chanceZodiac: chanceZodiacReducer,
    chanceZodiacRaffles: chanceZodiacRaffleReducer,
    ticketCancel: ticketCancelReducer,
    ticketCancelItem: ticketCancelItemReducer,
    ticketWinner: ticketWinnerReducer,
    ticketWinnerStepper: ticketWinnerStepperReducer,
    customer: customerReducer,
    signOut: signOutReducer,
    workShift: workShiftReducer,
    animalTypeGame: animalTypeGameReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
