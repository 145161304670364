import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCancellableTickets } from './TicketCancel.api';
import { ITicket } from '@models/Ticket.models';

interface ITicketCancelState {
  status: 'success' | 'failed' | 'idle' | 'pending';
  isLoading: boolean;
  cancellableTickets: ITicket[];
}

const initialState: ITicketCancelState = {
  status: 'idle',
  isLoading: false,
  cancellableTickets: [],
};

export const getCancellableTicketsAsync = createAsyncThunk(
  'ticketCancel/getCancellableTicketsAsync',
  getCancellableTickets,
);

export const ticketCancelSlice = createSlice({
  name: 'ticketCancel',
  initialState,
  reducers: {
    actionSetCancellableTickets: (state, action) => {
      state.cancellableTickets = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCancellableTicketsAsync.pending, (state) => {
        state.isLoading = true;
        state.cancellableTickets = [];
        state.status = 'pending';
      })
      .addCase(getCancellableTicketsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.cancellableTickets = action.payload;
        state.status = 'success';
      })
      .addCase(getCancellableTicketsAsync.rejected, (state) => {
        state.isLoading = false;
        state.cancellableTickets = [];
        state.status = 'failed';
      });
  },
});

export const { actionSetCancellableTickets } = ticketCancelSlice.actions;

export const ticketCancelReducer = ticketCancelSlice.reducer;
