import React from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import CurrencyList from '@components/CurrencyList/CurrencyList';
import { useCurrency } from '@features/Currency/Currency.hook';
import Button from '@mui/material/Button';

interface SelectCurrencyModalProps {
  isOpen: boolean;
}

const SelectCurrencyModal = ({ isOpen }: SelectCurrencyModalProps) => {
  const { selectedCurrency, currencyList, onChangeCurrencyDialog, applyChangeCurrency } =
    useCurrency();

  return (
    <Dialog open={isOpen}>
      <DialogTitle variant={'h5'}>Seleccione tipo de moneda</DialogTitle>
      <DialogContent>
        <Typography variant={'body1'}>
          Seleccione el tipo de moneda con la que va a trabajar
        </Typography>
        <CurrencyList
          hideTitle={true}
          currencySettingsList={currencyList}
          selectedCurrentSetting={selectedCurrency}
          onSelectCurrency={onChangeCurrencyDialog}
        />
        <DialogActions>
          <Button variant='contained' onClick={applyChangeCurrency}>
            Confirmar moneda
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default SelectCurrencyModal;
