import { IWorkshiftDetail, IWorkShiftDetailResponse } from '@models/WorkShift.models';
import { AxiosError, AxiosResponse } from 'axios';
import httpCommon from '@config/http-common';

export const getCurrentWorkShiftInfo = async () => {
  try {
    const response: AxiosResponse<IWorkShiftDetailResponse<IWorkshiftDetail>> =
      await httpCommon.get('Workshift/get-workshift-info');
    return response?.data?.response || {};
  } catch (error) {
    if (error instanceof AxiosError) {
      return error.response?.data?.message || 'unknown error';
    }
    return 'unknown error';
  }
};
