import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import RaffleBetTicketInfo from '@components/AnimalTicketInfo/components/RaffleBetTicketInfo';

import { ITicketAnimal } from '@models/Ticket.models';
import { ANIMAL_GAME_TYPE_LABEL } from '@constants/app.constants';

interface AnimalTicketInfoProps {
  rafflesAnimalBet: ITicketAnimal[];
  deleteLotteryBet: (lotteryId: number) => void;
  deleteRaffleBet: (lotteryId: number, raffleId: number) => void;
  setEditableRaffle: (lotteryId: number, raffleId: number, finishEdit?: boolean) => void;
  updateAnimalBetAmount: (
    lotteryIndex: number,
    raffleIndex: number,
    animalIndex: number,
    amount: string,
  ) => void;
  formHasErrors: boolean;
  formatCurrency: (amount: number) => string;
  isPrintingTicket: boolean;
}

function AnimalTicketInfo({
  rafflesAnimalBet,
  deleteLotteryBet,
  deleteRaffleBet,
  setEditableRaffle,
  updateAnimalBetAmount,
  formHasErrors,
  formatCurrency,
  isPrintingTicket,
}: AnimalTicketInfoProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ flex: 1 }}>
        {rafflesAnimalBet.map((animalsRaffle, lotteryIndex) => (
          <Box
            sx={{ paddingLeft: 1 }}
            key={`animal-lottery-bet-${ANIMAL_GAME_TYPE_LABEL?.get(
              animalsRaffle.animalGameTypeId,
            )}-${animalsRaffle.lotteryId}`}
          >
            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
              <Grid item xs={8}>
                <Typography component='h6'>
                  <strong>
                    {ANIMAL_GAME_TYPE_LABEL?.get(animalsRaffle.animalGameTypeId) ?? ''}
                  </strong>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  disabled={isPrintingTicket}
                  onClick={() => deleteLotteryBet(animalsRaffle.lotteryId)}
                >
                  <SvgIcon component={DeleteIcon} />
                </IconButton>
              </Grid>
            </Grid>
            <RaffleBetTicketInfo
              rafflesAnimalBet={animalsRaffle.rafflesAnimalBet}
              deleteRaffleBetAction={(raffleId: number) =>
                deleteRaffleBet(animalsRaffle.lotteryId, raffleId)
              }
              setEditableRaffleAction={(raffleId: number, finishEdit?: boolean) =>
                setEditableRaffle(lotteryIndex, raffleId, finishEdit)
              }
              updateAnimalBetAmount={(raffleIndex: number, animalIndex: number, amount: string) =>
                updateAnimalBetAmount(lotteryIndex, raffleIndex, animalIndex, amount)
              }
              formHasErrors={formHasErrors}
              formatCurrency={formatCurrency}
              animalGameTypeId={animalsRaffle.animalGameTypeId}
              isPrintingTicket={isPrintingTicket}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default AnimalTicketInfo;
