import { ITicketPrint, PrintDataItem } from '@models/Ticket.models';

import QzTray from 'qz-tray';
import { DateTime } from 'luxon';
import {
  boldOff,
  boldOn,
  centerAlign,
  defaultSize,
  encodingCp1522,
  leftAlign,
  lineBreak,
  printInit,
  rightAlign,
} from '@constants/printer.constants';
import { certificatePromise, signDataOnServer } from './sign.helpers';

export const Print = async (ticketPrint: ITicketPrint) => {
  await Init(ticketPrint);
};

const Init = async (ticketPrint: ITicketPrint) => {
  const dataToPrint = PrepareData(ticketPrint);
  await ConnectAndPrint(dataToPrint);
};

const ConnectAndPrint = async (printData: (PrintDataItem | string)[]) => {
  // Filter out non-PrintDataItem strings and transform them into PrintDataItem objects
  const printDataItems: PrintDataItem[] = printData.map(item => {
    if (typeof item === 'string') {
      return {
        data: item,
        format: 'plain', // assuming a default format
        options: { language: 'default', dotDensity: 'default' }, // assuming default options
        type: 'default' // assuming a default type
      };
    } else {
      return item;
    }
  });

  // Disconnect QZ Tray websocket if it's active
  if (QzTray.websocket.isActive()) {
    try {
      // Wait for the previous disconnect operation to complete
      await QzTray.websocket.disconnect();
    } catch (disconnectError) {
      console.error('Error disconnecting from QZ Tray:', disconnectError);
    }
  }

  // Clean up resources
  QzTray.security.setSignaturePromise(null);
  QzTray.security.setCertificatePromise(null);

  // Set new certificate promise
  certificatePromise();

  QzTray.security.setSignatureAlgorithm('SHA512'); // Since 2.1
  QzTray.security.setSignaturePromise(function (toSign: any) {
    return function (resolve: any, reject: any) {
      signDataOnServer(toSign)
        .then((base64Signature) => resolve(base64Signature))
        .catch((signError) => {
          console.error('Error signing data on the server:', signError);
          // Proceed with printing even if sign data is invalid
          resolve(null); // Pass null or some default value to indicate invalid sign data
        });
    };
  });

  // Connect to QZ Tray websocket
  try {
    await QzTray.websocket.connect();

    const printerNames = ['POS-58', 'XP-58'];

    const printPromises = printerNames.map(async (printerName) => {
      try {
        const printer = await QzTray.printers.find(printerName);
        const config = QzTray.configs.create(printer, { encoding: 'Cp1252' });
        await QzTray.print(config, printDataItems);
      } catch (printerError) {
        console.error(`Error printing with QZ Tray - Printer ${printerName}:`, printerError);
      }
    });

    await Promise.all(printPromises);
  } catch (connectError) {
    console.error('Error connecting to QZ Tray:', connectError);
  } finally {
    // Disconnect from QZ Tray websocket after printing
    try {
      await QzTray.websocket.disconnect();
    } catch (disconnectError) {
      console.error('Error disconnecting from QZ Tray:', disconnectError);
    }
  }
};



const PrepareData = (ticketPrint: ITicketPrint) => {
  return [
    {
      type: 'raw',
      format: 'plain',
      data: '',
      options: { language: 'ESCPOS', dotDensity: '8' },
    },
    printInit,
    encodingCp1522,
    centerAlign,
    defaultSize,
    // emModeOn,
    boldOn,
    ticketPrint.ticketCompanyName,
    boldOff,
    lineBreak + lineBreak,
    // emModeOff,
    centerAlign,
    `Fecha: ${DateTime.fromISO(ticketPrint.ticketCreatedAt).toLocaleString(
      DateTime.DATETIME_SHORT,
    )} ${ticketPrint.currencyCode.toUpperCase()}` + lineBreak,
    centerAlign,
    `Tiquete N° ${ticketPrint.ticketId} - ${ticketPrint.ticketNumber}${lineBreak}`,
    '--------------------------------',
    leftAlign,
    lineBreak,
    ticketPrint.ticketData,
    lineBreak,
    rightAlign,
    `TOTAL APOSTADO: ${ticketPrint.ticketTotalAmount}` + lineBreak,
    lineBreak,
    centerAlign,
    `Vence en ${ticketPrint.ticketDueDays} días` + lineBreak + lineBreak + lineBreak + lineBreak + lineBreak,
  ];
};
