import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const selectedRafflesSelector = createSelector(
  (state: RootState) => state.raffles.selectedRaffles,
  (selectedRaffles) => {
    return selectedRaffles;
  },
);

export const activeRafflesSelector = createSelector(
  (state: RootState) => state.raffles.activeRaffles,
  (activeRaffles) => {
    return activeRaffles;
  },
);
