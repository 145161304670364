import { useAppDispatch, useAppSelector } from '@app/hooks';
import { orderBy } from 'lodash';

import { IAnimalDetail } from '@models/Animal.models';
import {
  actionCleanAnimalsState,
  actionDeselectAnimal,
  actionSelectAnimal,
  actionSelectMassiveAnimals,
} from '@features/AnimalsGame/Animals/AnimalsSlice';
import {
  actionCleanAnimalsBetState,
  actionSetSelectedAnimalBet,
} from '@features/AnimalsGame/AnimalsBet/AnimalsBetSlice';
import { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { SEARCH_ANIMALS_SHORTCUT } from '@constants/shortcuts.constants';
import { ANIMAL_FRUITS_GAME_TYPE } from '@constants/app.constants';
import { ANIMALS_PATH } from '@constants/url.constants';
import { animalsSelector, selectedAnimalsSelector } from '@selectors/animals.selector';
import { selectedAnimalBetSelector } from '@selectors/animalsBet.selector';
import { raffleSettingsSelector } from '@selectors/animalcurrency.selector';
import { selectedLotteryFruitCombinedSelector } from '@selectors/lottery.selector';
import { selectedAnimalTypeGameSelector } from '@selectors/animalTypeGame.selector';
import { selectedRafflesSelector } from '@selectors/raffle.selector';

export const useAnimalsState = () => {
  const dispatch = useAppDispatch();
  const searchAnimalFruitRef = useRef<HTMLInputElement>(null);

  const selectedAnimals = useAppSelector(selectedAnimalsSelector);
  const animals = useAppSelector(animalsSelector);
  const selectedAnimalBet = useAppSelector(selectedAnimalBetSelector);
  const selectedLotterySetting = useAppSelector(raffleSettingsSelector);
  const selectedLotteryFruitCombined = useAppSelector(selectedLotteryFruitCombinedSelector);
  const selectedAnimalTypeGame = useAppSelector(selectedAnimalTypeGameSelector);
  const selectedRaffles = useAppSelector(selectedRafflesSelector);

  const [disableAnimalsCard, setDisableAnimalsCard] = useState(false);
  const [disableFruitsCard, setDisableFruitsCard] = useState(true);
  const [animalDetail, setAnimalDetail] = useState<IAnimalDetail>({} as IAnimalDetail);
  const [animalList, setAnimalList] = useState<IAnimalDetail[]>(animals);
  const [animalId, setAnimalId] = useState<string>();
  const [fruitId, setFruitId] = useState<string>();
  const [searchAnimalFruit, setSearchAnimalFruit] = useState<string>('');
  const [initialSelectedGameType, setInitialSelectedGameType] =
    useState<number>(selectedAnimalTypeGame);
  const canSelectANewAnimal = selectedLotterySetting.maxAnimalsByTicket > selectedAnimalBet.length;

  useHotkeys(
    SEARCH_ANIMALS_SHORTCUT,
    () => {
      if (searchAnimalFruitRef.current) {
        searchAnimalFruitRef.current.focus();
        searchAnimalFruitRef.current.value = '';
      }
    },
    {
      enabled: window.location.pathname === ANIMALS_PATH,
    },
  );

  const addAnimalToBet = (animal: IAnimalDetail) => {
    const newAnimals = [...selectedAnimalBet, { ...animal, betAmount: null }];
    const reOrderAnimalsByCode = orderBy(newAnimals, ['animalId'], ['asc']);
    dispatch(actionSetSelectedAnimalBet(reOrderAnimalsByCode));
  };

  const addMassiveAnimalsToBet = (animals: IAnimalDetail[]) => {
    const newAnimals = animals.map((animal) => ({ ...animal, betAmount: null }));
    const newAnimalsBet = [...selectedAnimalBet, ...newAnimals];
    const reOrderAnimalsByCode = orderBy(newAnimalsBet, ['animalId'], ['asc']);
    dispatch(actionSetSelectedAnimalBet(reOrderAnimalsByCode));
  };

  const removeAnimalFromBet = (animal: IAnimalDetail) => {
    const newAnimals = selectedAnimalBet.filter((a) => a.animalId !== animal.animalId);
    dispatch(actionSetSelectedAnimalBet(newAnimals));
  };
  const unSelectAnimal = (animal: IAnimalDetail) => {
    const newAnimals = selectedAnimals.filter(
      (animalSelected) => animalSelected.animalId !== animal.animalId,
    );
    dispatch(actionDeselectAnimal(newAnimals));
    removeAnimalFromBet(animal);
  };
  const selectAnimalAction = (animal: IAnimalDetail) => {
    const animalExists = selectedAnimals.some(
      (animalItem) => animalItem.animalId === animal.animalId,
    );

    if (animalExists) {
      handleExistingAnimal(animal);
      unSelectAnimal(animal);
    } else {
      handleNewAnimal(animal);
    }
  };

  const handleExistingAnimal = (animal: IAnimalDetail) => {
    if (!animal.animalIsFruit) {
      setAnimalId(undefined);
    } else {
      setFruitId(undefined);
    }
  };

  const handleNewAnimal = async (animal: IAnimalDetail) => {
    dispatch(actionSelectAnimal(animal));
    setAnimalDetail(animal);

    if (!selectedLotteryFruitCombined) {
      addAnimalToBet(animal);
    } else {
      handleCombineLottery(animal);
    }
  };

  const unSelectAnimalAndCard = (animalsToDeselect: IAnimalDetail[]) => {
    const deselectAnimalIds = animalsToDeselect.map((animal) => animal.animalId);
    const newAnimals = selectedAnimals.filter(
      (animalSelected) => !deselectAnimalIds.includes(animalSelected.animalId),
    );
    dispatch(actionDeselectAnimal(newAnimals));
  };

  // todo -> dejar habilitado el animalito que selecciono en caso de que se quiere anular la selección
  const handleCombineLottery = (animal: IAnimalDetail) => {
    if (selectedLotteryFruitCombined && !animal.animalIsFruit) {
      setAnimalId(animal.animalId);
      setDisableAnimalsCard(true);
      setDisableFruitsCard(false);
    } else if (selectedLotteryFruitCombined && animal.animalIsFruit) {
      setFruitId(animal.animalId);
      setDisableAnimalsCard(true);
      setDisableFruitsCard(false);
    }
  };

  useEffect(() => {
    if (animalId && fruitId) {
      const currentAnimal = animals.find((a) => a.animalId == animalId);
      const currentFruit = animals.find((a) => a.animalId == fruitId);
      if (currentAnimal && currentFruit) {
        const updatedAnimalDetail = {
          ...animalDetail,
          animalId: `${animalId}-${fruitId}`,
          animalName: `${animals.find((a) => a.animalId == animalId)?.animalName}-${
            animals.find((a) => a.animalId == fruitId)?.animalName
          }`,
        };
        dispatch(actionSelectAnimal(updatedAnimalDetail));
        addAnimalToBet(updatedAnimalDetail);
        setAnimalId(undefined);
        setFruitId(undefined);
        unSelectAnimalAndCard([currentAnimal, currentFruit]);
      }
    }
    if (animalId) {
      setDisableAnimalsCard(true);
    } else {
      setDisableAnimalsCard(false);
    }
    if (animalId && !fruitId) {
      setDisableFruitsCard(false);
    } else {
      setDisableFruitsCard(true);
    }
  }, [animalDetail, animalId, fruitId]);

  useEffect(() => {
    if (selectedAnimalTypeGame !== initialSelectedGameType) {
      setAnimalId(undefined);
      setFruitId(undefined);
      setInitialSelectedGameType(selectedAnimalTypeGame);
      setAnimalList(animals);
    } else {
      if (animals.length > 0) {
        setAnimalList(animals);
      } else {
        dispatch(actionCleanAnimalsBetState());
        setAnimalList([] as IAnimalDetail[]);
        dispatch(actionCleanAnimalsState());
        setDisableFruitsCard(false);
        setDisableFruitsCard(true);
      }
    }
  }, [animals, selectedAnimalTypeGame]);

  useEffect(() => {
    if (selectedAnimals.length > 0 || selectedAnimalBet.length > 0) {
      const animalsBetInputs: NodeListOf<HTMLElement> =
        document.querySelectorAll('.set-animal-bet');
      if (animalsBetInputs && animalsBetInputs.length > 0) {
        animalsBetInputs[0].focus();
      }
    }
    if (selectedRaffles.length === 0) {
      setDisableAnimalsCard(false);
      setDisableFruitsCard(true);
    }
  }, [selectedAnimals, selectedAnimalBet]);

  const searchAnimalFruitInput = (searchInput: string) => {
    setSearchAnimalFruit(searchInput);
  };

  const filterAnimalsFruitBySearchInput = () => {
    if (searchAnimalFruit.length > 0) {
      const filteredAnimals = animals.filter(
        (animal) =>
          animal.animalName.toLowerCase().includes(searchAnimalFruit.toLowerCase()) ||
          animal.animalUserId.toLowerCase().includes(searchAnimalFruit.toLowerCase()),
      );
      setAnimalList(filteredAnimals);
    } else {
      setAnimalList(animals);
    }
  };

  const isAnimalFilterHasOnlyNumbers = () => {
    return !isNaN(Number(searchAnimalFruit));
  };

  const isAnimalAlreadySelected = (animalId: string) => {
    return selectedAnimals.some((animal) => animal.animalId === animalId);
  };

  const massiveSelectAnimals = () => {
    if (selectedAnimalTypeGame !== ANIMAL_FRUITS_GAME_TYPE && searchAnimalFruit !== '') {
      let animalListFinal: IAnimalDetail | undefined;

      if (isAnimalFilterHasOnlyNumbers()) {
        animalListFinal = animalList.find((animal) => animal.animalUserId === searchAnimalFruit);
      } else {
        if (animalList.length === 1) {
          animalListFinal = animalList[0];
        }
      }

      if (animalListFinal && !isAnimalAlreadySelected(animalListFinal.animalId)) {
        dispatch(actionSelectMassiveAnimals([animalListFinal]));
        addMassiveAnimalsToBet([animalListFinal]);
        setAnimalList([...animals]);
        setSearchAnimalFruit('');
      }
    }
  };

  return {
    canSelectANewAnimal: canSelectANewAnimal,
    animals: animalList,
    selectedAnimals: selectedAnimals,
    selectAnimalAction,
    disableAnimalsCard: disableAnimalsCard,
    disableFruitsCard: disableFruitsCard,
    selectedLotteryFruitCombined,
    animalId,
    fruitId,
    selectedAnimalBet,
    selectedAnimalTypeGame,
    searchAnimalFruitRef,
    searchAnimalFruitInput,
    searchAnimalFruit,
    filterAnimalsFruitBySearchInput,
    massiveSelectAnimals,
    selectedRaffles,
  };
};
