import { ITicketWinner, ITicketWinnerResponse } from '@models/TicketWinner.models';
import { getTicketWinnerInfo } from './TicketWinner.api';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface ITicketWinnerState {
  status: 'success' | 'failed' | 'idle' | 'pending';
  isLoading: boolean;
  ticketWinner?: ITicketWinner[];
  error?: string;
}

const initialState: ITicketWinnerState = {
  status: 'idle',
  isLoading: false,
  ticketWinner: [],
  error: '',
};

export const getTicketWinnerInfoAsync = createAsyncThunk(
  'ticketWinner/get-ticket-winner-info',
  async ({
    ticketNumber,
    ticketCode,
  }: {
    ticketNumber: number | undefined;
    ticketCode: string | undefined;
  }) => {
    const response = await getTicketWinnerInfo(ticketNumber, ticketCode);
    return response as ITicketWinnerResponse<ITicketWinner[]>;
  },
);

export const tickeWinnerSlice = createSlice({
  name: 'ticketWinnerInfo',
  initialState,
  reducers: {
    actionCleanTicketWinner: () => {
      return initialState;
    },
    actionCleanTicketWinnerRequest: (state) => {
      state.status = 'idle';
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTicketWinnerInfoAsync.pending, (state) => {
        state.isLoading = true;
        state.status = 'pending';
        state.error = '';
      })
      .addCase(
        getTicketWinnerInfoAsync.fulfilled,
        (state, action: PayloadAction<ITicketWinnerResponse<ITicketWinner[]>>) => {
          state.isLoading = false;
          state.status = action.payload.success ? 'success' : 'failed';
          if (action.payload.success) {
            state.ticketWinner = action.payload.response;
            state.error = '';
          } else {
            state.error = action.payload.message;
          }
        },
      )
      .addCase(getTicketWinnerInfoAsync.rejected, (state) => {
        state.isLoading = false;
        state.status = 'failed';
        state.ticketWinner = [];
        state.error = '';
      });
  },
});

export const { actionCleanTicketWinner, actionCleanTicketWinnerRequest } = tickeWinnerSlice.actions;

export const ticketWinnerReducer = tickeWinnerSlice.reducer;
