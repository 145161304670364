import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const chance3digitsSelector = createSelector(
  (state: RootState) => state.chance3Digits.chance3DigitsLotteries,
  (chance3DigitsLotteries) => {
    return chance3DigitsLotteries;
  },
);

export const chance3DigitsIsLoadingSelector = createSelector(
  (state: RootState) => state.chance3Digits.isLoading,
  (isLoading) => {
    return isLoading;
  },
);

export const chance3DigitStatusSelector = createSelector(
  (state: RootState) => state.chance3Digits.status,
  (status) => {
    return status;
  },
);
