import { IRaffleDetail } from '@models/Raffle.models';
import { IAnimalBet, IAnimalRaffleBet } from '@models/Animal.models';
import { ITicketAnimal, ITicketBets, ITicketRaffleAnimalDetail } from '@models/Ticket.models';
import { IAnimalsActiveByLottery } from '@models/AnimalsLottery.models';
import { cloneDeep, groupBy, sortBy, uniqBy } from 'lodash';
import { ErrorValidateBet } from '@models/Bet.models';
import { ANIMAL_RAFFLES_ID, ANIMAL_TRIPLETA_GAME_ID } from '@constants/app.constants';
import { lineBreak } from '@constants/printer.constants';
import { commafy } from '@helpers/number.helpers';
import { joinAnimalValuesForTripleta } from '@helpers/bet.helpers';

export const buildAnimalTicketInfo = (
  currencyId: number,
  selectedRaffles: IRaffleDetail[],
  animalsBet: IAnimalBet[],
  selectedActiveLottery: IAnimalsActiveByLottery,
  animalBetTicketInfo: ITicketAnimal[],
  selectedGameType: number,
) => {
  const animalTickets: ITicketAnimal = {
    lotteryId: selectedActiveLottery.animalitosLotteryId,
    lotteryName: selectedActiveLottery.animalitosLotteryName,
    animalGameTypeId: selectedGameType,
    rafflesAnimalBet: [],
    totalBet: 0,
  };
  let totalBet = 0;
  animalTickets.lotteryName = selectedActiveLottery.animalitosLotteryName;
  selectedRaffles.forEach((raffle) => {
    let animalBetAux = animalsBet;
    let totalBetRaffle = animalsBet.reduce((acc, animal) => acc + Number(animal.betAmount), 0);
    if (selectedGameType === ANIMAL_TRIPLETA_GAME_ID) {
      totalBetRaffle = Number(animalsBet[0].betAmount);
      animalBetAux = [
        {
          animalId: joinAnimalValuesForTripleta(animalsBet.map((animal) => animal.animalId)),
          animalName: joinAnimalValuesForTripleta(animalsBet.map((animal) => animal.animalName)),
          betAmount: animalsBet[0].betAmount,
          animalUserId: animalsBet[0].animalUserId,
          animalImageUrl: animalsBet[0].animalImageUrl,
          animalIsFruit: animalsBet[0].animalIsFruit,
          isTripleta: true,
        },
      ];
    }
    totalBet += totalBetRaffle;
    const raffleAnimalBet: ITicketRaffleAnimalDetail = {
      raffleId: raffle.animalitosRaffleId,
      raffleName: raffle.animalitosRaffleName,
      animalsBet: animalBetAux,
      totalBet: totalBetRaffle,
    };
    animalTickets.rafflesAnimalBet.push(raffleAnimalBet);
  });
  animalTickets.totalBet = totalBet;
  return mergeAnimalTicketInfo(animalBetTicketInfo, animalTickets);
};

export const buildAnimalTicketInfoFromAnimalRaffleBet = (
  currencyId: number,
  animalRaffleBet: IAnimalRaffleBet[],
  selectedActiveLottery: IAnimalsActiveByLottery,
  animalBetTicketInfo: ITicketAnimal[],
  selectedGameType: number,
) => {
  const animalTickets: ITicketAnimal = {
    lotteryId: selectedActiveLottery.animalitosLotteryId,
    lotteryName: selectedActiveLottery.animalitosLotteryName,
    animalGameTypeId: selectedGameType,
    rafflesAnimalBet: [],
    totalBet: 0,
  };
  let totalBet = 0;
  const groupAnimalRaffleByRaffle = groupBy(animalRaffleBet, 'raffleId');
  Object.keys(groupAnimalRaffleByRaffle).forEach((raffleId) => {
    let animalBet: IAnimalBet[];
    let totalBetRaffle = groupAnimalRaffleByRaffle[raffleId].reduce(
      (acc, animal) => acc + Number(animal.betAmount),
      0,
    );
    if (selectedGameType === ANIMAL_TRIPLETA_GAME_ID) {
      totalBetRaffle = Number(groupAnimalRaffleByRaffle[raffleId][0].betAmount);
      animalBet = buildAnimalBetForTripleta(groupAnimalRaffleByRaffle[raffleId]);
    } else {
      animalBet = groupAnimalRaffleByRaffle[raffleId].map((animal) => {
        return {
          animalId: animal.animalId,
          animalName: animal.animalName,
          betAmount: animal.betAmount,
          animalUserId: animal.animalUserId,
          animalImageUrl: animal.animalImageUrl,
          animalIsFruit: animal.animalIsFruit,
          isTripleta: selectedGameType === ANIMAL_TRIPLETA_GAME_ID,
          betValueFixed: animal.betValueFixed && animal.betValueFixed > 0 ? animal.betValueFixed : 0
        };
      });
    }
    totalBet += totalBetRaffle;

    const raffleAnimalBet: ITicketRaffleAnimalDetail = {
      raffleId: Number(raffleId),
      raffleName: groupAnimalRaffleByRaffle[raffleId][0].raffleName,
      animalsBet: animalBet,
      totalBet: totalBetRaffle,
    };
    animalTickets.rafflesAnimalBet.push(raffleAnimalBet);
  });
  animalTickets.totalBet = totalBet;
  return mergeAnimalTicketInfo(animalBetTicketInfo, animalTickets);
};

const buildAnimalBetForTripleta = (animalRaffleBets: IAnimalRaffleBet[]): IAnimalBet[] => {
  const animalBet: IAnimalBet = {
    animalId: joinAnimalValuesForTripleta(animalRaffleBets.map((animal) => animal.animalId)),
    animalName: joinAnimalValuesForTripleta(animalRaffleBets.map((animal) => animal.animalName)),
    betAmount: animalRaffleBets[0].betAmount,
    animalUserId: animalRaffleBets[0].animalUserId,
    animalImageUrl: animalRaffleBets[0].animalImageUrl,
    animalIsFruit: animalRaffleBets[0].animalIsFruit,
    isTripleta: true,
  };

  return [animalBet];
};

export const mergeAnimalTicketInfo = (
  currentAnimalTicketInfo: ITicketAnimal[],
  animalTicket: ITicketAnimal,
): ITicketAnimal[] => {
  const copyAnimalTicketInfo = cloneDeep(animalTicket);
  const currentAnimalTicketInfoCopy = cloneDeep(currentAnimalTicketInfo);
  let foundLottery = false;

  if (currentAnimalTicketInfoCopy.length > 0) {
    while (!foundLottery) {
      const foundLotteryIndex = currentAnimalTicketInfoCopy.findIndex(
        (animalTicketInfo) =>
          animalTicketInfo.lotteryId === animalTicket.lotteryId &&
          animalTicketInfo.animalGameTypeId === animalTicket.animalGameTypeId,
      );
      if (foundLotteryIndex > -1) {
        foundLottery = true;
        animalTicket.rafflesAnimalBet.forEach((raffleAnimalBetToCompare) => {
          const raffleAlreadyExistsIndex = currentAnimalTicketInfoCopy[
            foundLotteryIndex
          ].rafflesAnimalBet.findIndex(
            (currentRaffleTicketInfo) =>
              currentRaffleTicketInfo.raffleId === raffleAnimalBetToCompare.raffleId,
          );
          if (raffleAlreadyExistsIndex > -1) {
            currentAnimalTicketInfoCopy[foundLotteryIndex].rafflesAnimalBet[
              raffleAlreadyExistsIndex
            ] = {
              ...currentAnimalTicketInfoCopy[foundLotteryIndex].rafflesAnimalBet[
                raffleAlreadyExistsIndex
              ],
              animalsBet: sortBy(
                uniqBy(
                  raffleAnimalBetToCompare.animalsBet.concat(
                    currentAnimalTicketInfoCopy[foundLotteryIndex].rafflesAnimalBet[
                      raffleAlreadyExistsIndex
                    ].animalsBet,
                  ),
                  'animalId',
                ),
                'animalName',
              ),
            };
          } else {
            currentAnimalTicketInfoCopy[foundLotteryIndex].rafflesAnimalBet.push(
              raffleAnimalBetToCompare,
            );
          }
        });
      } else {
        currentAnimalTicketInfoCopy.push(copyAnimalTicketInfo);
        foundLottery = true;
      }
    }

    return currentAnimalTicketInfoCopy;
  }
  return [{ ...animalTicket }];
};

export const buildAnimalTicketBetWithError = (
  currentAnimalTicketInfo: ITicketAnimal[],
  errorBetList: ErrorValidateBet[],
): ITicketAnimal[] => {
  return currentAnimalTicketInfo.map((animalTicketInfo) => {
    const newAnimalTicketInfo = animalTicketInfo.rafflesAnimalBet.map((raffleAnimalBet) => {
      return {
        ...raffleAnimalBet,
        animalsBet: evaluateAnimalBetHasError(raffleAnimalBet.animalsBet, errorBetList),
      };
    });
    return {
      ...animalTicketInfo,
      rafflesAnimalBet: newAnimalTicketInfo,
    };
  });
};

export const evaluateRaffleHasError = (
  raffleAnimalBet: ITicketRaffleAnimalDetail,
  errorBetList: ErrorValidateBet[],
): ITicketRaffleAnimalDetail => {
  let foundAError = false;
  let finalIteration = false;
  let iterator = 0,
    internalListIterator = 0;
  while (!foundAError && !finalIteration) {
    errorBetList.forEach((errorBet, index) => {
      errorBet.errorList.forEach((errorDetail, indexList) => {
        if (
          errorDetail.raffleId === raffleAnimalBet.raffleId &&
          errorDetail.gameTypeId === ANIMAL_RAFFLES_ID
        ) {
          foundAError = true;
          iterator = index;
          internalListIterator = indexList;
        }
      });
    });
    finalIteration = true;
  }

  if (foundAError) {
    return {
      ...raffleAnimalBet,
      error: errorBetList[iterator].errorList[internalListIterator].error,
      hasError: true,
    };
  }
  return raffleAnimalBet;
};

export const evaluateAnimalBetHasError = (
  animalBet: IAnimalBet[],
  errorBetList: ErrorValidateBet[],
): IAnimalBet[] => {
  return animalBet.map((animal) => {
    let foundAError = false;
    let finalIteration = false;
    let iterator = 0,
      internalListIterator = 0;
    while (!foundAError && !finalIteration) {
      errorBetList.forEach((errorBet, index) => {
        errorBet.errorList.forEach((errorDetail, indexList) => {
          if (
            errorDetail.betValue === animal.animalId.toString() &&
            errorDetail.gameTypeId === ANIMAL_RAFFLES_ID
          ) {
            foundAError = true;
            iterator = index;
            internalListIterator = indexList;
          }
        });
      });
      finalIteration = true;
    }

    if (foundAError) {
      return {
        ...animal,
        error: errorBetList[iterator].errorList[internalListIterator].error,
        hasError: true,
      };
    }
    return animal;
  });
};

export const processBuildTicketWithError = (currentTicketState: ITicketAnimal[]) => {
  const errorsFound: string[] = [];
  const raffleWithoutError: ITicketAnimal[] = [];

  currentTicketState.forEach((ticket) => {
    const raffleAnimalDetail: ITicketRaffleAnimalDetail[] = [];
    ticket.rafflesAnimalBet.forEach((raffle) => {
      if (raffle.hasError && raffle.error) {
        errorsFound.push(raffle.error);
      } else {
        const raffleAux = cloneDeep(raffle);
        raffleAux.animalsBet = [];
        raffle.animalsBet.forEach((animal) => {
          if (animal.hasError && animal.error) {
            errorsFound.push(animal.error);
          } else {
            raffleAux.animalsBet.push(animal);
          }
        });
        if (raffleAux.animalsBet.length > 0) {
          raffleAnimalDetail.push(raffleAux);
        }
      }
    });
    if (raffleAnimalDetail.length > 0) {
      raffleWithoutError.push({
        ...ticket,
        rafflesAnimalBet: raffleAnimalDetail,
      });
    }
  });
  return { errorsFound, raffleWithoutError };
};

export const createTicketPrintString = (ticketData: ITicketBets[]): string => {
  let ticketString = '';
  ticketData.forEach((raffle) => {
    ticketString += `${raffle.raffleName}: `;
    raffle.betDetail.forEach((detail) => {
      ticketString += `${detail.betValue} $${commafy(detail.betTotal)}, `;
    });
    ticketString = ticketString.trim().slice(0, -1);
    ticketString += lineBreak;
  });
  return ticketString;
};
