import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from '@app/store';

import reportWebVitals from './reportWebVitals';

import './index.scss';
import App from './App';

import '@fontsource/lato';
import '@fontsource/courier-prime';

import { AuthProvider } from 'oidc-react';
import { oidcConfig } from '@config/oidc-identity-server';
import AppThemeProvider from '@components/AppThemeProvider/AppThemeProvider';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <Provider store={store}>
        <AppThemeProvider>
          <App />
        </AppThemeProvider>
      </Provider>
    </AuthProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
