import { blue, blueGrey, grey } from '@mui/material/colors';

export const buttonStyle = {
  color: grey[50],
  height: '100%',
  fontWeight: 'bold',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: blue[900],
    color: grey[50],
  },
};

export const buttonStyleActive = {
  backgroundColor: grey[50],
  color: blueGrey[900],
};
