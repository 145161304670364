import { IBet } from '@models/Bet.models';
import {
  ChanceZodiacLottery,
  ChanceZodiacRaffle,
  ChanceZodiacTicketBetArray,
  ITicketChanceZodiac,
  ITicketChanceZodiacDetail,
} from '@models/ChanceZodiac.models';
import { StarSign } from '@models/StarSign.models';
import { DateTime } from 'luxon';

export const isRaffleActive = (ChanceZodiacRaffle: ChanceZodiacRaffle): boolean => {
  const now = DateTime.local();
  const raffleEndDate = DateTime.fromISO(ChanceZodiacRaffle.chanceZodiacRaffleBetEndDate);
  return raffleEndDate > now;
};

export const filterRaffleZodiacSelected = (raffles: ChanceZodiacRaffle[]): ChanceZodiacRaffle[] =>
  raffles.filter((raffle) => raffle.chanceZodiacRaffleSelected);

export const filterChanceZodiacRaffleByMaxBet = (
  chance4DigitsLotteries: ChanceZodiacLottery[],
): {
  maxBet3: ChanceZodiacRaffle[];
} => {
  if (chance4DigitsLotteries) {
    const filteredChanceRaffleDetailMaxBet3: ChanceZodiacRaffle[] = [];
    chance4DigitsLotteries.forEach((raffle) =>
      raffle.chanceZodiacRaffleDetail.forEach((raffleDetail) => {
        if (isRaffleActive(raffleDetail)) {
          if (raffle.chanceZodiacLotteryMaxDigitsByBet === 3) {
            filteredChanceRaffleDetailMaxBet3.push({
              ...raffleDetail,
              chanceZodiacLotteryId: raffle.chanceZodiacLotteryId,
              chanceZodiacLotteryName: raffle.chanceZodiacLotteryName,
              chanceZodiacRaffleName: raffleDetail.chanceZodiacRaffleName,
            });
          }
        }
      }),
    );

    return {
      maxBet3: filteredChanceRaffleDetailMaxBet3,
    };
  }
  return { maxBet3: [] };
};

export const buildChanceZodiacBet = (
  numberArray: string[],
  betAmount: number,
  selectedRaffle: ChanceZodiacRaffle[],
  currencyId: number,
  gameTypeId: number,
  starSignId: number,
): IBet[] => {
  const chanceBetArray: IBet[] = [];
  selectedRaffle.forEach((raffle) => {
    numberArray.forEach((betNumber) => {
      chanceBetArray.push({
        raffleId: raffle.chanceZodiacRaffleId,
        raffleName: raffle.chanceZodiacRaffleName,
        lotteryId: raffle.chanceZodiacLotteryId,
        betValue: betNumber + '-' + starSignId,
        gameTypeId: gameTypeId,
        betTotal: betAmount,
      });
    });
  });

  return chanceBetArray;
};

export const buildITicketChanceZodiac = (
  numberArray: string[],
  betAmount: number,
  selectedRaffle: ChanceZodiacRaffle[],
  starSign: StarSign,
): ITicketChanceZodiac[] => {
  const ticketChanceZodiac: ITicketChanceZodiac[] = [];
  selectedRaffle.forEach((raffle) => {
    const ChanceZodiacBet: ITicketChanceZodiacDetail[] = [];
    const chanceZodiacTicketBetArray: ChanceZodiacTicketBetArray[] = [];
    numberArray.forEach((betNumber) => {
      chanceZodiacTicketBetArray.push({
        betNumber,
        betAmount,
        starSignId: starSign.starSignId,
        starSignName: starSign.starSignName,
      });
    });
    ChanceZodiacBet.push({
      raffleId: raffle.chanceZodiacRaffleId,
      raffleName: raffle.chanceZodiacRaffleName,
      totalBet: betAmount * numberArray.length,
      ChanceZodiacBet: chanceZodiacTicketBetArray,
    });
    ticketChanceZodiac.push({
      lotteryId: raffle.chanceZodiacLotteryId,
      lotteryName: raffle.chanceZodiacLotteryName,
      totalBet: ChanceZodiacBet.reduce((a, b) => a + b.totalBet, 0),
      raffleChanceZodiacBet: ChanceZodiacBet,
    });
  });

  return ticketChanceZodiac;
};
