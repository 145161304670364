import { createAsyncThunk, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ErrorValidateBet, IBet, IBetTicket, IBetTicketResponse } from '@models/Bet.models';
import { ITicketAnimal, ITicketResponse } from '@models/Ticket.models';
import { addTicketApi } from '@features/Ticket/Ticket.api';
import { ITicketChance3Digits } from '@models/Chance3Digits.models';
import { ITicketChance4Digits } from '@models/Chance4Digits.models';
import { ITicketChanceZodiac } from '@models/ChanceZodiac.models';

interface TicketSliceState {
  ticketBets: IBet[];
  animalBetTicketInfo: ITicketAnimal[];
  chance3BetTicketInfo: ITicketChance3Digits[];
  chance4BetTicketInfo: ITicketChance4Digits[];
  chanceZodiacBetTicketInfo: ITicketChanceZodiac[];
  foundErrorsOnAddTicket: string[];
  salePointId: number;
  currencyId: number;
  isEditing: boolean;
  raffleId: number;
  isLoading: boolean;
  status: 'success' | 'failed' | 'idle';
  betResponse: ITicketResponse;
  betErrors: ErrorValidateBet[];
}

const initialState: TicketSliceState = {
  ticketBets: [],
  animalBetTicketInfo: [],
  chance3BetTicketInfo: [],
  chance4BetTicketInfo: [],
  chanceZodiacBetTicketInfo: [],
  foundErrorsOnAddTicket: [],
  salePointId: 0,
  currencyId: 0,
  isEditing: false,
  raffleId: 0,
  betErrors: [],
  betResponse: {} as ITicketResponse,
  isLoading: false,
  status: 'idle',
};

export const addTicketAsync = createAsyncThunk('ticket/addTicket', async (betData: IBetTicket) => {
  const response = await addTicketApi(betData);
  return response as IBetTicketResponse;
});

export const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    actionCleanTicketState: () => {
      return initialState;
    },
    actionBetToTicket: (state: Draft<TicketSliceState>, action: PayloadAction<IBetTicket>) => {
      state.ticketBets = action.payload.bets;
      state.salePointId = action.payload.salePointId;
      state.currencyId = action.payload.currencyId;
    },
    actionSetBet: (state: Draft<TicketSliceState>, action: PayloadAction<IBet[]>) => {
      state.ticketBets = action.payload;
    },
    actionCleanBetErrors: (state: Draft<TicketSliceState>) => {
      state.betErrors = [];
    },
    actionSetAnimalBetTicketInfo: (state, action) => {
      state.animalBetTicketInfo = action.payload;
    },
    actionSetFoundErrorsOnAddTicket: (state, action) => {
      state.foundErrorsOnAddTicket = action.payload;
    },
    actionCleanFoundErrorsOnAddTicket: (state) => {
      state.foundErrorsOnAddTicket = [];
    },
    actionSetChance3BetTicketInfo: (state, action) => {
      state.chance3BetTicketInfo = action.payload;
    },
    actionSetChanceZodiacBetTicketInfo: (state, action) => {
      state.chanceZodiacBetTicketInfo = action.payload;
    },
    actionSetChance4BetTicketInfo: (state, action) => {
      state.chance4BetTicketInfo = action.payload;
    },
    actionSetIsLoading: (state) => {
      state.isLoading = true;
    },
    actionSetIsNotLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addTicketAsync.pending, (state) => {
        state.isLoading = true;
        state.status = 'idle';
        state.betErrors = [];
      })
      .addCase(addTicketAsync.fulfilled, (state, action: PayloadAction<IBetTicketResponse>) => {
        state.isLoading = false;
        state.betResponse = action.payload.response;
        state.status = action.payload.success ? 'success' : 'failed';
        if (action.payload.success) {
          state.betErrors = [];
        } else if (action.payload.errors) {
          state.betErrors = action.payload.errors;
        }
      })
      .addCase(addTicketAsync.rejected, (state) => {
        state.isLoading = false;
        state.status = 'failed';
        state.betErrors = [];
      });
  },
});

export const {
  actionCleanTicketState,
  actionBetToTicket,
  actionSetAnimalBetTicketInfo,
  actionSetBet,
  actionSetFoundErrorsOnAddTicket,
  actionSetChance3BetTicketInfo,
  actionSetChanceZodiacBetTicketInfo,
  actionSetChance4BetTicketInfo,
  actionCleanBetErrors,
  actionSetIsLoading,
  actionSetIsNotLoading,
} = ticketSlice.actions;

export const ticketReducer = ticketSlice.reducer;
