import React, { useId } from 'react';
import { ITicketChanceZodiac } from '@models/ChanceZodiac.models';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import ChanceZodiacRaffleBetTicketInfo from './component/ChanceZodiacRaffleBetTicketInfo';

interface ChanceTicketInfoProps {
  chanceTicketInfo: ITicketChanceZodiac[];
  deleteLottery: (lotteryId: number) => void;
  deleteRaffle: (lotteryId: number, raffleId: number) => void;
  setEditableRaffle: (lotteryId: number, raffleId: number, finishEdit?: boolean) => void;
  updateChanceBetAmount: (
    indexLottery: number,
    indexRaffle: number,
    indexBet: number,
    amount: string,
  ) => void;
  formHasErrors: boolean;
  formatCurrency: (amount: number) => string;
  isPrintingTicket: boolean;
}
const ChanceZodiacTicketInfo = ({
  chanceTicketInfo,
  deleteRaffle,
  deleteLottery,
  setEditableRaffle,
  updateChanceBetAmount,
  formHasErrors,
  formatCurrency,
  isPrintingTicket,
}: ChanceTicketInfoProps) => {
  const randomId = useId();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ flex: 1 }}>
        {chanceTicketInfo.map((chanceLottery, lotteryIndex) => (
          <Box
            sx={{ paddingLeft: 1 }}
            key={`chance-zodiac-bet-${chanceLottery.lotteryName}-${chanceLottery.lotteryId}-${randomId}`}
          >
            <Grid container spacing={2} sx={{ alignItems: 'center' }}>
              <Grid item xs={8}>
                <Typography component='h6'>
                  <strong>{chanceLottery.lotteryName}</strong>
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  disabled={isPrintingTicket}
                  onClick={() => deleteLottery(chanceLottery.lotteryId)}
                >
                  <SvgIcon component={DeleteIcon} />
                </IconButton>
              </Grid>
            </Grid>
            <ChanceZodiacRaffleBetTicketInfo
              chanceLotteryRaffles={chanceLottery.raffleChanceZodiacBet}
              deleteRaffle={(raffleId: number) => deleteRaffle(chanceLottery.lotteryId, raffleId)}
              setEditableRaffle={(raffleId: number, finishEdit?: boolean) =>
                setEditableRaffle(lotteryIndex, raffleId, finishEdit)
              }
              updateChanceBetAmount={(raffleIndex: number, animalIndex: number, amount: string) =>
                updateChanceBetAmount(lotteryIndex, raffleIndex, animalIndex, amount)
              }
              formHasErrors={formHasErrors}
              formatCurrency={formatCurrency}
              isPrintingTicket={isPrintingTicket}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ChanceZodiacTicketInfo;
