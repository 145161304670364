import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const chance4RafflesSelector = createSelector(
  (state: RootState) => state.chance4Raffles.chance4Raffles,
  (chance4Raffles) => {
    return chance4Raffles;
  },
);

export const chance4RaffleMaxBet4Selector = createSelector(
  (state: RootState) => state.chance4Raffles.chance4RaffleMaxBet4,
  (chance4RaffleMaxBet4) => {
    return chance4RaffleMaxBet4;
  },
);

export const chance4RaffleMaxBet3Selector = createSelector(
  (state: RootState) => state.chance4Raffles.chance4RaffleMaxBet3,
  (chance4RaffleMaxBet3) => {
    return chance4RaffleMaxBet3;
  },
);

export const chance4RaffleMaxBet2Selector = createSelector(
  (state: RootState) => state.chance4Raffles.chance4RaffleMaxBet2,
  (chance4RaffleMaxBet2) => {
    return chance4RaffleMaxBet2;
  },
);

export const selectedChance4RaffleMaxBet4Selector = createSelector(
  (state: RootState) => state.chance4Raffles.selectedChance4RaffleMaxBet4,
  (selectedChance4RaffleMaxBet4) => {
    return selectedChance4RaffleMaxBet4;
  },
);

export const selectedChance3RaffleMaxBet3Selector = createSelector(
  (state: RootState) => state.chance4Raffles.selectedChance3RaffleMaxBet3,
  (selectedChance3RaffleMaxBet3) => {
    return selectedChance3RaffleMaxBet3;
  },
);

export const selectedChance2RaffleMaxBet2Selector = createSelector(
  (state: RootState) => state.chance4Raffles.selectedChance2RaffleMaxBet2,
  (selectedChance2RaffleMaxBet2) => {
    return selectedChance2RaffleMaxBet2;
  },
);

export const isRaffleChance4SelectedSelector = createSelector(
  (state: RootState) => state.chance4Raffles.isRaffleChance4Selected,
  (isRaffleChance4Selected) => {
    return isRaffleChance4Selected;
  },
);
