import { useAppDispatch, useAppSelector } from '@app/hooks';
import { removeToken } from '@helpers/localstorage.helper';
import { useAuth } from 'oidc-react';
import {
  actionEnableIsLoading,
  actionHideSignOutDialog,
  actionShowSignOutDialog,
} from './SignOutSlice';
import { IDENTITY_CONFIG } from '@constants/oidc-identity-server.constants';
import { signOutIsLoadingSelector, signOutOpenDialogSelector } from '@selectors/signOut.selector';

export const useSignout = () => {
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(signOutIsLoadingSelector);
  const openDialog = useAppSelector(signOutOpenDialogSelector);

  const onOpenSignOutDialog = () => {
    dispatch(actionShowSignOutDialog());
  };

  const onConfirmSignOut = (confirm: boolean) => {
    if (confirm) {
      dispatch(actionEnableIsLoading());
      logout();
    } else {
      dispatch(actionHideSignOutDialog());
    }
  };

  const logout = () => {
    removeToken();
    auth.userManager.revokeTokens().then(() => {
      auth.signOut();

      auth.signOutRedirect({
        // eslint-disable-next-line camelcase
        post_logout_redirect_uri: IDENTITY_CONFIG.logout,
        // eslint-disable-next-line camelcase
        id_token_hint: auth.userData?.id_token,
      });
    });
  };

  return {
    onConfirmSignOut,
    isLoading,
    openDialog,
    onOpenSignOutDialog,
  };
};
