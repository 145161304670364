import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const signOutIsLoadingSelector = createSelector(
  (state: RootState) => state.signOut.isLoading,
  (isLoading) => isLoading,
);

export const signOutOpenDialogSelector = createSelector(
  (state: RootState) => state.signOut.openDialog,
  (openDialog) => openDialog,
);
