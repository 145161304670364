import { IBetTicket, IBetTicketResponse } from '@models/Bet.models';
import { AxiosError, AxiosResponse } from 'axios';
import httpCommon from '@config/http-common';

export const validateBetApi = async (data: IBetTicket) => {
  try {
    const response: AxiosResponse<IBetTicketResponse> = await httpCommon.post(
      'Ticket/validate-bet',
      data,
    );
    return response?.data || [];
  } catch (error) {
    throw (error as AxiosError).response?.data;
  }
};
