import { AxiosError, AxiosResponse } from 'axios';
import httpCommon from '@config/http-common';
import { Chance4DigitsLottery, Chance4DigitsResponse } from '@models/Chance4Digits.models';

export const getActiveChanceFourRafflesCurrentDate = async (filter: number | void) => {
  try {
    const response: AxiosResponse<Chance4DigitsResponse<Chance4DigitsLottery[]>> =
      await httpCommon.get(
        `ChanceFourRaffle/get-active-chance-four-raffles-for-current-date${
          filter ? '/' + filter : ''
        }`,
      );
    return response?.data?.response || [];
  } catch (error) {
    if (error instanceof AxiosError) {
      return error.response?.data?.message || 'unknown error';
    }
    return 'unknown error';
  }
};
