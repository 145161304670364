import { lineBreak } from '@constants/printer.constants';
import { ITicketAnimal } from '@models/Ticket.models';

export const amountIsExceedingMaxValue = (maxBetByAnimal: number, amount: number) => {
  return amount > maxBetByAnimal;
};

export const amountIsNotValid = (amount: string | number) => {
  const re = /^\d+(\.\d{1,2})?$/;
  return Number(amount) <= 0 || !re.test(amount.toString());
};

export const createAnimalBetPrintTicketData = (animalBetTicket: ITicketAnimal[]): string => {
  let ticketData = '';
  animalBetTicket.forEach((animalBet) => {
    animalBet.rafflesAnimalBet.forEach((raffle) => {
      ticketData += `${raffle.raffleName}: `;
      raffle.animalsBet.forEach((animal) => {
        if (animal.betAmount) {
          ticketData += `${animal.animalName} $${animal.betAmount}, `;
        }
      });
      ticketData = ticketData.trim().slice(0, -1);
      ticketData += lineBreak;
    });
  });
  return ticketData;
};
