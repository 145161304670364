import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { IconButton } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConditionalRendering } from '@components/ConditionalRendering/ConditionalRendering';
import { Chance3TicketBetArray, ITicketChance3DigitsDetail } from '@models/Chance3Digits.models';
import ChanceBetTicketInfo from '@components/ChanceTicketInfo/component/ChanceBetTicketInfo';

interface ChanceRaffleBetTicketInfoProps {
  chanceLotteryRaffles: ITicketChance3DigitsDetail[];
  deleteRaffle: (raffleId: number) => void;
  setEditableRaffle: (raffleId: number, finishEdit?: boolean) => void;
  updateChanceBetAmount: (raffleIndex: number, animalIndex: number, amount: string) => void;
  formHasErrors: boolean;
  formatCurrency: (amount: number) => string;
  isPrintingTicket: boolean;
}

function ChanceRaffleBetTicketInfo({
  chanceLotteryRaffles,
  deleteRaffle,
  setEditableRaffle,
  updateChanceBetAmount,
  formHasErrors,
  formatCurrency,
  isPrintingTicket,
}: ChanceRaffleBetTicketInfoProps) {
  const calculateTotalByRaffle = (chanceBet: Chance3TicketBetArray[]) =>
    chanceBet.reduce((acc, chance) => acc + Number(chance.betAmount), 0);

  const renderRaffleAnimalBet = chanceLotteryRaffles.map(
    (chanceLotteryRaffle, chanceLotteryRaffleIndex) => (
      <Box key={`raffle-ticket-animal-${chanceLotteryRaffle.raffleId}`}>
        <Grid container spacing={2}>
          <Grid item xs={1} />
          <Grid item xs={7}>
            <Typography sx={{ marginTop: 1 }} component='p' variant='body1'>
              <strong>{chanceLotteryRaffle.raffleName}</strong>
            </Typography>
          </Grid>
          <ConditionalRendering isTrue={!!chanceLotteryRaffle.isEditing}>
            <Grid item xs={2}>
              <IconButton
                disabled={formHasErrors || isPrintingTicket}
                onClick={() => setEditableRaffle(chanceLotteryRaffleIndex, true)}
              >
                <SvgIcon component={CheckIcon} />
              </IconButton>
            </Grid>
          </ConditionalRendering>
          <ConditionalRendering isTrue={!chanceLotteryRaffle.isEditing}>
            <Grid item xs={2}>
              <IconButton
                disabled={isPrintingTicket}
                onClick={() => setEditableRaffle(chanceLotteryRaffleIndex)}
              >
                <SvgIcon component={EditIcon} />
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                disabled={isPrintingTicket}
                onClick={() => deleteRaffle(chanceLotteryRaffle.raffleId)}
              >
                <SvgIcon component={DeleteIcon} />
              </IconButton>
            </Grid>
          </ConditionalRendering>
        </Grid>
        <ChanceBetTicketInfo
          chanceBet={chanceLotteryRaffle.chance3DigitsBet}
          isEditable={!!chanceLotteryRaffle.isEditing}
          updateChanceBetAmountAction={(chanceBetIndex: number, amount: string) =>
            updateChanceBetAmount(chanceLotteryRaffleIndex, chanceBetIndex, amount)
          }
          formatCurrency={formatCurrency}
        />
        <Typography sx={{ paddingLeft: 2, paddingTop: 1 }} component='p' variant='body1'>
          Total:{' '}
          <strong>
            {formatCurrency(calculateTotalByRaffle(chanceLotteryRaffle.chance3DigitsBet))}
          </strong>
        </Typography>
      </Box>
    ),
  );

  return <>{renderRaffleAnimalBet}</>;
}

export default ChanceRaffleBetTicketInfo;
