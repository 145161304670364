import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const chance4DigitsLotteriesSelector = createSelector(
  (state: RootState) => state.chance4Digits.chance4DigitsLotteries,
  (chance4DigitsLotteries) => {
    return chance4DigitsLotteries;
  },
);

export const chance4DigitsLotteriesStatusSelector = createSelector(
  (state: RootState) => state.chance4Digits.status,
  (status) => {
    return status;
  },
);

export const chance4DigitsLotteriesLoadingSelector = createSelector(
  (state: RootState) => state.chance4Digits.isLoading,
  (isLoading) => {
    return isLoading;
  },
);
