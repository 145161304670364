import React from 'react';
import Box from '@mui/material/Box';
import { IAnimalBet } from '@models/Animal.models';
import Typography from '@mui/material/Typography';
import { ConditionalRendering } from '@components/ConditionalRendering/ConditionalRendering';
import InputNumber from '@components/InputNumber/InputNumber';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';

interface AnimalTicketInfoProps {
  animalsBet: IAnimalBet[];
  isEditable: boolean;
  updateAnimalBetAmountAction: (animalIndex: number, amount: string) => void;
  formatCurrency: (amount: number) => string;
}

function AnimalBetTicketInfo({
  animalsBet,
  isEditable,
  updateAnimalBetAmountAction,
  formatCurrency,
}: AnimalTicketInfoProps) {
  const renderAnimalBet = animalsBet.map((animalBet, animalBetIndex) => (
    <Box
      key={`animal-bet-${animalBet.animalName}-${animalBet.animalUserId}`}
      sx={{ display: 'flex', alignItems: animalBet.hasError ? 'center' : 'flex-end' }}
    >
      <Typography sx={{ marginRight: 2, paddingLeft: 4, width: '30%' }} component='span'>
        {animalBet.animalName}
      </Typography>
      <ConditionalRendering isTrue={isEditable}>
        <InputNumber
          sx={{ width: '50%' }}
          error={animalBet.hasError}
          helperText={animalBet.hasError ? String(animalBet.error) : ''}
          variant='standard'
          isAmount
          value={animalBet.betAmount}
          onChange={(value) => updateAnimalBetAmountAction(animalBetIndex, value)}
        />
      </ConditionalRendering>
      <ConditionalRendering isTrue={!isEditable}>
        <Typography component='span'>
          {formatCurrency(Number(animalBet.betAmount))}
          {animalBet.betValueFixed && animalBet.betValueFixed > 0 && (
            <Tooltip
              title={'El valor de la apuesta ha cambiado debido al límite del cupo del sorteo.'}
              arrow
            >
              <WarningIcon color='warning' fontSize='small' style={{ marginLeft: '3px' }} />
            </Tooltip>
          )}
        </Typography>
      </ConditionalRendering>
    </Box>
  ));

  return <>{renderAnimalBet}</>;
}

export default AnimalBetTicketInfo;
