import { useAppDispatch, useAppSelector } from '@app/hooks';
import { actionSelectRaffle, actionSetActiveRaffles } from './RaffleSlice';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  actionCleanAnimalsState,
  actionSetAnimals,
} from '@features/AnimalsGame/Animals/AnimalsSlice';
import { IRaffleDetail } from '@models/Raffle.models';
import {
  buildAnimalRaffleWithError,
  filterActiveRaffles,
  isRaffleActive,
} from '@helpers/raffle.helpers';
import { actionCleanAnimalsBetState } from '@features/AnimalsGame/AnimalsBet/AnimalsBetSlice';
import { enqueueSnackbar } from 'notistack';
import { ANIMAL_TRIPLETA_GAME_ID } from '@constants/app.constants';
import { IAnimalsActiveByLottery } from '@models/AnimalsLottery.models';
import { useHotkeys } from 'react-hotkeys-hook';
import { SEARCH_RAFFLES_SHORTCUT } from '@constants/shortcuts.constants';

import { activeRafflesSelector, selectedRafflesSelector } from '@selectors/raffle.selector';
import { animalsSelector } from '@selectors/animals.selector';
import { animalsLotterySelector } from '@selectors/animalsLottery.selector';
import { betErrorsAnimalBetSelector } from '@selectors/animalsBet.selector';
import { foundErrorsOnAddTicketSelector } from '@selectors/ticket.selector';
import { selectedCurrencyIdSelector } from '@selectors/animalcurrency.selector';
import { selectedAnimalTypeGameSelector } from '@selectors/animalTypeGame.selector';

export const useRaffles = () => {
  const dispatch = useAppDispatch();
  const searchRaffleRef = useRef<HTMLInputElement>(null);

  const activeRaffles = useAppSelector(activeRafflesSelector);
  const selectedRaffles = useAppSelector(selectedRafflesSelector);
  const animals = useAppSelector(animalsSelector);
  const activeAnimalsByLottery = useAppSelector(animalsLotterySelector);
  const betErrors = useAppSelector(betErrorsAnimalBetSelector);
  const foundErrorsOnAddTicket = useAppSelector(foundErrorsOnAddTicketSelector);
  const selectedCurrencyId = useAppSelector(selectedCurrencyIdSelector);
  const selectedAnimalTypeGame = useAppSelector(selectedAnimalTypeGameSelector);

  useHotkeys(SEARCH_RAFFLES_SHORTCUT, () => {
    if (searchRaffleRef.current) {
      searchRaffleRef.current.focus();
      searchRaffleRef.current.value = '';
    }
  });

  const currentActiveRafflesByLottery = useMemo(
    () => filterActiveRaffles(activeAnimalsByLottery, selectedAnimalTypeGame, selectedCurrencyId),
    [activeAnimalsByLottery, selectedAnimalTypeGame, selectedCurrencyId],
  );

  const [searchRaffleName, setSearchRaffleName] = useState('');

  const filterRafflesByLottery = () => {
    dispatch(actionSetActiveRaffles(currentActiveRafflesByLottery));
  };

  const raffleAlreadySelected = (raffleId: number) => {
    return selectedRaffles.find((raffle) => raffle.animalitosRaffleId === raffleId);
  };

  const setAnimals = (newSelectedRaffles: IRaffleDetail[]) => {
    if (newSelectedRaffles.length === 0) {
      dispatch(actionSetAnimals([]));
    }

    if (animals.length === 0) {
      const animalsFarm = getLotteryFromSelectedRaffle(newSelectedRaffles[0].animalitosRaffleId);
      if (animalsFarm) {
        dispatch(actionSetAnimals(animalsFarm.animalDetails));
      }
    }
  };

  const getLotteryFromSelectedRaffle = (raffleId: number) => {
    return activeAnimalsByLottery.find((animalLottery) =>
      animalLottery.animalitosRaffleDetail.find((raffle) => raffle.animalitosRaffleId === raffleId),
    );
  };

  const getAnimalFarmIdFromRaffleId = (raffleId: number) => {
    const lottery = getLotteryFromSelectedRaffle(raffleId);
    if (lottery) {
      return lottery.animalitosFarmId;
    }
  };

  const unSelectRaffle = (raffleId: number) => {
    const newSelectedRaffles = selectedRaffles.filter(
      (raffle) => raffle.animalitosRaffleId !== raffleId,
    );
    dispatch(actionSelectRaffle(newSelectedRaffles));
    if (newSelectedRaffles.length === 0) {
      dispatch(actionCleanAnimalsBetState());
      dispatch(actionCleanAnimalsState());
      filterRafflesByLottery();
    }
    setAnimals(newSelectedRaffles);
  };

  const selectRaffle = (raffleId: number) => {
    const raffleIsAlreadySelected = raffleAlreadySelected(raffleId);
    if (raffleIsAlreadySelected) {
      unSelectRaffle(raffleId);
    } else {
      const raffle = activeRaffles.find((raffle) => raffle.animalitosRaffleId === raffleId);
      if (raffle) {
        const newRaffles = [...selectedRaffles, raffle];
        dispatch(actionSelectRaffle(newRaffles));
        setAnimals(newRaffles);

        if (newRaffles.length === 1 && !searchRaffleName) {
          const animalFarmId = getAnimalFarmIdFromRaffleId(raffleId);
          if (animalFarmId) {
            const newActiveRaffles = filterByFarmIdAndASelectedGameType(animalFarmId);
            dispatch(actionSetActiveRaffles(newActiveRaffles));
          }
        }
      }
    }
  };

  const filterByFarmIdAndASelectedGameType = (farmId: number) => {
    let activeRaffles: IAnimalsActiveByLottery[];

    if (selectedAnimalTypeGame !== ANIMAL_TRIPLETA_GAME_ID) {
      activeRaffles = activeAnimalsByLottery.filter(
        (lottery) =>
          lottery.animalitosLotteryGameType === selectedAnimalTypeGame &&
          lottery.animalitosFarmId === farmId,
      );
    } else {
      activeRaffles = activeAnimalsByLottery.filter((lottery) =>
        lottery.animalitosLotterySettings.find(
          (raffleSetting) =>
            raffleSetting.hasTripleta &&
            raffleSetting.currencyId === selectedCurrencyId &&
            lottery.animalitosFarmId === farmId,
        ),
      );
    }

    const activeRafflesFiltered = activeRaffles.map((raffle) => {
      return raffle.animalitosRaffleDetail.filter((raffleDetail) => {
        return isRaffleActive(raffleDetail);
      });
    });

    return activeRafflesFiltered.flat() || [];
  };

  const clearRaffleNameInput = () => {
    setSearchRaffleName('');
  };

  useEffect(() => {
    if (activeAnimalsByLottery.length > 0) {
      filterRafflesByLottery();
    }
  }, [activeAnimalsByLottery, selectedAnimalTypeGame]);

  useEffect(() => {
    clearRaffleNameInput();
  }, [selectedAnimalTypeGame]);

  useEffect(() => {
    if (selectedRaffles.length > 0 && betErrors.length > 0) {
      const animalBetErrors = buildAnimalRaffleWithError(selectedRaffles, betErrors);
      dispatch(actionSelectRaffle(animalBetErrors));
      let executeFilteringRaffles = false;

      animalBetErrors.forEach((raffle) => {
        if (raffle.animalitosRaffleId && raffle.animalitosRaffleError.length > 0) {
          raffle.animalitosRaffleError.forEach((error) => {
            enqueueSnackbar(error, {
              variant: 'error',
              hideIconVariant: true,
            });
          });

          selectRaffle(raffle.animalitosRaffleId);
          executeFilteringRaffles = true;
        }
      });

      if (executeFilteringRaffles) {
        filterRafflesByLottery();
      }
    }
  }, [betErrors]);

  useEffect(() => {
    if (foundErrorsOnAddTicket.length > 0 || betErrors.length > 0) {
      filterRafflesByLottery();
    }
  }, [foundErrorsOnAddTicket, betErrors]);

  useEffect(() => {
    const searchAnimalInput = document.getElementById('search-animal-input');
    if (selectedRaffles.length > 0 && searchAnimalInput) {
      searchAnimalInput.focus();
    }
  }, [selectedRaffles]);

  const filterActiveRafflesBySearchInput = () => {
    if (searchRaffleName.length > 0) {
      const activeRafflesFiltered = currentActiveRafflesByLottery.filter((raffle) =>
        raffle.animalitosRaffleName.toLowerCase().includes(searchRaffleName.toLowerCase()),
      );
      dispatch(actionSetActiveRaffles(activeRafflesFiltered));
    } else if (selectedRaffles.length > 0) {
      const animalFarmId = getAnimalFarmIdFromRaffleId(selectedRaffles[0].animalitosRaffleId);
      if (animalFarmId) {
        const newActiveRaffles = filterByFarmIdAndASelectedGameType(animalFarmId);
        dispatch(actionSetActiveRaffles(newActiveRaffles));
      }
    } else {
      filterRafflesByLottery();
    }
  };

  return {
    activeRaffles,
    selectRaffle,
    selectedRaffles,
    filterActiveRafflesBySearchInput,
    setSearchRaffleName,
    searchRaffleName,
    searchRaffleRef,
  };
};
