import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCurrentWorkShiftInfo } from '@controllers/CloseWorkShift/CloseWorkShift.api';
import { IWorkshiftDetail } from '@models/WorkShift.models';

interface WorkShiftState {
  workShiftInfo: IWorkshiftDetail;
  isLoading: boolean;
  status: 'idle' | 'failed' | 'success';
}

const initialState: WorkShiftState = {
  workShiftInfo: {
    workshiftStartDate: '',
    seller: '',
    salePoint: '',
    workshiftOpeningCashUsd: 0,
    workshiftOpeningCashVes: 0,
    workshiftTotalSoldUsd: 0,
    workshiftTotalSoldVes: 0,
    workshiftTotalPaidUsd: 0,
    workshiftTotalPaidVes: 0,
    workshiftCashOutUsd: 0,
    workshiftCashOutVes: 0,
    exchangeRate: 0,
    workshiftTotalCashOutVesExchange: 0,
    workshiftTotalCashOutUsdExchange: 0,
  },
  isLoading: false,
  status: 'idle',
};
export const getCurrentWorkShiftInfoAsync = createAsyncThunk(
  'workshift/getCurrentWorkShiftInfo',
  async () => {
    const response = await getCurrentWorkShiftInfo();
    return response as IWorkshiftDetail;
  },
);

export const workShiftSlice = createSlice({
  name: 'workshift',
  initialState,
  reducers: {
    actionSetWorkShiftInfo: (state, action) => {
      state.workShiftInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentWorkShiftInfoAsync.pending, (state) => {
        state.workShiftInfo = initialState.workShiftInfo;
        state.isLoading = true;
      })
      .addCase(getCurrentWorkShiftInfoAsync.fulfilled, (state, action) => {
        state.workShiftInfo = action.payload;
        state.isLoading = false;
        state.status = 'success';
      })
      .addCase(getCurrentWorkShiftInfoAsync.rejected, (state) => {
        state.isLoading = false;
        state.status = 'failed';
        state.workShiftInfo = initialState.workShiftInfo;
      });
  },
});

export const { actionSetWorkShiftInfo } = workShiftSlice.actions;

export const workShiftReducer = workShiftSlice.reducer;
