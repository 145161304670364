import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const isLoggedInSelector = createSelector(
  (state: RootState) => state.login.isLoggedIn,
  (isLoggedIn) => {
    return isLoggedIn;
  },
);

export const isLoadingSelector = createSelector(
  (state: RootState) => state.login.isLoading,
  (isLoading) => {
    return isLoading;
  },
);

export const statusSelector = createSelector(
  (state: RootState) => state.login.status,
  (status) => {
    return status;
  },
);

export const hasErrorsSelector = createSelector(
  (state: RootState) => state.login.hasErrors,
  (hasErrors) => {
    return hasErrors;
  },
);

export const errorMessageSelector = createSelector(
  (state: RootState) => state.login.errorMessage,
  (errorMessage) => {
    return errorMessage;
  },
);
