import { useAppDispatch, useAppSelector } from '@app/hooks';

import { actionSetBet, actionSetChance4BetTicketInfo } from '@features/Ticket/TicketSlice';

import {
  createBetFromCurrentChance4BetTicket,
  deleteLotteryFromTicket,
  deleteRaffleFromTicket,
} from '@helpers/chance4digits.helpers';
import { useEffect, useState } from 'react';
import { amountIsExceedingMaxValue, amountIsNotValid } from '@helpers/animalBet.helpers';
import { ITicketChance4Digits } from '@models/Chance4Digits.models';
import { CHANCE_4_DIGITS_RAFFLES_ID } from '@constants/app.constants';
import { IBet } from '@models/Bet.models';

import { chance4BetTicketInfoSelector, ticketBetsSelector } from '@selectors/ticket.selector';
import { chance4DigitsLotteriesSelector } from '@selectors/chance4Digits.selector';
import { selectedCurrencyIdSelector } from '@selectors/animalcurrency.selector';

export const useChance4DigitsTicket = () => {
  const dispatch = useAppDispatch();

  const ticketBets = useAppSelector(ticketBetsSelector);
  const chance4BetTicketInfo = useAppSelector(chance4BetTicketInfoSelector);
  const chance4DigitsLotteries = useAppSelector(chance4DigitsLotteriesSelector);
  const selectedCurrencyId = useAppSelector(selectedCurrencyIdSelector);

  const [formHasErrors, setFormHasErrors] = useState(false);

  const deleteLottery = (lotteryId: number) => {
    dispatch(
      actionSetChance4BetTicketInfo(deleteLotteryFromTicket(chance4BetTicketInfo, lotteryId)),
    );
  };

  const deleteLotteryRaffle = (lotteryId: number, raffleId: number) => {
    dispatch(
      actionSetChance4BetTicketInfo(
        deleteRaffleFromTicket(chance4BetTicketInfo, lotteryId, raffleId),
      ),
    );
  };

  useEffect(() => {
    let copyNewChanceBets: IBet[] = ticketBets.filter(
      (bet) => bet.gameTypeId !== CHANCE_4_DIGITS_RAFFLES_ID,
    );

    if (chance4BetTicketInfo.length > 0) {
      const builtChance3DigitsTicketBets =
        createBetFromCurrentChance4BetTicket(chance4BetTicketInfo);
      copyNewChanceBets = copyNewChanceBets.concat(builtChance3DigitsTicketBets);
    }
    dispatch(actionSetBet(copyNewChanceBets));
  }, [chance4BetTicketInfo]);

  const setEditableRaffle = (indexLottery: number, index: number, finishEdit?: boolean) => {
    const lotteryObject = chance4BetTicketInfo[indexLottery];
    const raffleObject = {
      ...lotteryObject.raffleChance4DigitsBet[index],
      isEditing: !finishEdit,
    };

    const newChanceBetTicketInfo = chance4BetTicketInfo.map((lottery, i) => {
      if (i === indexLottery) {
        return {
          ...lottery,
          raffleChance4DigitsBet: lottery.raffleChance4DigitsBet.map((raffle, j) => {
            if (j === index) {
              return raffleObject;
            }
            return raffle;
          }),
        };
      }
      return lottery;
    });

    dispatch(actionSetChance4BetTicketInfo(newChanceBetTicketInfo));
  };

  const updateChanceBetAmount = (
    indexLottery: number,
    indexRaffle: number,
    indexBet: number,
    amount: string,
  ) => {
    let hasErrorForm = false;
    const newChanceTicketInfo: ITicketChance4Digits[] = JSON.parse(
      JSON.stringify(chance4BetTicketInfo),
    );

    const lotterySetting = chance4DigitsLotteries.find(
      (chanceLotteries) =>
        chanceLotteries.chanceFourLotteryId === newChanceTicketInfo[indexLottery].lotteryId,
    );
    const raffleSetting = lotterySetting?.chanceLotterySettings.find(
      (raffleSetting) => raffleSetting.currencyId === selectedCurrencyId,
    );

    if (raffleSetting) {
      const exceedMaxAmount = amountIsExceedingMaxValue(
        raffleSetting.maxBetByChance,
        Number(amount),
      );
      const isAmountNotValid = amountIsNotValid(amount);

      let errorMessage = '';
      if (exceedMaxAmount) {
        hasErrorForm = true;
        errorMessage = `Monto máximo es de ${raffleSetting.maxBetByChance}`;
      } else if (Number(amount) <= 0) {
        hasErrorForm = true;
        errorMessage = 'Monto debe ser mayor a 0';
      } else if (isAmountNotValid) {
        hasErrorForm = true;
        errorMessage = 'El número no es válido';
      }
      newChanceTicketInfo[indexLottery].raffleChance4DigitsBet[indexRaffle].chance4DigitsBet[
        indexBet
      ] = {
        ...chance4BetTicketInfo[indexLottery].raffleChance4DigitsBet[indexRaffle].chance4DigitsBet[
          indexBet
        ],
        betAmount: amount,
        error: errorMessage,
        hasError: hasErrorForm,
      };

      if (hasErrorForm) {
        setFormHasErrors(true);
      } else {
        setFormHasErrors(false);
      }

      newChanceTicketInfo[indexLottery].totalBet = newChanceTicketInfo[
        indexLottery
      ].raffleChance4DigitsBet.reduce(
        (acc, chance4Digits) =>
          chance4Digits.chance4DigitsBet.reduce((acc2, bet) => acc2 + Number(bet.betAmount), 0),
        0,
      );

      dispatch(actionSetChance4BetTicketInfo(newChanceTicketInfo));
    }
  };

  return {
    chance4BetTicketInfo,
    deleteLottery,
    deleteLotteryRaffle,
    setEditableRaffle,
    updateChanceBetAmount,
    formHasErrors,
  };
};
