import { AxiosError, AxiosResponse } from 'axios';
import httpCommon from '@config/http-common';
import { ITicketWinner, ITicketWinnerResponse } from '@models/TicketWinner.models';

export const getTicketWinnerInfo = async (
  ticketNumber: number | void,
  ticketCode: string | void,
) => {
  try {
    const response: AxiosResponse<ITicketWinnerResponse<ITicketWinner[]>> = await httpCommon.get(
      `TicketWinner/get-ticket-winner-info${ticketNumber ? '/' + ticketNumber : ''} ${
        ticketCode ? '/' + ticketCode : ''
      }`.replace(/\s/g, ''),
    );
    return response?.data || [];
  } catch (error) {
    if (error instanceof AxiosError) {
      return error.response?.data?.message || 'unknown error';
    }
    return 'unknown error';
  }
};
