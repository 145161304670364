import { Chance3DigitsRaffle } from '@models/Chance3Digits.models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Chance3RaffleState {
  chance3Raffles: Chance3DigitsRaffle[];
  chance3RaffleMaxBet3: Chance3DigitsRaffle[];
  chance3RaffleMaxBet2: Chance3DigitsRaffle[];
  selectedChance3RaffleMaxBet3: Chance3DigitsRaffle[];
  selectedChance3RaffleMaxBet2: Chance3DigitsRaffle[];
  isRaffleSelected: boolean;
}

const initialState: Chance3RaffleState = {
  chance3Raffles: [],
  chance3RaffleMaxBet3: [],
  chance3RaffleMaxBet2: [],
  selectedChance3RaffleMaxBet3: [],
  selectedChance3RaffleMaxBet2: [],
  isRaffleSelected: false,
};

export const chance3RaffleSlice = createSlice({
  name: 'chance3Raffles',
  initialState,
  reducers: {
    actionSetChance3Raffles: (state, action: PayloadAction<Chance3DigitsRaffle[]>) => {
      state.chance3Raffles = action.payload;
    },
    actionSetChance3RaffleMaxBet3: (state, action: PayloadAction<Chance3DigitsRaffle[]>) => {
      state.chance3RaffleMaxBet3 = action.payload;
    },
    actionSetChance3RaffleMaxBet2: (state, action: PayloadAction<Chance3DigitsRaffle[]>) => {
      state.chance3RaffleMaxBet2 = action.payload;
    },
    actionSetChanceMaxBet: (
      state,
      action: PayloadAction<{ maxBet3?: Chance3DigitsRaffle[]; maxBet2?: Chance3DigitsRaffle[] }>,
    ) => {
      state.chance3RaffleMaxBet3 = action.payload.maxBet3 ?? [];
      state.chance3RaffleMaxBet2 = action.payload.maxBet2 ?? [];
    },
    actionCleanChance3RaffleState: (state) => {
      state.chance3RaffleMaxBet3 = [];
      state.chance3RaffleMaxBet2 = [];
      state.selectedChance3RaffleMaxBet3 = [];
      state.selectedChance3RaffleMaxBet2 = [];
      state.isRaffleSelected = false;
    },
    actionSetIsRaffleSelected: (state, action: PayloadAction<boolean>) => {
      state.isRaffleSelected = action.payload;
    },
    actionSetSelectedRaffleMaxBet3: (state, action: PayloadAction<Chance3DigitsRaffle[]>) => {
      state.selectedChance3RaffleMaxBet3 = action.payload;
    },
    actionSetSelectedRaffleMaxBet2: (state, action: PayloadAction<Chance3DigitsRaffle[]>) => {
      state.selectedChance3RaffleMaxBet2 = action.payload;
    },
  },
});

export const {
  actionSetChance3Raffles,
  actionSetChanceMaxBet,
  actionSetChance3RaffleMaxBet3,
  actionSetChance3RaffleMaxBet2,
  actionCleanChance3RaffleState,
  actionSetIsRaffleSelected,
  actionSetSelectedRaffleMaxBet3,
  actionSetSelectedRaffleMaxBet2,
} = chance3RaffleSlice.actions;

export const chanceRaffleReducer = chance3RaffleSlice.reducer;
