import QzTray from 'qz-tray';
const signingApiEndpoint = `${process.env.REACT_APP_ENDPOINT_BASE_URL}Signing/validate-sign`;

export const certificatePromise = () => {
  QzTray.security.setCertificatePromise(
    (resolve: (value: string) => void, reject: (error: string) => void) => {
      fetch('signing/digital-certificate.txt', {
        cache: 'no-store',
        headers: { 'Content-Type': 'text/plain' },
      })
        .then((response) => {
          if (response.ok) {
            response.text().then(resolve).catch(reject);
          } else {
            reject(response.statusText);
          }
        })
        .catch(reject);
    },
  );
};

export const signDataOnServer = async (toSign: string): Promise<string> => {
  try {
    const response = await fetch(signingApiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ request: toSign }),
    });

    if (response.ok) {
      return response.text();
    } else {
      console.error('Error signing data on the server:', response.statusText);
      throw new Error('Error signing data on the server');
    }
  } catch (error) {
    console.error('Error signing data on the server:', error);
    throw error;
  }
};
