import { createSlice } from '@reduxjs/toolkit';

interface SignOutSliceState {
  isLoading: boolean;
  openDialog: boolean;
}

export const initialState: SignOutSliceState = {
  isLoading: false,
  openDialog: false,
};

export const signOutSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    actionShowSignOutDialog: (state) => {
      state.openDialog = true;
    },
    actionHideSignOutDialog: (state) => {
      state.openDialog = false;
    },
    actionEnableIsLoading: (state) => {
      state.isLoading = true;
    },
    actionDisableIsLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  actionShowSignOutDialog,
  actionHideSignOutDialog,
  actionEnableIsLoading,
  actionDisableIsLoading,
} = signOutSlice.actions;

export const signOutReducer = signOutSlice.reducer;
