import React from 'react';
import { Card, CardActionArea, CardMedia, Paper, SvgIcon } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import styles from './AnimalCard.module.scss';
import { blue, grey } from '@mui/material/colors';
import { IAnimalDetail } from '@models/Animal.models';

interface AnimalCardProps {
  animal: IAnimalDetail;
  selectedAnimals: IAnimalDetail[];
  selectAnimalAction: (animal: IAnimalDetail) => void;
  canSelectANewAnimal: boolean;
}

function AnimalCard({
  animal,
  selectedAnimals,
  selectAnimalAction,
  canSelectANewAnimal,
}: AnimalCardProps) {
  const isSelected = selectedAnimals.some(
    (selectedAnimal) => selectedAnimal.animalId === animal.animalId,
  );

  const disabledCard = !canSelectANewAnimal && !isSelected;

  const disabledCardStyle = {
    opacity: disabledCard ? 0.5 : 1,
    cursor: disabledCard ? 'not-allowed' : 'pointer',
    backgroundColor: grey[200],
  };

  return (
    <Paper className={styles.AnimalCard} tabIndex={-1}>
      {isSelected && (
        <div className={styles.isSelected}>
          <SvgIcon sx={{ color: blue[800] }} component={CheckCircleIcon} />
        </div>
      )}
      <Card sx={disabledCard ? disabledCardStyle : undefined}>
        <CardActionArea disabled={disabledCard} onClick={() => selectAnimalAction(animal)}>
          <CardMedia
            sx={{ objectFit: 'contain' }}
            component='img'
            height='60'
            image={`${process.env.REACT_APP_PUBLIC_URL}/animals/${animal.animalImageUrl}`}
            alt={animal.animalName}
          />
        </CardActionArea>
      </Card>
    </Paper>
  );
}

export default AnimalCard;
