import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const ticketBetsSelector = createSelector(
  (state: RootState) => state.ticket.ticketBets,
  (ticketBets) => {
    return ticketBets;
  },
);

export const animalBetTicketInfoSelector = createSelector(
  (state: RootState) => state.ticket.animalBetTicketInfo,
  (animalBetTicketInfo) => {
    return animalBetTicketInfo;
  },
);

export const chance3BetTicketInfoSelector = createSelector(
  (state: RootState) => state.ticket.chance3BetTicketInfo,
  (chance3BetTicketInfo) => {
    return chance3BetTicketInfo;
  },
);

export const chance4BetTicketInfoSelector = createSelector(
  (state: RootState) => state.ticket.chance4BetTicketInfo,
  (chance4BetTicketInfo) => {
    return chance4BetTicketInfo;
  },
);

export const chanceZodiacBetTicketInfoSelector = createSelector(
  (state: RootState) => state.ticket.chanceZodiacBetTicketInfo,
  (chanceZodiacBetTicketInfo) => {
    return chanceZodiacBetTicketInfo;
  },
);

export const foundErrorsOnAddTicketSelector = createSelector(
  (state: RootState) => state.ticket.foundErrorsOnAddTicket,
  (foundErrorsOnAddTicket) => {
    return foundErrorsOnAddTicket;
  },
);

export const salePointIdSelector = createSelector(
  (state: RootState) => state.ticket.salePointId,
  (salePointId) => {
    return salePointId;
  },
);

export const currencyIdSelector = createSelector(
  (state: RootState) => state.ticket.currencyId,
  (currencyId) => {
    return currencyId;
  },
);

export const isEditingSelector = createSelector(
  (state: RootState) => state.ticket.isEditing,
  (isEditing) => {
    return isEditing;
  },
);

export const raffleIdSelector = createSelector(
  (state: RootState) => state.ticket.raffleId,
  (raffleId) => {
    return raffleId;
  },
);

export const isLoadingSelector = createSelector(
  (state: RootState) => state.ticket.isLoading,
  (isLoading) => {
    return isLoading;
  },
);

export const statusTicketSelector = createSelector(
  (state: RootState) => state.ticket.status,
  (status) => {
    return status;
  },
);

export const betResponseSelector = createSelector(
  (state: RootState) => state.ticket.betResponse,
  (betResponse) => {
    return betResponse;
  },
);

export const betErrorsSelector = createSelector(
  (state: RootState) => state.ticket.betErrors,
  (betErrors) => {
    return betErrors;
  },
);
