import { useAppDispatch, useAppSelector } from '@app/hooks';
import { batch } from 'react-redux';
import { actionSetSelectedChance3DigitsSetting } from '@features/Currency/Currency.slice';
import { useChance3Raffles } from '@features/Chance3Digits/Chance3Raffles/Chance3Raffle.hook';
import { actionCleanChance3RaffleState } from '@features/Chance3Digits/Chance3Raffles/Chance3Raffle.slice';
import { actionCleanTicketState } from '@features/Ticket/TicketSlice';
import {
  chance3RaffleMaxBet2Selector,
  chance3RaffleMaxBet3Selector,
} from '@selectors/chance3Raffle.selector';

export const useChance3DigitsCurrency = () => {
  const dispatch = useAppDispatch();
  const { setChanceMaxBetBy } = useChance3Raffles();

  const chance3RaffleMaxBet3 = useAppSelector(chance3RaffleMaxBet3Selector);
  const chance3RaffleMaxBet2 = useAppSelector(chance3RaffleMaxBet2Selector);

  const onSelectChanceSetting = () => {
    batch(() => {
      dispatch(actionCleanChance3RaffleState());
      setChanceMaxBetBy();
      dispatch(actionSetSelectedChance3DigitsSetting({}));
      dispatch(actionCleanTicketState());
    });
  };

  const shouldAskBeforeChangeCurrency = () => {
    const raffleMaxBet3Selected = chance3RaffleMaxBet3.find(
      (raffle) => raffle.chanceThreeRaffleSelected,
    );
    const raffleMaxBet2Selected = chance3RaffleMaxBet2.find(
      (raffle) => raffle.chanceThreeRaffleSelected,
    );

    return raffleMaxBet3Selected || raffleMaxBet2Selected;
  };

  return {
    shouldAskBeforeChangeCurrency,
    onSelectChanceSetting,
  };
};
