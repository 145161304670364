import { IRaffleDetail } from '@models/Raffle.models';
import { DateTime } from 'luxon';
import { ErrorValidateBet } from '@models/Bet.models';
import { ANIMAL_RAFFLES_ID, ANIMAL_TRIPLETA_GAME_ID } from '@constants/app.constants';
import { IAnimalsActiveByLottery } from '@models/AnimalsLottery.models';

export const isRaffleActive = (raffle: IRaffleDetail): boolean => {
  const now = DateTime.local();
  const raffleEndDate = DateTime.fromISO(raffle.animalitosRaffleBetEndDate);
  return raffleEndDate > now;
};

export const buildAnimalRaffleWithError = (
  selectedRaffle: IRaffleDetail[],
  errorBetList: ErrorValidateBet[],
) => {
  return selectedRaffle.map((raffle) => {
    const errors: string[] = [];

    errorBetList.forEach((error) => {
      error.errorList.forEach((errorDetail) => {
        if (
          errorDetail.raffleId === raffle.animalitosRaffleId &&
          errorDetail.gameTypeId === ANIMAL_RAFFLES_ID
        ) {
          errors.push(errorDetail.error);
        }
      });
    });

    return {
      ...raffle,
      animalitosRaffleError: errors,
    };
  });
};

export const getErrorFromListToRaffle = (errorList: ErrorValidateBet, raffle: IRaffleDetail) => {
  const error = errorList.errorList.find(
    (error) =>
      error.raffleId === raffle.animalitosRaffleId && error.gameTypeId === ANIMAL_RAFFLES_ID,
  );
  return error?.error;
};

export const filterActiveRaffles = (
  activeAnimalsByLottery: IAnimalsActiveByLottery[],
  selectedAnimalTypeGame: number,
  selectedCurrencyId: number,
) => {
  let activeRaffles: IAnimalsActiveByLottery[];

  if (selectedAnimalTypeGame !== ANIMAL_TRIPLETA_GAME_ID) {
    activeRaffles = activeAnimalsByLottery.filter(
      (lottery) => lottery.animalitosLotteryGameType === selectedAnimalTypeGame,
    );
  } else {
    activeRaffles = activeAnimalsByLottery.filter((lottery) =>
      lottery.animalitosLotterySettings.find(
        (raffleSetting) =>
          raffleSetting.hasTripleta && raffleSetting.currencyId === selectedCurrencyId,
      ),
    );
  }

  const activeRafflesFiltered = activeRaffles.map((raffle) => {
    return raffle.animalitosRaffleDetail.filter((raffleDetail) => {
      return isRaffleActive(raffleDetail);
    });
  });

  return activeRafflesFiltered.flat() || [];
};
