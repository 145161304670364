import { AxiosError, AxiosResponse } from 'axios';
import httpCommon from '@config/http-common';
import { Chance3DigitsLottery, Chance3DigitsResponse } from '@models/Chance3Digits.models';

export const getActiveAnimalByRafflesCurrentDate = async (filter: number | void) => {
  try {
    const response: AxiosResponse<Chance3DigitsResponse<Chance3DigitsLottery[]>> =
      await httpCommon.get(
        `ChanceThreeRaffle/get-active-chance-three-raffles-for-current-date${
          filter ? '/' + filter : ''
        }`,
      );
    return response?.data?.response || [];
  } catch (error) {
    if (error instanceof AxiosError) {
      return error.response?.data?.message || 'unknown error';
    }
    return 'unknown error';
  }
};
