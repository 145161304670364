import React, { useEffect, useState } from 'react';

import { MENU_LINKS, ADMIN_LINKS, ACTION_LINKS } from './links';
import MenuItemApp from '@components/Toolbar/components/MenuItemApp/MenuItemApp';
import Box from '@mui/material/Box';
import AdministrationMenu from '@components/Toolbar/components/AdministrationMenu/AdministrationMenu';
import ConfirmSignOut from '@components/ConfirmSignOut/ConfirmSignOut';
import { useSignout } from '@features/SignOut/SignOut.hook';
import SignOutMenu from '../SignOutMenu/SignOutMenu';
import { useAuth } from 'oidc-react/build/src/useAuth';
import Currency from '@features/Currency/Currency';

function MenuApp() {
  const auth = useAuth();
  const { onConfirmSignOut, isLoading, openDialog, onOpenSignOutDialog } = useSignout();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorElSignOut, setAnchorElSignOut] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openSignOut = Boolean(anchorElSignOut);
  const [username, setUsername] = useState<string | undefined>('');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOutClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSignOut(event.currentTarget);
  };
  const handleSignoutClose = () => {
    setAnchorElSignOut(null);
  };

  const checkLinkIsActive = (link: string) => {
    if (link === '/') return window.location.pathname === link;
    const currentPath = window.location.pathname;
    return currentPath.includes(link);
  };

  const adminLinkIsActive = () => {
    const currentPath = window.location.pathname;
    return ADMIN_LINKS.some((link) => currentPath.includes(link.path));
  };

  const signOutLinkIsActive = () => {
    const currentPath = window.location.pathname;
    return ACTION_LINKS.some((link) => currentPath.includes(link.path));
  };

  const getUsername = () => {
    auth.userManager.getUser().then((user) => {
      setUsername(user?.profile.name);
    });
  };

  useEffect(() => {
    getUsername();
  }, [auth]);

  const buildMenuItems = MENU_LINKS.map((menuLink, index) => {
    return (
      <MenuItemApp
        key={`menu-app-${menuLink.label}-${index}`}
        label={menuLink.label}
        link={menuLink.path}
        isActive={checkLinkIsActive(menuLink.path)}
      />
    );
  });

  return (
    <>
      <Box display='flex' flexGrow={1} sx={{ height: '70px' }}>
        {buildMenuItems}
      </Box>
      <Box display='flex' flexGrow={1} sx={{ height: '70px' }}>
        <AdministrationMenu
          anchorEl={anchorEl}
          onClick={handleClick}
          open={open}
          isActive={adminLinkIsActive()}
          onClose={handleClose}
          checkLinkIsActive={checkLinkIsActive}
        />
      </Box>
      <Box display='flex' flexGrow={1} sx={{ height: '70px' }}>
        <Currency />
      </Box>
      <Box display='flex' flexGrow={2} sx={{ height: '70px', justifyContent: 'flex-end' }}>
        <SignOutMenu
          anchorEl={anchorElSignOut}
          onClick={handleSignOutClick}
          open={openSignOut}
          isActive={signOutLinkIsActive()}
          onClose={handleSignoutClose}
          checkLinkIsActive={checkLinkIsActive}
          username={username}
          onOpenDialogSignOut={onOpenSignOutDialog}
        />
      </Box>
      <ConfirmSignOut
        onConfirmSignOut={onConfirmSignOut}
        openDialogSignOut={openDialog}
        isLoading={isLoading}
      />
    </>
  );
}

export default MenuApp;
