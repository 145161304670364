import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chance4BetArray } from '@models/Chance4Digits.models';

interface IChancePayloadAction {
  seriesBet: string[];
  betAmount: number;
}

const initialState: Chance4BetArray = {
  arrayNumbers: [],
  betAmount: 0,
};

const seriesBetSlice = createSlice({
  name: 'seriesBet',
  initialState,
  reducers: {
    actionSetSeriesArray: (state, action: PayloadAction<IChancePayloadAction>) => {
      state.arrayNumbers = action.payload.seriesBet;
      state.betAmount = action.payload.betAmount;
    },
    actionCleanSeriesState: (state) => {
      state.arrayNumbers = [];
      state.betAmount = 0;
    },
  },
});

export const { actionSetSeriesArray } = seriesBetSlice.actions;

export const seriesChance4BetReducer = seriesBetSlice.reducer;
