import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAnimalsActiveByLottery } from '@models/AnimalsLottery.models';

interface LotteryState {
  selectedLottery: number;
  selectedLotteryName: string;
  selectedActiveLottery: IAnimalsActiveByLottery;
  selectedLotteryFruitCombined?: boolean | undefined;
}

const initialState: LotteryState = {
  selectedLottery: 0,
  selectedLotteryName: '',
  selectedActiveLottery: {} as IAnimalsActiveByLottery,
  selectedLotteryFruitCombined: false,
};

export const lotterySlice = createSlice({
  name: 'lottery',
  initialState,
  reducers: {
    actionSelectLottery: (state, action: PayloadAction<IAnimalsActiveByLottery>) => {
      state.selectedLottery = action.payload.animalitosLotteryId;
      state.selectedLotteryName = action.payload.animalitosLotteryName;
      state.selectedActiveLottery = action.payload;
      state.selectedLotteryFruitCombined = action.payload.animalitosLotteryFruitCombined;
    },
    actionSetLotteryFruitCombined: (state, action: PayloadAction<boolean>) => {
      state.selectedLotteryFruitCombined = action.payload;
    },
  },
});

export const { actionSelectLottery, actionSetLotteryFruitCombined } = lotterySlice.actions;

export const lotteryReducer = lotterySlice.reducer;
