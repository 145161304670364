import { ChanceZodiacLottery } from '@models/ChanceZodiac.models';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getActiveChanceZodiacByRafflesCurrentDate } from '@controllers/ChanceZodiac/ChanceZodiac.api';

interface ChanceZodiacState {
  chanceZodiacLotteries: ChanceZodiacLottery[];
  isLoading: boolean;
  status: 'idle' | 'failed' | 'success';
}

const initialState: ChanceZodiacState = {
  chanceZodiacLotteries: [],
  isLoading: false,
  status: 'idle',
};

export const getChanceZodiacLotteriesAsync = createAsyncThunk(
  'ChanceZodiac/getChanceZodiacLotteriesAsync',
  async (filter: number | void) => {
    const response = await getActiveChanceZodiacByRafflesCurrentDate(filter);
    return response as ChanceZodiacLottery[];
  },
);

export const chanceZodiacSlice = createSlice({
  name: 'ChanceZodiac',
  initialState,
  reducers: {
    actionSetChanceZodiacLotteries: (state, action) => {
      state.chanceZodiacLotteries = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChanceZodiacLotteriesAsync.pending, (state) => {
        state.chanceZodiacLotteries = [];
        state.isLoading = true;
      })
      .addCase(getChanceZodiacLotteriesAsync.fulfilled, (state, action) => {
        state.chanceZodiacLotteries = action.payload;
        state.isLoading = false;
        state.status = 'success';
      })
      .addCase(getChanceZodiacLotteriesAsync.rejected, (state) => {
        state.isLoading = false;
        state.status = 'failed';
        state.chanceZodiacLotteries = [];
      });
  },
});

export const { actionSetChanceZodiacLotteries } = chanceZodiacSlice.actions;
export const chanceZodiacReducer = chanceZodiacSlice.reducer;
