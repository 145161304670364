import React, { CSSProperties } from 'react';
import { Card, CardActionArea, CardMedia, Grid, SvgIcon } from '@mui/material';
import Typography from '@mui/material/Typography';
import PetsIcon from '@mui/icons-material/Pets';
import CardContent from '@mui/material/CardContent';

import { useAnimalTypeGame } from '@features/AnimalsGame/AnimalTypeGame/AnimalTypeGame.hook';

const AnimalTypeGame = () => {
  const { selectedAnimalTypeGame, onHandleSelectAnimalTypeGame } = useAnimalTypeGame();

  const activeLotteryCard: CSSProperties = {
    backgroundColor: 'primary.main',
    color: 'white',
  };
  const lotteryCardStyle = (isActiveLottery: boolean) => {
    if (isActiveLottery) {
      return activeLotteryCard;
    }
    return {};
  };

  const animalTypeGameCard = (animalType: string, animalTypeGameId: number) => (
    <Card sx={lotteryCardStyle(selectedAnimalTypeGame === animalTypeGameId)}>
      <CardActionArea
        sx={{ display: 'flex', flexDirection: 'column' }}
        onClick={() => onHandleSelectAnimalTypeGame(animalTypeGameId)}
      >
        <CardMedia>
          <SvgIcon sx={{ height: '16px', paddingTop: 1 }} component={PetsIcon} />
        </CardMedia>
        <CardContent sx={{ padding: 0.5 }}>
          <Typography sx={{ fontSize: '14px' }}>
            <Typography component='span'>{animalType}</Typography>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        {animalTypeGameCard('Animalitos', 1)}
      </Grid>
      <Grid item xs={3}>
        {animalTypeGameCard('Animalitos + Frutas', 2)}
      </Grid>
      <Grid item xs={3}>
        {animalTypeGameCard('Frutas', 3)}
      </Grid>
      <Grid item xs={3}>
        {animalTypeGameCard('Tripleta', 5)}
      </Grid>
    </Grid>
  );
};

export default AnimalTypeGame;
