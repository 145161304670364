import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useEffect, useRef, useState } from 'react';
import {
  actionSetChanceMaxBet,
  actionSetIsRaffleSelected,
  actionSetSelectedRaffleMaxBet3,
} from '@features/Chance3Digits/Chance3Raffles/Chance3Raffle.slice';
import { Chance3DigitsRaffle } from '@models/Chance3Digits.models';

import {
  actionSetActiveChance3DigitsSettings,
  actionSetSelectedChance3DigitsSetting,
} from '@features/Currency/Currency.slice';
import { filterRaffleByMaxBet } from '@helpers/chance3digits.helpers';
import { ChanceSettings } from '@models/chance.models';
import { useHotkeys } from 'react-hotkeys-hook';
import { SEARCH_RAFFLES_SHORTCUT } from '@constants/shortcuts.constants';
import { cloneDeep } from 'lodash';
import { CHANCE_3_CIFRAS_PATH } from '@constants/url.constants';
import { chance3digitsSelector } from '@selectors/chance3digits.selector';
import {
  chance3RaffleMaxBet2Selector,
  chance3RaffleMaxBet3Selector,
  chance3RaffleSelectedRaffleMaxBet2Selector,
  chance3RaffleSelectedRaffleMaxBet3Selector,
} from '@selectors/chance3Raffle.selector';
import { selectedCurrencyIdSelector } from '@selectors/animalcurrency.selector';

export const useChance3Raffles = () => {
  const dispatch = useAppDispatch();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const chance3DigitsLotteries = useAppSelector(chance3digitsSelector);
  const chance3RaffleMaxBet3 = useAppSelector(chance3RaffleMaxBet3Selector);
  const chance3RaffleMaxBet2 = useAppSelector(chance3RaffleMaxBet2Selector);
  const selectedCurrencyId = useAppSelector(selectedCurrencyIdSelector);
  const selectedChance3RaffleMaxBet2 = useAppSelector(chance3RaffleSelectedRaffleMaxBet2Selector);
  const selectedChance3RaffleMaxBet3 = useAppSelector(chance3RaffleSelectedRaffleMaxBet3Selector);

  const [searchInput, setSearchInput] = useState('');
  const [rafflesMax3Digits, setRafflesMax3Digits] =
    useState<Chance3DigitsRaffle[]>(chance3RaffleMaxBet3);
  const [rafflesMax2Digits, setRafflesMax2Digits] =
    useState<Chance3DigitsRaffle[]>(chance3RaffleMaxBet2);

  useHotkeys(
    SEARCH_RAFFLES_SHORTCUT,
    () => {
      // eslint-disable-next-line
      debugger;
      if (searchInputRef.current) {
        searchInputRef.current.focus();
        searchInputRef.current.value = '';
      }
    },
    {
      enabled: window.location.pathname === CHANCE_3_CIFRAS_PATH,
    },
  );

  useEffect(() => {
    setRafflesMax3Digits(chance3RaffleMaxBet3);
    setRafflesMax2Digits(chance3RaffleMaxBet2);
  }, [chance3RaffleMaxBet3, chance3RaffleMaxBet2]);

  const searchForRaffles = () => {
    if (searchInput.length > 0) {
      const auxChance3RaffleMaxBet3 = cloneDeep(chance3RaffleMaxBet3);
      const auxChance3RaffleMaxBet2 = cloneDeep(chance3RaffleMaxBet2);

      const filterRaffle3Digits = auxChance3RaffleMaxBet3.filter((raffle) =>
        raffle.chanceThreeLotteryName.toLowerCase().includes(searchInput.toLowerCase()),
      );

      const filterRaffle2Digits = auxChance3RaffleMaxBet2.filter((raffle) =>
        raffle.chanceThreeLotteryName.toLowerCase().includes(searchInput.toLowerCase()),
      );

      selectedChance3RaffleMaxBet3.forEach((raffle) => {
        const indexChange3Digits = filterRaffle3Digits.findIndex(
          (raffleOriginal) => raffleOriginal.chanceThreeRaffleId === raffle.chanceThreeRaffleId,
        );
        if (indexChange3Digits !== -1) {
          filterRaffle3Digits[indexChange3Digits].chanceThreeRaffleSelected = true;
        }
      });

      selectedChance3RaffleMaxBet2.forEach((raffle) => {
        const indexChange2Digits = filterRaffle2Digits.findIndex(
          (raffleOriginal) => raffleOriginal.chanceThreeRaffleId === raffle.chanceThreeRaffleId,
        );
        if (indexChange2Digits !== -1) {
          filterRaffle2Digits[indexChange2Digits].chanceThreeRaffleSelected = true;
        }
      });

      setRafflesMax3Digits(filterRaffle3Digits);
      setRafflesMax2Digits(filterRaffle2Digits);
    }

    if (searchInput.length === 0) {
      const auxChance3RaffleMaxBet2 = cloneDeep(chance3RaffleMaxBet2);
      const auxChance3RaffleMaxBet3 = cloneDeep(chance3RaffleMaxBet3);

      selectedChance3RaffleMaxBet3.forEach((raffle) => {
        const indexChange3Digits = chance3RaffleMaxBet3.findIndex(
          (raffleOriginal) => raffleOriginal.chanceThreeRaffleId === raffle.chanceThreeRaffleId,
        );
        if (indexChange3Digits !== -1) {
          auxChance3RaffleMaxBet3[indexChange3Digits].chanceThreeRaffleSelected = true;
        }
      });

      selectedChance3RaffleMaxBet2.forEach((raffle) => {
        const indexChange2Digits = chance3RaffleMaxBet2.findIndex(
          (raffleOriginal) => raffleOriginal.chanceThreeRaffleId === raffle.chanceThreeRaffleId,
        );
        if (indexChange2Digits !== -1) {
          auxChance3RaffleMaxBet2[indexChange2Digits].chanceThreeRaffleSelected = true;
        }
      });
      setRafflesMax3Digits(auxChance3RaffleMaxBet3);
      setRafflesMax2Digits(auxChance3RaffleMaxBet2);
    }
  };

  const disabledRaffles = (
    chance3RaffleMaxBet: Chance3DigitsRaffle[],
    setDisabledValue: boolean,
  ) => {
    return chance3RaffleMaxBet.map((raffle) => ({
      ...raffle,
      chanceThreeRaffleDisabled: setDisabledValue,
    }));
  };

  const setRaffleSettings = (
    isMaxBet3: boolean,
    settings3Digits: ChanceSettings[] | undefined,
    settings2Digits: ChanceSettings[] | undefined,
  ) => {
    let raffleSettings;
    if (isMaxBet3 && settings3Digits) {
      raffleSettings = settings3Digits.find((setting) => setting.currencyId === selectedCurrencyId);
    } else if (!isMaxBet3 && settings2Digits) {
      raffleSettings = settings2Digits.find((setting) => setting.currencyId === selectedCurrencyId);
    }
    if (raffleSettings) {
      dispatch(actionSetSelectedChance3DigitsSetting(raffleSettings));
    }
  };

  const setChance3DigitsLotteriesSettings = (lotteryId: number, isMaxBet3: boolean) => {
    const settings3Digits = chance3DigitsLotteries.find(
      (lottery) =>
        lottery.chanceThreeLotteryId === lotteryId &&
        lottery.chanceThreeLotteryMaxDigitsByBet === 3,
    );
    const settings2Digits = chance3DigitsLotteries.find(
      (lottery) =>
        lottery.chanceThreeLotteryId === lotteryId &&
        lottery.chanceThreeLotteryMaxDigitsByBet === 2,
    );
    dispatch(
      actionSetActiveChance3DigitsSettings({
        settings3Digits: settings3Digits?.chanceLotterySettings,
        settings2Digits: settings2Digits?.chanceLotterySettings,
      }),
    );
    setRaffleSettings(
      isMaxBet3,
      settings3Digits?.chanceLotterySettings,
      settings2Digits?.chanceLotterySettings,
    );
  };

  const checkIfRaffleIsSelected = (
    raffles: Chance3DigitsRaffle[],
    isMax3Bet: boolean,
    raffleId: number,
  ) => {
    const raffleSelected = raffles.find((raffle) => raffle.chanceThreeRaffleSelected);
    let alreadySelectedRaffles: Chance3DigitsRaffle[] = [];
    let raffleOtherBetSelected;
    if (isMax3Bet) {
      alreadySelectedRaffles = selectedChance3RaffleMaxBet3.filter(
        (raffle) => raffle.chanceThreeRaffleId !== raffleId,
      );
      alreadySelectedRaffles = Array.from(
        new Set([
          ...selectedChance3RaffleMaxBet3.filter(
            (raffle) => raffle.chanceThreeRaffleId !== raffleId,
          ),
          ...raffles.filter((raffle) => raffle.chanceThreeRaffleSelected),
        ]),
      );
      dispatch(actionSetSelectedRaffleMaxBet3([...alreadySelectedRaffles]));

      raffleOtherBetSelected = !!rafflesMax2Digits.find(
        (raffle) => raffle.chanceThreeRaffleSelected,
      );
    } else {
      alreadySelectedRaffles = selectedChance3RaffleMaxBet2.filter(
        (raffle) => raffle.chanceThreeRaffleId !== raffleId,
      );
      raffleOtherBetSelected = !!rafflesMax3Digits.find(
        (raffle) => raffle.chanceThreeRaffleSelected,
      );
      dispatch(
        actionSetSelectedRaffleMaxBet3([
          ...alreadySelectedRaffles,
          ...raffles.filter((raffle) => raffle.chanceThreeRaffleSelected),
        ]),
      );
    }

    if (raffleSelected || raffleOtherBetSelected || alreadySelectedRaffles.length > 0) {
      dispatch(actionSetIsRaffleSelected(true));
    } else {
      dispatch(actionSetIsRaffleSelected(false));
    }
  };

  const onSelectChanceRaffle = (raffleId: number, isMaxBet3: boolean) => {
    if (isMaxBet3) {
      let raffleSelected = false;
      let lotterySelectedId = 0;
      const updatedRaffles = rafflesMax3Digits.map((raffle) => {
        if (raffle.chanceThreeRaffleId === raffleId) {
          lotterySelectedId = raffle.chanceThreeLotteryId;
          if (!raffle.chanceThreeRaffleSelected) {
            raffleSelected = true;
          }
          return { ...raffle, chanceThreeRaffleSelected: !raffle.chanceThreeRaffleSelected };
        }
        if (raffle.chanceThreeRaffleSelected) {
          raffleSelected = true;
        }
        return raffle;
      });
      setRafflesMax3Digits(updatedRaffles);
      setRafflesMax2Digits(disabledRaffles(rafflesMax2Digits, raffleSelected));
      if (raffleSelected) {
        setChance3DigitsLotteriesSettings(lotterySelectedId, isMaxBet3);
      }
      checkIfRaffleIsSelected(updatedRaffles, isMaxBet3, raffleId);
    } else {
      let raffleSelected = false;
      let lotterySelectedId = 0;
      const updatedRaffles = rafflesMax2Digits.map((raffle) => {
        if (raffle.chanceThreeRaffleId === raffleId) {
          lotterySelectedId = raffle.chanceThreeLotteryId;
          if (!raffle.chanceThreeRaffleSelected) {
            raffleSelected = true;
          }
          return { ...raffle, chanceThreeRaffleSelected: !raffle.chanceThreeRaffleSelected };
        }
        if (raffle.chanceThreeRaffleSelected) {
          raffleSelected = true;
        }
        return raffle;
      });

      setRafflesMax2Digits(updatedRaffles);
      setRafflesMax3Digits(disabledRaffles(rafflesMax3Digits, raffleSelected));
      if (raffleSelected) {
        setChance3DigitsLotteriesSettings(lotterySelectedId, isMaxBet3);
      }
      checkIfRaffleIsSelected(updatedRaffles, isMaxBet3, raffleId);
    }
  };

  const setChanceMaxBetBy = () => {
    const raffles = filterRaffleByMaxBet(chance3DigitsLotteries);
    dispatch(actionSetChanceMaxBet({ maxBet3: raffles.maxBet3, maxBet2: raffles.maxBet2 }));
  };

  useEffect(() => {
    setChanceMaxBetBy();
  }, [chance3DigitsLotteries]);

  return {
    onSelectChanceRaffle,
    chance3RaffleMaxBet3: rafflesMax3Digits,
    chance3RaffleMaxBet2: rafflesMax2Digits,
    setChanceMaxBetBy,
    searchForRaffles,
    setSearchInput,
    searchInputRef,
    searchInput,
  };
};
