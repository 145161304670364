import { StarSign } from '@models/StarSign.models';

export const starSignList: StarSign[] = [
  { starSignId: 1, starSignName: 'Capricornio', starSignImage: 'capricorn.png' },
  { starSignId: 2, starSignName: 'Acuario', starSignImage: 'aquarius.png' },
  { starSignId: 3, starSignName: 'Piscis', starSignImage: 'pisces.png' },
  { starSignId: 4, starSignName: 'Tauro', starSignImage: 'taurus.png' },
  { starSignId: 5, starSignName: 'Géminis', starSignImage: 'gemini.png' },
  { starSignId: 6, starSignName: 'Cáncer', starSignImage: 'cancer.png' },
  { starSignId: 7, starSignName: 'Leo', starSignImage: 'leo.png' },
  { starSignId: 8, starSignName: 'Virgo', starSignImage: 'virgo.png' },
  { starSignId: 9, starSignName: 'Libra', starSignImage: 'libra.png' },
  { starSignId: 10, starSignName: 'Escorpio', starSignImage: 'scorpio.png' },
  { starSignId: 11, starSignName: 'Sagitario', starSignImage: 'sagittarius.png' },
  { starSignId: 12, starSignName: 'Aries', starSignImage: 'aries.png' },
];
