import React from 'react';
import TextField from '@mui/material/TextField';
import { InputBaseComponentProps, SxProps, Theme } from '@mui/material';

interface InputNumberProps {
  disabled?: boolean;
  onChange: (value: string) => void;
  onPressBetShortcut?: () => void | null;
  value: number | string | undefined | null;
  variant?: 'standard' | 'outlined' | 'filled';
  sx?: SxProps<Theme>;
  fullWidth?: boolean;
  inputProps?: InputBaseComponentProps;
  helperText?: string | false;
  error?: boolean;
  name?: string;
  isAmount?: boolean;
  id?: string;
  className?: string;
}
function InputNumber({
  disabled,
  onChange,
  fullWidth,
  value,
  variant,
  error,
  isAmount,
  sx,
  inputProps,
  name,
  id,
  helperText,
  onPressBetShortcut,
}: InputNumberProps) {
  const beforeChange = (value: string) => {
    if (value.charAt(value.length - 1) === 'B' && onPressBetShortcut) {
      onPressBetShortcut();
    }
    if (isAmount) {
      const regex = /^\d{1,5}(\.|(\.\d{0,2}))?$/;
      if (value === '' || regex.test(value)) {
        onChange(value ?? '');
      }
    } else {
      const regex = /^[0-9\b]+$/;
      if (value === '' || regex.test(value)) {
        onChange(value ?? '');
      }
    }
  };

  return (
    <TextField
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      sx={sx}
      inputProps={inputProps}
      variant={variant ?? 'standard'}
      onChange={(e) => beforeChange(e.target.value)}
      type='text'
      name={name}
      value={value ?? ''}
      helperText={helperText}
      autoComplete={'off'}
      tabIndex={1}
      id={id}
    />
  );
}

export default InputNumber;
