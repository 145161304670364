import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Fab,
  Tooltip,
  Stack,
  TextField,
  CircularProgress,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTicketDuplicateModal } from './TicketDuplicateModal.hook';

const TicketDuplicateModal = () => {
  const {
    duplicateTicketModalShow,
    setDuplicateTicketModalShow,
    isLoading,
    setTicketNumber,
    ticketNumber,
    duplicateTicket,
  } = useTicketDuplicateModal();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTicketNumber(event.target.value);
  };

  return (
    <>
      <Fab
        size='small'
        color='primary'
        aria-label='add'
        sx={{ marginRight: 1 }}
        onClick={() => setDuplicateTicketModalShow(true)}
      >
        <Tooltip title={'Duplicar tiquete'}>
          <ContentCopyIcon color='inherit' />
        </Tooltip>
      </Fab>
      <Dialog open={duplicateTicketModalShow}>
        <DialogTitle variant={'h5'}>Duplicar tiquete</DialogTitle>
        <DialogContent>
          <Typography variant={'body1'}>
            Ingrese el número del tiquete que desea duplicar
          </Typography>
          <Stack
            sx={{ width: '100%', marginTop: 2 }}
            alignItems='center'
            direction='row'
            spacing={2}
          >
            <Typography sx={{ minWidth: '150px' }}>Nombres</Typography>
            <TextField
              id='ticket-number'
              placeholder='N° Tiquete'
              variant='outlined'
              autoComplete={'off'}
              sx={{ width: '100%' }}
              required
              defaultValue={ticketNumber}
              inputProps={{ maxLength: 100 }}
              onChange={handleInputChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => setDuplicateTicketModalShow(false)}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button variant='contained' onClick={duplicateTicket} disabled={isLoading}>
            Duplicar tiquete
            {isLoading && <CircularProgress size={24} />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TicketDuplicateModal;
