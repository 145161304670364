import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import PrivateRoute from '@app/PrivateRoute';

import AnimalController from './controllers/AnimalsLottery/AnimalController';
import LoginController from './controllers/Login/LoginController';

import * as PATHS from '@constants/url.constants';

import './App.scss';
const Chance3DigitsController = lazy(
  () => import('@controllers/Chance3Digits/Chance3DigitsController'),
);
const Chance4DigitsController = lazy(
  () => import('@controllers/Chance4Digits/Chance4DigitsController'),
);
const ChanceZodiacController = lazy(
  () => import('@controllers/ChanceZodiac/ChanceZodiacController'),
);
const TicketCancelController = lazy(
  () => import('@controllers/TicketCancel/TicketCancelController'),
);
const TicketWinnerController = lazy(
  () => import('@controllers/TicketWinner/TicketWinnerController'),
);
const CustomerController = lazy(() => import('@controllers/Customer/CustomerController'));

const CloseWorkShiftController = lazy(
  () => import('@controllers/CloseWorkShift/CloseWorkShiftController'),
);

import PageLoader from '@components/PageLoader/PageLoader';

function App() {
  return (
    <Suspense fallback={<PageLoader />}>
      <Router>
        <Routes>
          <Route path={PATHS.LOGIN_PATH} element={<LoginController />} />
          <Route
            index
            path={PATHS.ANIMALS_PATH}
            element={
              <PrivateRoute>
                <AnimalController />
              </PrivateRoute>
            }
          />
          <Route
            index
            path={PATHS.CHANCE_3_CIFRAS_PATH}
            element={
              <PrivateRoute>
                <Chance3DigitsController />
              </PrivateRoute>
            }
          />
          <Route
            index
            path={PATHS.CHANCE_ZODIACAL_PATH} // '/chance-zodiacal'
            element={
              <PrivateRoute>
                <ChanceZodiacController />
              </PrivateRoute>
            }
          />
          <Route
            index
            path={PATHS.CHANCE_4_CIFRAS_PATH} // '/chance-4-cifras'
            element={
              <PrivateRoute>
                <Chance4DigitsController />
              </PrivateRoute>
            }
          />
          <Route
            index
            path={PATHS.TICKET_CANCEL_PATH} // '/ticket-cancel'
            element={
              <PrivateRoute>
                <TicketCancelController />
              </PrivateRoute>
            }
          />
          <Route
            index
            path={PATHS.TICKET_WINNER_PATH} // '/ticket-winner'
            element={
              <PrivateRoute>
                <TicketWinnerController />
              </PrivateRoute>
            }
          />
          <Route
            index
            path={PATHS.CUSTOMER_PATH} // '/customer'
            element={
              <PrivateRoute>
                <CustomerController />
              </PrivateRoute>
            }
          />
          <Route
            index
            path={PATHS.CLOSE_WORKSHIFT_PATH} // '/close-workshift'
            element={
              <PrivateRoute>
                <CloseWorkShiftController />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
