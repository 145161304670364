const MENU_LINKS = [
  {
    label: 'Animalitos',
    path: '/',
  },
  {
    label: 'Chance 3 Cifras',
    path: '/chance-3-cifras',
  },
  {
    label: 'Chance Zodiacal',
    path: '/chance-zodiacal',
  },
  {
    label: 'Chance 4 Cifras',
    path: '/chance-4-cifras',
  },
];

const ACTION_LINKS = [
  {
    label: 'Cerrar Sesión',
    path: '/sign-out',
  },
];

const ADMIN_LINKS = [
  {
    label: 'Anulación tiquete',
    path: '/ticket-cancel',
  },
  {
    label: 'Pago de premios',
    path: '/ticket-winner',
  },
  {
    label: 'Clientes',
    path: '/customer',
  },
  {
    label: 'Cierre de turno',
    path: '/close-workshift',
  },
];

export { MENU_LINKS, ACTION_LINKS, ADMIN_LINKS };
