import {
  Chance3DigitsLottery,
  Chance3DigitsRaffle,
  Chance3TicketBetArray,
  ITicketChance3Digits,
  ITicketChance3DigitsDetail,
} from '@models/Chance3Digits.models';
import { cloneDeep, groupBy, uniqBy } from 'lodash';
import { DateTime } from 'luxon';

// @models
import { ErrorValidateBet, IBet } from '@models/Bet.models';

// @constants
import { CHANCE_3_DIGITS_RAFFLES_ID } from '@constants/app.constants';
import { lineBreak } from '@constants/printer.constants';

export const isRaffleActive = (Chance3DigitsRaffle: Chance3DigitsRaffle): boolean => {
  const now = DateTime.local();
  const raffleEndDate = DateTime.fromISO(Chance3DigitsRaffle.chanceThreeRaffleBetEndDate);
  return raffleEndDate > now;
};

export const filterRaffleByMaxBet = (
  chance3DigitsLotteries: Chance3DigitsLottery[],
): {
  maxBet3: Chance3DigitsRaffle[];
  maxBet2: Chance3DigitsRaffle[];
} => {
  if (chance3DigitsLotteries) {
    const filteredChanceRaffleDetailMaxBet2: Chance3DigitsRaffle[] = [];
    const filteredChanceRaffleDetailMaxBet3: Chance3DigitsRaffle[] = [];
    chance3DigitsLotteries.forEach((raffle) =>
      raffle.chanceThreeRaffleDetail.forEach((raffleDetail) => {
        if (isRaffleActive(raffleDetail)) {
          if (raffle.chanceThreeLotteryMaxDigitsByBet === 2) {
            filteredChanceRaffleDetailMaxBet2.push({
              ...raffleDetail,
              chanceThreeLotteryId: raffle.chanceThreeLotteryId,
              chanceThreeLotteryName: raffle.chanceThreeLotteryName,
              chanceThreeRaffleName: raffleDetail.chanceThreeRaffleName,
            });
          } else if (raffle.chanceThreeLotteryMaxDigitsByBet === 3) {
            filteredChanceRaffleDetailMaxBet3.push({
              ...raffleDetail,
              chanceThreeLotteryId: raffle.chanceThreeLotteryId,
              chanceThreeLotteryName: raffle.chanceThreeLotteryName,
              chanceThreeRaffleName: raffleDetail.chanceThreeRaffleName,
            });
          }
        }
      }),
    );

    return {
      maxBet3: filteredChanceRaffleDetailMaxBet3,
      maxBet2: filteredChanceRaffleDetailMaxBet2,
    };
  }
  return { maxBet3: [], maxBet2: [] };
};

export const filterRaffleSelected = (raffles: Chance3DigitsRaffle[]): Chance3DigitsRaffle[] =>
  raffles.filter((raffle) => raffle.chanceThreeRaffleSelected);

export const chanceSwap = (str: string): string[] => {
  let list: string[] = [];
  if (str.length <= 2) {
    list = str.length === 2 ? [str[0] + str[1], str[1] + str[0]] : [str];
    return list.filter((item, index) => list.indexOf(item) === index);
  } else {
    list = str
      .split('')
      .reduce(
        (accumulator: string[], letter, i) =>
          accumulator.concat(
            chanceSwap(str.slice(0, i) + str.slice(i + 1)).map((val: string) => letter + val),
          ),
        [],
      );
  }
  return list.filter((item, index) => list.indexOf(item) === index);
};

export const tripleSeriesCombination = (value: string): string[] => {
  return Array.from({ length: 10 }, (_, index) => index + value);
};

export const seriesCompletation = (start: number, end: number): string[] => {
  return Array.from({ length: end - start + 1 }, (_, index) => String(index + start));
};

export const groupChanceDigitsByLottery = (
  raffles: Chance3DigitsRaffle[],
  numbers: string[],
  betAmount: number,
): ITicketChance3Digits[] => {
  const groupByLottery = groupBy(raffles, 'chanceThreeLotteryId');
  return Object.keys(groupByLottery).map((key) => {
    const lotteryId = Number(key);
    const lotteryName = groupByLottery[key][0].chanceThreeLotteryName;
    const totalBet = betAmount * numbers.length;
    const raffleChance3DigitsBet: ITicketChance3DigitsDetail[] = groupByLottery[key].map(
      (raffle) => {
        const raffleId = raffle.chanceThreeRaffleId;
        const raffleName = raffle.chanceThreeRaffleName;
        const totalBet = betAmount * numbers.length;
        const chance3DigitsBet = numbers.map((number) => {
          return {
            betNumber: number,
            betAmount: betAmount,
          };
        });
        return {
          raffleId,
          raffleName,
          totalBet,
          chance3DigitsBet,
        };
      },
    );
    return {
      lotteryId,
      lotteryName,
      totalBet,
      raffleChance3DigitsBet,
    };
  });
};

export const mergeCurrentChanceTicket = (
  currentChanceTicketInfo: ITicketChance3Digits[],
  newChanceTicketInfo: ITicketChance3Digits[],
): ITicketChance3Digits[] => {
  if (currentChanceTicketInfo.length === 0) {
    return newChanceTicketInfo;
  }
  const currentTicketInfo = cloneDeep(currentChanceTicketInfo);
  const newTicketInfo = cloneDeep(newChanceTicketInfo);

  if (checkIfLotteryIsInTicket(currentTicketInfo, newTicketInfo)) {
    newTicketInfo.forEach((newTicket) => {
      const index = currentTicketInfo.findIndex(
        (currentTicket) => currentTicket.lotteryId === newTicket.lotteryId,
      );
      if (index !== -1) {
        newTicket.raffleChance3DigitsBet.forEach((newRaffle) => {
          const raffleIndex = currentTicketInfo[index].raffleChance3DigitsBet.findIndex(
            (currentRaffle) => currentRaffle.raffleId === newRaffle.raffleId,
          );
          if (raffleIndex !== -1) {
            currentTicketInfo[index].raffleChance3DigitsBet[raffleIndex].chance3DigitsBet = uniqBy(
              newRaffle.chance3DigitsBet.concat(
                currentTicketInfo[index].raffleChance3DigitsBet[raffleIndex].chance3DigitsBet,
              ),
              'betNumber',
            );
            currentTicketInfo[index].raffleChance3DigitsBet[raffleIndex].totalBet =
              currentTicketInfo[index].raffleChance3DigitsBet[raffleIndex].chance3DigitsBet.reduce(
                (accumulator, currentValue) => accumulator + Number(currentValue.betAmount),
                0,
              );
          } else {
            currentTicketInfo[index].raffleChance3DigitsBet.push(newRaffle);
          }
        });
      } else {
        currentTicketInfo.push(newTicket);
      }
    });
  } else {
    return currentTicketInfo.concat(newTicketInfo);
  }
  return currentTicketInfo;
};

export const checkIfLotteryIsInTicket = (
  currentChanceTicketInfo: ITicketChance3Digits[],
  newChanceTicketInfo: ITicketChance3Digits[],
) => {
  const result = currentChanceTicketInfo.map((a) => a.lotteryId);
  const result2 = newChanceTicketInfo.map((a) => a.lotteryId);
  return result.some((val) => result2.includes(val));
};

export const deleteLotteryFromTicket = (
  chance3BetTicketInfo: ITicketChance3Digits[],
  lotteryId: number,
) => chance3BetTicketInfo.filter((lottery) => lottery.lotteryId !== lotteryId);

export const deleteRaffleFromTicket = (
  chance3BetTicketInfo: ITicketChance3Digits[],
  lotteryId: number,
  raffleId: number,
) => {
  const newChanceBetTicketInfo = chance3BetTicketInfo.map((lottery) => {
    if (lottery.lotteryId === lotteryId) {
      return {
        ...lottery,
        raffleChance3DigitsBet: lottery.raffleChance3DigitsBet.filter(
          (raffle) => raffle.raffleId !== raffleId,
        ),
      };
    }
    return lottery;
  });
  return newChanceBetTicketInfo.filter((lottery) => lottery.raffleChance3DigitsBet.length > 0);
};

export const onAddNumberToTicket = (
  chance3BetTicketInfo: ITicketChance3Digits[],
  chance3RaffleMaxBet3: Chance3DigitsRaffle[],
  chance3RaffleMaxBet2: Chance3DigitsRaffle[],
  numbers: string[],
  betAmount: number,
) => {
  const filteredMaxBet3 = filterRaffleSelected(chance3RaffleMaxBet3);
  const filteredMaxBet2 = filterRaffleSelected(chance3RaffleMaxBet2);
  if (filteredMaxBet3.length > 0) {
    const ticketInfo = groupChanceDigitsByLottery(filteredMaxBet3, numbers, betAmount);
    return mergeCurrentChanceTicket(chance3BetTicketInfo, ticketInfo);
  }
  if (filteredMaxBet2.length > 0) {
    const ticketInfo = groupChanceDigitsByLottery(filteredMaxBet2, numbers, betAmount);
    return mergeCurrentChanceTicket(chance3BetTicketInfo, ticketInfo);
  }
};

export const createBetFromCurrentChanceBetTicket = (
  chance3BetTicketInfo: ITicketChance3Digits[],
): IBet[] => {
  const bets: IBet[] = [];
  chance3BetTicketInfo.forEach((lottery) => {
    lottery.raffleChance3DigitsBet.forEach((raffle) => {
      raffle.chance3DigitsBet.forEach((number) => {
        bets.push({
          lotteryId: lottery.lotteryId,
          betValue: number.betNumber,
          betTotal: Number(number.betAmount),
          gameTypeId: CHANCE_3_DIGITS_RAFFLES_ID,
          raffleId: raffle.raffleId,
          raffleName: raffle.raffleName,
        });
      });
    });
  });
  return bets;
};

export const buildChance3DigitsBetWithErrors = (
  currentChance3DigitsTicketInfo: ITicketChance3Digits[],
  errorBetList: ErrorValidateBet[],
): ITicketChance3Digits[] => {
  return currentChance3DigitsTicketInfo.map((lottery) => {
    const newRaffleChance3DigitsBet = lottery.raffleChance3DigitsBet.map((raffle) => {
      return {
        ...raffle,
        chance3DigitsBet: evaluateChanceDigitBetHasError(raffle.chance3DigitsBet, errorBetList),
      };
    });
    return {
      ...lottery,
      raffleChance3DigitsBet: newRaffleChance3DigitsBet,
    };
  });
};

export const evaluateRaffleHasError = (
  raffleDetail: ITicketChance3DigitsDetail,
  errorBetList: ErrorValidateBet[],
): ITicketChance3DigitsDetail => {
  let foundAError = false;
  let finalIteration = false;
  let iterator = 0,
    internalListIterator = 0;
  while (!foundAError && !finalIteration) {
    errorBetList.forEach((errorBet, index) => {
      errorBet.errorList.forEach((errorDetail, indexList) => {
        if (
          errorDetail.raffleId === raffleDetail.raffleId &&
          errorDetail.gameTypeId === CHANCE_3_DIGITS_RAFFLES_ID
        ) {
          foundAError = true;
          iterator = index;
          internalListIterator = indexList;
        }
      });
    });
    finalIteration = true;
  }

  if (foundAError) {
    return {
      ...raffleDetail,
      error: errorBetList[iterator].errorList[internalListIterator].error,
      hasError: true,
    };
  }
  return raffleDetail;
};

export const evaluateChanceDigitBetHasError = (
  chance3DigitBet: Chance3TicketBetArray[],
  errorBetList: ErrorValidateBet[],
): Chance3TicketBetArray[] => {
  return chance3DigitBet.map((chance) => {
    let foundAError = false;
    let finalIteration = false;
    let iterator = 0,
      internalListIterator = 0;
    while (!foundAError && !finalIteration) {
      errorBetList.forEach((errorBet, index) => {
        errorBet.errorList.forEach((errorDetail, indexList) => {
          if (
            errorDetail.betValue === chance.betNumber &&
            errorDetail.gameTypeId === CHANCE_3_DIGITS_RAFFLES_ID
          ) {
            foundAError = true;
            iterator = index;
            internalListIterator = indexList;
          }
        });
      });
      finalIteration = true;
    }

    if (foundAError) {
      return {
        ...chance,
        error: errorBetList[iterator].errorList[internalListIterator].error,
        hasError: true,
      };
    }
    return chance;
  });
};

export const processBuiltChance3DigitsTicketWithError = (
  chance3DigitsWithError: ITicketChance3Digits[],
) => {
  const errorsFound: string[] = [];
  const raffleWithoutError: ITicketChance3Digits[] = [];

  chance3DigitsWithError.forEach((lottery) => {
    const raffleWithError: ITicketChance3DigitsDetail[] = [];
    lottery.raffleChance3DigitsBet.forEach((raffle) => {
      if (raffle.hasError && raffle.error) {
        errorsFound.push(raffle.error);
      } else {
        const raffleAux = cloneDeep(raffle);
        raffleAux.chance3DigitsBet = [];
        raffle.chance3DigitsBet.forEach((chance) => {
          if (chance.hasError && chance.error) {
            errorsFound.push(chance.error);
          } else {
            raffleAux.chance3DigitsBet.push(chance);
          }
        });
        if (raffleAux.chance3DigitsBet.length > 0) {
          raffleWithError.push(raffleAux);
        }
      }
    });
    if (raffleWithError.length > 0) {
      raffleWithoutError.push({
        ...lottery,
        raffleChance3DigitsBet: raffleWithError,
      });
    }
  });
  return {
    errorsFound,
    raffleWithoutError,
  };
};

export const createChance3PrintTicketData = (ticketData: ITicketChance3Digits[]): string => {
  let ticket = '';
  ticketData.forEach((lottery) => {
    lottery.raffleChance3DigitsBet.forEach((raffle) => {
      ticket += `${raffle.raffleName}: `;
      raffle.chance3DigitsBet.forEach((chance) => {
        ticket += `${chance.betNumber} $${chance.betAmount}, `;
      });
      ticket = ticket.trim().slice(0, -1);
      ticket += lineBreak;
    });
    ticket += lineBreak;
  });
  return ticket;
};
