import { CHANCE_4_DIGITS_RAFFLES_ID } from '@constants/app.constants';
import { ErrorValidateBet, IBet } from '@models/Bet.models';
import {
  Chance4DigitsLottery,
  Chance4DigitsRaffle,
  Chance4TicketBetArray,
  ITicketChance4Digits,
  ITicketChance4DigitsDetail,
} from '@models/Chance4Digits.models';
import { cloneDeep, groupBy, uniqBy } from 'lodash';
import { Chance3TicketBetArray } from '@models/Chance3Digits.models';
import { DateTime } from 'luxon';
import { lineBreak } from '@constants/printer.constants';

export const isRaffleActive = (Chance4DigitsRaffle: Chance4DigitsRaffle): boolean => {
  const now = DateTime.local();
  const raffleEndDate = DateTime.fromISO(Chance4DigitsRaffle.chanceFourRaffleBetEndDate);
  return raffleEndDate > now;
};

export const filterRaffleSelected = (raffles: Chance4DigitsRaffle[]): Chance4DigitsRaffle[] =>
  raffles.filter((raffle) => raffle.chanceFourRaffleSelected);
export const filterChance4RaffleByMaxBet = (
  chance4DigitsLotteries: Chance4DigitsLottery[],
): {
  maxBet4: Chance4DigitsRaffle[];
} => {
  if (chance4DigitsLotteries) {
    const filteredChanceRaffleDetailMaxBet4: Chance4DigitsRaffle[] = [];
    chance4DigitsLotteries.forEach((raffle) =>
      raffle.chanceFourRaffleDetail.forEach((raffleDetail) => {
        if (isRaffleActive(raffleDetail)) {
          if (raffle.chanceFourLotteryMaxDigitsByBet === 4) {
            filteredChanceRaffleDetailMaxBet4.push({
              ...raffleDetail,
              chanceFourLotteryId: raffle.chanceFourLotteryId,
              chanceFourLotteryName: raffle.chanceFourLotteryName,
              chanceFourRaffleName: raffleDetail.chanceFourRaffleName,
            });
          }
        }
      }),
    );

    return {
      maxBet4: filteredChanceRaffleDetailMaxBet4,
    };
  }
  return { maxBet4: [] };
};

export const tripleSeriesCombination = (value: string): string[] => {
  return Array.from({ length: 10 }, (_, index) => index + value);
};

export const groupChanceDigitsByLottery = (
  raffles: Chance4DigitsRaffle[],
  numbers: string[],
  betAmount: number,
): ITicketChance4Digits[] => {
  const groupByLottery = groupBy(raffles, 'chanceFourLotteryId');
  return Object.keys(groupByLottery).map((key) => {
    const lotteryId = Number(key);
    const lotteryName = groupByLottery[key][0].chanceFourLotteryName;
    const totalBet = betAmount * numbers.length;
    const raffleChance4DigitsBet: ITicketChance4DigitsDetail[] = groupByLottery[key].map(
      (raffle) => {
        const raffleId = raffle.chanceFourRaffleId;
        const raffleName = raffle.chanceFourRaffleName;
        const totalBet = betAmount * numbers.length;
        const chance4DigitsBet = numbers.map((number) => {
          return {
            betNumber: number,
            betAmount: betAmount,
          };
        });
        return {
          raffleId,
          raffleName,
          totalBet,
          chance4DigitsBet,
        };
      },
    );
    return {
      lotteryId,
      lotteryName,
      totalBet,
      raffleChance4DigitsBet,
    };
  });
};

export const mergeCurrentChanceTicket = (
  currentChanceTicketInfo: ITicketChance4Digits[],
  newChanceTicketInfo: ITicketChance4Digits[],
): ITicketChance4Digits[] => {
  if (currentChanceTicketInfo.length === 0) {
    return newChanceTicketInfo;
  }
  const currentTicketInfo = cloneDeep(currentChanceTicketInfo);
  const newTicketInfo = cloneDeep(newChanceTicketInfo);

  if (checkIfLotteryIsInTicket(currentTicketInfo, newTicketInfo)) {
    newTicketInfo.forEach((newTicket) => {
      const index = currentTicketInfo.findIndex(
        (currentTicket) => currentTicket.lotteryId === newTicket.lotteryId,
      );
      if (index !== -1) {
        newTicket.raffleChance4DigitsBet.forEach((newRaffle) => {
          const raffleIndex = currentTicketInfo[index].raffleChance4DigitsBet.findIndex(
            (currentRaffle) => currentRaffle.raffleId === newRaffle.raffleId,
          );
          if (raffleIndex !== -1) {
            currentTicketInfo[index].raffleChance4DigitsBet[raffleIndex].chance4DigitsBet = uniqBy(
              newRaffle.chance4DigitsBet.concat(
                currentTicketInfo[index].raffleChance4DigitsBet[raffleIndex].chance4DigitsBet,
              ),
              'betNumber',
            );
            currentTicketInfo[index].raffleChance4DigitsBet[raffleIndex].totalBet =
              currentTicketInfo[index].raffleChance4DigitsBet[raffleIndex].chance4DigitsBet.reduce(
                (accumulator, currentValue) => accumulator + Number(currentValue.betAmount),
                0,
              );
          } else {
            currentTicketInfo[index].raffleChance4DigitsBet.push(newRaffle);
          }
        });
      } else {
        currentTicketInfo.push(newTicket);
      }
    });
  } else {
    return currentTicketInfo.concat(newTicketInfo);
  }
  return currentTicketInfo;
};

export const checkIfLotteryIsInTicket = (
  currentChanceTicketInfo: ITicketChance4Digits[],
  newChanceTicketInfo: ITicketChance4Digits[],
) => {
  const result = currentChanceTicketInfo.map((a) => a.lotteryId);
  const result2 = newChanceTicketInfo.map((a) => a.lotteryId);
  return result.some((val) => result2.includes(val));
};

export const deleteLotteryFromTicket = (
  chance3BetTicketInfo: ITicketChance4Digits[],
  lotteryId: number,
) => chance3BetTicketInfo.filter((lottery) => lottery.lotteryId !== lotteryId);

export const deleteRaffleFromTicket = (
  chance3BetTicketInfo: ITicketChance4Digits[],
  lotteryId: number,
  raffleId: number,
) => {
  const newChanceBetTicketInfo = chance3BetTicketInfo.map((lottery) => {
    if (lottery.lotteryId === lotteryId) {
      return {
        ...lottery,
        raffleChance4DigitsBet: lottery.raffleChance4DigitsBet.filter(
          (raffle) => raffle.raffleId !== raffleId,
        ),
      };
    }
    return lottery;
  });
  return newChanceBetTicketInfo.filter((lottery) => lottery.raffleChance4DigitsBet.length > 0);
};

export const onAddNumberToTicket = (
  chance4BetTicketInfo: ITicketChance4Digits[],
  chance4RaffleMaxBet4: Chance4DigitsRaffle[],
  chance4RaffleMaxBet3: Chance4DigitsRaffle[],
  chance4RaffleMaxBet2: Chance4DigitsRaffle[],
  numbers: string[],
  betAmount: number,
) => {
  const filteredMaxBet4 = filterRaffleSelected(chance4RaffleMaxBet4);
  const filteredMaxBet3 = filterRaffleSelected(chance4RaffleMaxBet3);
  const filteredMaxBet2 = filterRaffleSelected(chance4RaffleMaxBet2);
  if (filteredMaxBet4.length > 0) {
    const ticketInfo = groupChanceDigitsByLottery(filteredMaxBet4, numbers, betAmount);
    return mergeCurrentChanceTicket(chance4BetTicketInfo, ticketInfo);
  }
  if (filteredMaxBet3.length > 0) {
    const ticketInfo = groupChanceDigitsByLottery(filteredMaxBet3, numbers, betAmount);
    return mergeCurrentChanceTicket(chance4BetTicketInfo, ticketInfo);
  }
  if (filteredMaxBet2.length > 0) {
    const ticketInfo = groupChanceDigitsByLottery(filteredMaxBet2, numbers, betAmount);
    return mergeCurrentChanceTicket(chance4BetTicketInfo, ticketInfo);
  }
};

export const buildChance4DigitsBetWithErrors = (
  currentChance3DigitsTicketInfo: ITicketChance4Digits[],
  errorBetList: ErrorValidateBet[],
): ITicketChance4Digits[] => {
  return currentChance3DigitsTicketInfo.map((lottery) => {
    const newRaffleChance4DigitsBet = lottery.raffleChance4DigitsBet.map((raffle) => {
      return {
        ...raffle,
        chance4DigitsBet: evaluateChanceDigitBetHasError(raffle.chance4DigitsBet, errorBetList),
      };
    });
    return {
      ...lottery,
      raffleChance4DigitsBet: newRaffleChance4DigitsBet,
    };
  });
};

export const createBetFromCurrentChance4BetTicket = (
  chance4BetTicketInfo: ITicketChance4Digits[],
): IBet[] => {
  const bets: IBet[] = [];
  chance4BetTicketInfo.forEach((lottery) => {
    lottery.raffleChance4DigitsBet.forEach((raffle) => {
      raffle.chance4DigitsBet.forEach((number) => {
        bets.push({
          lotteryId: lottery.lotteryId,
          betValue: number.betNumber,
          betTotal: Number(number.betAmount),
          gameTypeId: CHANCE_4_DIGITS_RAFFLES_ID,
          raffleId: raffle.raffleId,
          raffleName: raffle.raffleName,
        });
      });
    });
  });
  return bets;
};

export const evaluateChanceDigitBetHasError = (
  chance4DigitBet: Chance4TicketBetArray[],
  errorBetList: ErrorValidateBet[],
): Chance3TicketBetArray[] => {
  return chance4DigitBet.map((chance) => {
    let foundAError = false;
    let finalIteration = false;
    let iterator = 0,
      internalListIterator = 0;
    while (!foundAError && !finalIteration) {
      errorBetList.forEach((errorBet, index) => {
        errorBet.errorList.forEach((errorDetail, indexList) => {
          if (
            errorDetail.betValue === chance.betNumber &&
            errorDetail.gameTypeId === CHANCE_4_DIGITS_RAFFLES_ID
          ) {
            foundAError = true;
            iterator = index;
            internalListIterator = indexList;
          }
        });
      });
      finalIteration = true;
    }

    if (foundAError) {
      return {
        ...chance,
        error: errorBetList[iterator].errorList[internalListIterator].error,
        hasError: true,
      };
    }
    return chance;
  });
};

export const evaluateRaffleHasError = (
  raffleDetail: ITicketChance4DigitsDetail,
  errorBetList: ErrorValidateBet[],
): ITicketChance4DigitsDetail => {
  let foundAError = false;
  let finalIteration = false;
  let iterator = 0,
    internalListIterator = 0;
  while (!foundAError && !finalIteration) {
    errorBetList.forEach((errorBet, index) => {
      errorBet.errorList.forEach((errorDetail, indexList) => {
        if (
          errorDetail.raffleId === raffleDetail.raffleId &&
          errorDetail.gameTypeId === CHANCE_4_DIGITS_RAFFLES_ID
        ) {
          foundAError = true;
          iterator = index;
          internalListIterator = indexList;
        }
      });
    });
    finalIteration = true;
  }

  if (foundAError) {
    return {
      ...raffleDetail,
      error: errorBetList[iterator].errorList[internalListIterator].error,
      hasError: true,
    };
  }
  return raffleDetail;
};

export const processBuiltChance4DigitsTicketWithError = (
  chance4DigitsWithError: ITicketChance4Digits[],
) => {
  const errorsFound: string[] = [];
  const raffleWithoutError: ITicketChance4Digits[] = [];

  chance4DigitsWithError.forEach((lottery) => {
    const raffleWithError: ITicketChance4DigitsDetail[] = [];
    lottery.raffleChance4DigitsBet.forEach((raffle) => {
      if (raffle.hasError && raffle.error) {
        errorsFound.push(raffle.error);
      } else {
        const raffleAux = cloneDeep(raffle);
        raffleAux.chance4DigitsBet = [];
        raffle.chance4DigitsBet.forEach((chance) => {
          if (chance.hasError && chance.error) {
            errorsFound.push(chance.error);
          } else {
            raffleAux.chance4DigitsBet.push(chance);
          }
        });
        if (raffleAux.chance4DigitsBet.length > 0) {
          raffleWithError.push(raffleAux);
        }
      }
    });
    if (raffleWithError.length > 0) {
      raffleWithoutError.push({
        ...lottery,
        raffleChance4DigitsBet: raffleWithError,
      });
    }
  });
  return {
    errorsFound,
    raffleWithoutError,
  };
};

export const createChance4DigitsPrintTicket = (ticketData: ITicketChance4Digits[]): string => {
  let ticket = '';
  ticketData.forEach((lottery) => {
    lottery.raffleChance4DigitsBet.forEach((raffle) => {
      ticket += `${raffle.raffleName}: `;
      raffle.chance4DigitsBet.forEach((chance) => {
        ticket += `${chance.betNumber} $${chance.betAmount}`;
      });
      ticket += lineBreak;
    });
    ticket += lineBreak;
  });
  return ticket;
};
