import React from 'react';
import Grid from '@mui/material/Grid';

import AnimalCard from '@components/AnimalList/components/AnimalCard/AnimalCard';
import { IAnimalDetail } from '@models/Animal.models';

interface AnimalListProps {
  animals: IAnimalDetail[];
  selectedAnimals: IAnimalDetail[];
  selectAnimalAction: (animal: IAnimalDetail) => void;
  canSelectANewAnimal: boolean;
}

function AnimalList({
  animals,
  selectedAnimals,
  selectAnimalAction,
  canSelectANewAnimal,
}: AnimalListProps) {
  return (
    <Grid container spacing={1}>
      {animals
        // .filter((animal) => !animal.animalIsFruit)
        .map((animal) => (
          <Grid item xs={3} md={1.3} key={`${animal.animalId}-${animal.animalUserId}`}>
            <AnimalCard
              animal={animal}
              selectedAnimals={selectedAnimals}
              selectAnimalAction={selectAnimalAction}
              canSelectANewAnimal={canSelectANewAnimal}
            />
          </Grid>
        ))}
    </Grid>
  );
}

export default AnimalList;
