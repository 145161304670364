import { ChanceZodiacRaffle } from '@models/ChanceZodiac.models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ChanceZodiacRaffleState {
  chanceZodiacRaffles: ChanceZodiacRaffle[];
  chanceZodiacRaffleMaxBet3: ChanceZodiacRaffle[];
  chanceZodiacRaffleMaxBet2: ChanceZodiacRaffle[];
  selectedChanceZodiacRaffleMaxBet3: ChanceZodiacRaffle[];
  selectedChanceZodiacRaffleMaxBet2: ChanceZodiacRaffle[];
  isChanceZodiacRaffleSelected: boolean;
}

const initialState: ChanceZodiacRaffleState = {
  chanceZodiacRaffles: [],
  chanceZodiacRaffleMaxBet3: [],
  chanceZodiacRaffleMaxBet2: [],
  selectedChanceZodiacRaffleMaxBet3: [],
  selectedChanceZodiacRaffleMaxBet2: [],
  isChanceZodiacRaffleSelected: false,
};

export const chanceZodiacRaffleSlice = createSlice({
  name: 'chanceZodiacRaffles',
  initialState,
  reducers: {
    actionSetChanceZodiacRaffles: (state, action: PayloadAction<ChanceZodiacRaffle[]>) => {
      state.chanceZodiacRaffles = action.payload;
    },
    actionSetChanceZodiacRaffleMaxBet3: (state, action: PayloadAction<ChanceZodiacRaffle[]>) => {
      state.chanceZodiacRaffleMaxBet3 = action.payload;
    },
    actionSetChanceZodiacRaffleMaxBet2: (state, action: PayloadAction<ChanceZodiacRaffle[]>) => {
      state.chanceZodiacRaffleMaxBet2 = action.payload;
    },
    actionSetChanceZodiacMaxBet: (
      state,
      action: PayloadAction<{ maxBet3?: ChanceZodiacRaffle[]; maxBet2?: ChanceZodiacRaffle[] }>,
    ) => {
      state.chanceZodiacRaffleMaxBet3 = action.payload.maxBet3 ?? [];
      state.chanceZodiacRaffleMaxBet2 = action.payload.maxBet2 ?? [];
    },
    actionCleanChanceZodiacRaffleState: (state) => {
      state.chanceZodiacRaffleMaxBet3 = [];
      state.chanceZodiacRaffleMaxBet2 = [];
    },
    actionSetChanceZodiacRaffleSelected: (state, action: PayloadAction<boolean>) => {
      state.isChanceZodiacRaffleSelected = action.payload;
    },
    actionSetSelectedChanceZodiacRaffleMaxBet3: (
      state,
      action: PayloadAction<ChanceZodiacRaffle[]>,
    ) => {
      state.selectedChanceZodiacRaffleMaxBet3 = action.payload;
    },
    actionSetSelectedChanceZodiacRaffleMaxBet2: (
      state,
      action: PayloadAction<ChanceZodiacRaffle[]>,
    ) => {
      state.selectedChanceZodiacRaffleMaxBet2 = action.payload;
    },
  },
});

export const {
  actionSetChanceZodiacRaffles,
  actionSetChanceZodiacMaxBet,
  actionSetChanceZodiacRaffleMaxBet3,
  actionSetChanceZodiacRaffleMaxBet2,
  actionCleanChanceZodiacRaffleState,
  actionSetChanceZodiacRaffleSelected,
  actionSetSelectedChanceZodiacRaffleMaxBet3,
  actionSetSelectedChanceZodiacRaffleMaxBet2,
} = chanceZodiacRaffleSlice.actions;

export const chanceZodiacRaffleReducer = chanceZodiacRaffleSlice.reducer;
