import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import styles from './MenuItemApp.module.scss';
import { blueGrey, grey } from '@mui/material/colors';
import { useAppSelector } from '@app/hooks';
import { appThemes } from '@constants/app.constants';

function MenuItemApp({
  label,
  link,
  isActive,
  onClick,
}: {
  label: string;
  link: string;
  isActive: boolean;
  onClick?: () => void;
}) {
  const selectedCurrencyID = useAppSelector((state) => state.currency.selectedCurrencyId);

  const buttonStyle = {
    color: grey[50],
    height: '100%',
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: appThemes[selectedCurrencyID - 1].palette.primary.dark,
      color: grey[50],
    },
  };

  const buttonStyleActive = {
    backgroundColor: grey[50],
    color: blueGrey[900],
  };

  const buttonStyleFinal = isActive ? { ...buttonStyle, ...buttonStyleActive } : buttonStyle;
  return (
    <Link className={styles.MenuItemApp} to={link}>
      <Button sx={buttonStyleFinal} color='inherit' onClick={onClick}>
        {label}
      </Button>
    </Link>
  );
}

export default MenuItemApp;
