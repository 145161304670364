import React from 'react';

// @models
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Currency } from '@models/currency.models';
import { ConditionalRendering } from '@components/ConditionalRendering/ConditionalRendering';
import { MenuItem, Select } from '@mui/material';

interface LotteryCurrencyListProps {
  currencySettingsList: Currency[];
  selectedCurrentSetting: number;
  onSelectCurrency: (currency: number) => void;
  hideTitle?: boolean;
}

function CurrencyList({
  currencySettingsList,
  selectedCurrentSetting,
  onSelectCurrency,
  hideTitle = false,
}: LotteryCurrencyListProps) {
  const renderCurrency = () => {
    return currencySettingsList.map((currencySetting) => (
      <MenuItem
        key={`currency-${currencySetting.name}-${currencySetting.code}`}
        value={currencySetting.id}
      >
        {currencySetting.name}
      </MenuItem>
    ));
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          padding: '6px',
          justifyContent: 'center',
        }}
      >
        <ConditionalRendering isTrue={!hideTitle}>
          <Typography sx={{ paddingLeft: 2 }} variant='body1'>
            Elige la moneda
          </Typography>
        </ConditionalRendering>

        <Select
          labelId='select-game-currency'
          id='select-game-currency'
          value={selectedCurrentSetting}
          label=''
          fullWidth={hideTitle}
          onChange={(event) => onSelectCurrency(Number(event.target.value))}
          variant='outlined'
          sx={{
            backgroundColor: 'white',
          }}
        >
          {renderCurrency()}
        </Select>
      </Box>
    </Box>
  );
}

export default CurrencyList;
