import httpCommon from '@config/http-common';
import { ICustomer, ICustomerResponse } from '@models/Customer.models';
import { AxiosError, AxiosResponse } from 'axios';

export const getCustomerByTypeDocumentNumber = async (
  documentType: string | void,
  documentNumber: string | void,
) => {
  try {
    const response: AxiosResponse<ICustomerResponse<ICustomer[]>> = await httpCommon.get(
      `Customer/get-customer-by-type-and-number-document/documenttype${
        documentType ? '/' + documentType : ''
      } ${documentNumber ? '/documentnumber/' + documentNumber : ''}`.replace(/\s/g, ''),
    );
    return response?.data || [];
  } catch (error) {
    if (error instanceof AxiosError) {
      return error.response?.data?.message || 'unknown error';
    }
    return 'unknown error';
  }
};

export const createCustomer = async (data: ICustomer) => {
  try {
    const response: AxiosResponse<ICustomerResponse<string>> = await httpCommon.post(
      'Customer/add-customer',
      data,
    );
    return response?.data || [];
  } catch (error) {
    throw (error as AxiosError).response?.data;
  }
};

export const updateCustomer = async (
  documentType: string | void,
  documentNumber: string | void,
  data: ICustomer,
) => {
  try {
    const response: AxiosResponse<ICustomerResponse<string>> = await httpCommon.put(
      `Customer/update-customer/documenttype${documentType ? '/' + documentType : ''} ${
        documentNumber ? '/documentnumber/' + documentNumber : ''
      }`.replace(/\s/g, ''),
      data,
    );
    return response?.data || [];
  } catch (error) {
    throw (error as AxiosError).response?.data;
  }
};
