import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRaffleDetail } from '@models/Raffle.models';

interface RaffleSliceState {
  selectedRaffles: IRaffleDetail[];
  activeRaffles: IRaffleDetail[];
}

const initialState: RaffleSliceState = {
  selectedRaffles: [],
  activeRaffles: [],
};

export const rafflesSlice = createSlice({
  name: 'raffles',
  initialState,
  reducers: {
    actionCleanRafflesState: (state) => {
      return {
        ...initialState,
        activeRaffles: state.activeRaffles,
      };
    },
    actionSelectRaffle: (state, action: PayloadAction<IRaffleDetail[]>) => {
      state.selectedRaffles = action.payload;
    },
    actionSetActiveRaffles: (state, action: PayloadAction<IRaffleDetail[]>) => {
      state.activeRaffles = action.payload;
    },
  },
});

export const { actionSelectRaffle, actionSetActiveRaffles, actionCleanRafflesState } =
  rafflesSlice.actions;

export const raffleReducer = rafflesSlice.reducer;
