import React from 'react';
import { Card, CardActionArea, CardMedia, Paper, SvgIcon } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import styles from './FruitCard.module.scss';
import { blue, grey } from '@mui/material/colors';
import { IAnimalDetail } from '@models/Animal.models';

interface FruitCardProps {
  animal: IAnimalDetail;
  selectedAnimals: IAnimalDetail[];
  selectAnimalAction: (animal: IAnimalDetail) => void;
  canSelectANewAnimal: boolean;
  animalId: string | undefined;
  fruitId: string | undefined;
  selectedAnimalBet: IAnimalDetail[];
}

function FruitCard({
  animal,
  selectedAnimals,
  selectAnimalAction,
  animalId,
  selectedAnimalBet,
}: FruitCardProps) {
  const index = animal.animalIsFruit ? `${animalId}-${animal.animalId}` : undefined;
  const isSelected = selectedAnimals.some(
    (selectedAnimal) => selectedAnimal.animalId === animal.animalId,
  );

  const disabledCard = selectedAnimalBet.some(
    (selectedAnimal) => selectedAnimal.animalId === index,
  );

  const disabledCardStyle = {
    opacity: disabledCard ? 0.5 : 1,
    cursor: disabledCard ? 'not-allowed' : 'pointer',
    backgroundColor: grey[200],
  };

  return (
    <Paper className={styles.FruitCard} tabIndex={-1}>
      {isSelected && (
        <div className={styles.isSelected}>
          <SvgIcon sx={{ color: blue[800] }} component={CheckCircleIcon} />
        </div>
      )}
      <Card sx={disabledCard ? disabledCardStyle : undefined}>
        <CardActionArea disabled={disabledCard} onClick={() => selectAnimalAction(animal)}>
          <CardMedia
            sx={{ objectFit: 'contain' }}
            component='img'
            height='60'
            image={`${process.env.REACT_APP_PUBLIC_URL}/animals/${animal.animalImageUrl}`}
            alt={animal.animalName}
          />
        </CardActionArea>
      </Card>
    </Paper>
  );
}

export default FruitCard;
