import { useAppDispatch, useAppSelector } from '@app/hooks';
import { actionSelectAnimalTypeGame } from '@features/AnimalsGame/AnimalTypeGame/AnimalTypeGameSlice';
import { actionSetSelectedLotterySettings } from '@features/Currency/Currency.slice';
import { useEffect } from 'react';
import { batch } from 'react-redux';
import { actionCleanRafflesState } from '@features/AnimalsGame/Raffles/RaffleSlice';
import { actionCleanAnimalsState } from '@features/AnimalsGame/Animals/AnimalsSlice';
import { actionCleanAnimalsBetState } from '@features/AnimalsGame/AnimalsBet/AnimalsBetSlice';
import { IAnimalsActiveByLottery } from '@models/AnimalsLottery.models';
import { ANIMAL_TRIPLETA_GAME_ID } from '@constants/app.constants';
import { actionSetLotteryFruitCombined } from '../Lottery/LotterySlice';
import { selectedAnimalTypeGameSelector } from '@selectors/animalTypeGame.selector';
import { animalsLotterySelector } from '@selectors/animalsLottery.selector';
import { selectedCurrencyIdSelector } from '@selectors/animalcurrency.selector';

export const useAnimalTypeGame = () => {
  const dispatch = useAppDispatch();

  const selectedAnimalTypeGame = useAppSelector(selectedAnimalTypeGameSelector);
  const activeAnimalsByLottery = useAppSelector(animalsLotterySelector);
  const selectedCurrencyId = useAppSelector(selectedCurrencyIdSelector);

  const getSettingsFromAnimalTypeGame = (selectedAnimalTypeGame: number) => {
    let activeRafflesFiltered: IAnimalsActiveByLottery | undefined;

    if (selectedAnimalTypeGame !== ANIMAL_TRIPLETA_GAME_ID) {
      activeRafflesFiltered = activeAnimalsByLottery.find(
        (lottery) => lottery.animalitosLotteryGameType === selectedAnimalTypeGame,
      );
    } else {
      activeRafflesFiltered = activeAnimalsByLottery.find((lottery) =>
        lottery.animalitosLotterySettings.find(
          (raffleSetting) =>
            raffleSetting.hasTripleta && raffleSetting.currencyId === selectedCurrencyId,
        ),
      );

      activeRafflesFiltered = {
        ...(activeRafflesFiltered as IAnimalsActiveByLottery),
        animalitosLotterySettings: (
          activeRafflesFiltered as IAnimalsActiveByLottery
        )?.animalitosLotterySettings.map((setting) => ({
          ...setting,
          maxAnimalsByTicket: 3,
        })),
      };
    }

    if (activeRafflesFiltered) {
      dispatch(
        actionSetSelectedLotterySettings(
          activeRafflesFiltered?.animalitosLotterySettings.find(
            (lotterySetting) => lotterySetting.currencyId === selectedCurrencyId,
          ),
        ),
      );
    }
  };

  useEffect(() => {
    getSettingsFromAnimalTypeGame(selectedAnimalTypeGame);
  }, [selectedAnimalTypeGame, selectedCurrencyId]);

  const onHandleSelectAnimalTypeGame = (selectedGame: number) => {
    if (selectedGame === 2) {
      dispatch(actionSetLotteryFruitCombined(true));
    } else {
      dispatch(actionSetLotteryFruitCombined(false));
    }
    dispatch(actionSelectAnimalTypeGame(selectedGame));

    batch(() => {
      dispatch(actionCleanRafflesState());
      dispatch(actionCleanAnimalsState());
      dispatch(actionCleanAnimalsBetState());
    });
  };

  return {
    selectedAnimalTypeGame,
    onHandleSelectAnimalTypeGame,
  };
};
