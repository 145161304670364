import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ConditionalRendering } from '@components/ConditionalRendering/ConditionalRendering';
import InputNumber from '@components/InputNumber/InputNumber';
import { Chance3TicketBetArray } from '@models/Chance3Digits.models';

interface AnimalTicketInfoProps {
  chanceBet: Chance3TicketBetArray[];
  isEditable: boolean;
  updateChanceBetAmountAction: (chanceBetIndex: number, amount: string) => void;
  formatCurrency: (amount: number) => string;
}

function ChanceBetTicketInfo({
  chanceBet,
  isEditable,
  updateChanceBetAmountAction,
  formatCurrency,
}: AnimalTicketInfoProps) {
  const renderAnimalBet = chanceBet.map((chance, chanceBetIndex) => (
    <Box
      key={`animal-chance-bet-${chance.betNumber}`}
      sx={{ display: 'flex', alignItems: chance.hasError ? 'center' : 'flex-end' }}
    >
      <Typography sx={{ marginRight: 2, paddingLeft: 4, width: '30%' }} component='span'>
        {chance.betNumber}
      </Typography>
      <ConditionalRendering isTrue={isEditable}>
        <InputNumber
          isAmount={true}
          sx={{ width: '50%' }}
          error={chance.hasError}
          helperText={chance.hasError ? String(chance.error) : ''}
          variant='standard'
          value={chance.betAmount}
          onChange={(value) => updateChanceBetAmountAction(chanceBetIndex, value)}
        />
      </ConditionalRendering>
      <ConditionalRendering isTrue={!isEditable}>
        <Typography component='span'>{formatCurrency(Number(chance.betAmount))}</Typography>
      </ConditionalRendering>
    </Box>
  ));

  return <>{renderAnimalBet}</>;
}

export default ChanceBetTicketInfo;
