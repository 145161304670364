import { AxiosError, AxiosResponse } from 'axios';
import httpCommon from '@config/http-common';
import { IAnimalResponse } from '@models/AnimalsLottery.models';
import { ITicket } from '@models/Ticket.models';

export const getCancellableTickets = async () => {
  try {
    const response: AxiosResponse<IAnimalResponse<ITicket[]>> = await httpCommon.get(
      'TicketCancellation/get-cancellable-tickets',
    );
    return response?.data?.response || [];
  } catch (error) {
    if (error instanceof AxiosError) {
      return error.response?.data?.message || 'unknown error';
    }
    return 'unknown error';
  }
};
