import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ICustomer, ICustomerResponse } from '@models/Customer.models';
import {
  createCustomer,
  getCustomerByTypeDocumentNumber,
  updateCustomer,
} from '@controllers/Customer/Customer.api';

export interface CustomerFormState {
  customer?: ICustomer;
  openDialog: boolean;
  error: string;
  isLoading: boolean;
  status: 'success' | 'failed' | 'idle' | 'pending';
  requestType: 'idle' | 'get' | 'create' | 'update';
}

export const initialState: CustomerFormState = {
  customer: {} as ICustomer,
  openDialog: false,
  error: '',
  isLoading: false,
  status: 'idle',
  requestType: 'idle',
};

export const getCustomerAsync = createAsyncThunk(
  'customer/get-customer-by-type-and-number-document',
  async ({ documentType, documentNumber }: { documentType: string; documentNumber: string }) => {
    const response = await getCustomerByTypeDocumentNumber(documentType, documentNumber);
    return response as ICustomerResponse<ICustomer>;
  },
);

export const createCustomerAsync = createAsyncThunk(
  'customer/add-customer',
  async (customerData: ICustomer) => {
    const response = await createCustomer(customerData);
    return response as ICustomerResponse<string>;
  },
);

export const updateCustomerAsync = createAsyncThunk(
  'customer/update-customer',
  async ({
    documentType,
    documentNumber,
    customerData,
  }: {
    documentType: string;
    documentNumber: string;
    customerData: ICustomer;
  }) => {
    const response = await updateCustomer(documentType, documentNumber, customerData);
    return response as ICustomerResponse<string>;
  },
);

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    actionSetCustomer: (state, action) => {
      state.customer = action.payload.response;
    },
    actionCleanCustomer: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCustomerAsync.pending, (state) => {
        state.isLoading = true;
        state.status = 'pending';
        state.error = '';
        state.requestType = 'get';
      })
      .addCase(getCustomerAsync.fulfilled, (state, action) => {
        state.customer = action.payload.response;
        state.isLoading = false;
        state.status = action.payload.success ? 'success' : 'failed';
        state.requestType = 'get';
        if (action.payload.success) {
          state.error = '';
        } else {
          state.error = action.payload.message;
        }
      })
      .addCase(getCustomerAsync.rejected, (state) => {
        state.isLoading = false;
        state.status = 'failed';
        state.error = '';
        state.requestType = 'get';
      })
      .addCase(createCustomerAsync.pending, (state) => {
        state.isLoading = true;
        state.status = 'pending';
        state.error = '';
        state.requestType = 'create';
      })
      .addCase(createCustomerAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = action.payload.success ? 'success' : 'failed';
        state.requestType = 'create';
        if (action.payload.success) {
          state.error = '';
        } else {
          state.error = action.payload.message;
        }
      })
      .addCase(createCustomerAsync.rejected, (state) => {
        state.isLoading = false;
        state.status = 'failed';
        state.error = '';
        state.requestType = 'create';
      })
      .addCase(updateCustomerAsync.pending, (state) => {
        state.isLoading = true;
        state.status = 'pending';
        state.error = '';
        state.requestType = 'update';
      })
      .addCase(updateCustomerAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = action.payload.success ? 'success' : 'failed';
        state.requestType = 'update';
        if (action.payload.success) {
          state.error = '';
        } else {
          state.error = action.payload.message;
        }
      })
      .addCase(updateCustomerAsync.rejected, (state) => {
        state.isLoading = false;
        state.status = 'failed';
        state.error = '';
        state.requestType = 'update';
      });
  },
});

export const { actionCleanCustomer, actionSetCustomer } = customerSlice.actions;

export const customerReducer = customerSlice.reducer;
