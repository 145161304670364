import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useState } from 'react';

import {
  actionBetToTicket,
  actionSetAnimalBetTicketInfo,
  actionSetBet,
} from '@features/Ticket/TicketSlice';
import { ITicketAnimal } from '@models/Ticket.models';
import { buildBetFromTicket } from '@helpers/bet.helpers';
import { amountIsExceedingMaxValue, amountIsNotValid } from '@helpers/animalBet.helpers';
import { IBet } from '@models/Bet.models';
import { ANIMAL_RAFFLES_ID, ANIMAL_TRIPLETA_GAME_ID } from '@constants/app.constants';
import { raffleSettingsSelector } from '@selectors/animalcurrency.selector';
import { animalBetTicketInfoSelector, ticketBetsSelector } from '@selectors/ticket.selector';

export const useAnimalTicket = () => {
  const dispatch = useAppDispatch();

  const animalBetTicketInfo = useAppSelector(animalBetTicketInfoSelector);
  const ticketBets = useAppSelector(ticketBetsSelector);
  const selectedLotterySetting = useAppSelector(raffleSettingsSelector);

  const [formHasErrors, setFormHasErrors] = useState(false);
  const [isEditingForm, setIsEditingForm] = useState(false);

  const deleteRaffleOnTicketBet = (lotteryId: number, raffleId: number) => {
    const newTicketBets: IBet[] = [];

    ticketBets.forEach((bet) => {
      if (bet.gameTypeId === ANIMAL_RAFFLES_ID || bet.gameTypeId === ANIMAL_TRIPLETA_GAME_ID) {
        if (bet.lotteryId === lotteryId) {
          if (bet.raffleId !== raffleId) {
            newTicketBets.push(bet);
          }
        } else {
          newTicketBets.push(bet);
        }
      } else {
        newTicketBets.push(bet);
      }
    });
    dispatch(actionSetBet(newTicketBets));
  };

  const deleteLotteryBet = (lotteryId: number) => {
    const lotteryTicketAnimal = animalBetTicketInfo.filter(
      (animalTicket) => animalTicket.lotteryId !== lotteryId,
    );
    const newTicketBets: IBet[] = [];

    ticketBets.forEach((bet) => {
      if (bet.gameTypeId === ANIMAL_RAFFLES_ID) {
        if (bet.lotteryId !== lotteryId) {
          newTicketBets.push(bet);
        }
      } else {
        newTicketBets.push(bet);
      }
    });

    dispatch(actionSetAnimalBetTicketInfo(lotteryTicketAnimal));
    dispatch(actionSetBet(newTicketBets));
  };

  const deleteRaffleBet = (lotteryId: number, raffleId: number) => {
    const newAnimalBetTicketInfo = animalBetTicketInfo.map((lottery) => {
      if (lottery.lotteryId === lotteryId) {
        const filterRaffles = lottery.rafflesAnimalBet.filter(
          (raffle) => raffle.raffleId !== raffleId,
        );
        return {
          ...lottery,
          rafflesAnimalBet: filterRaffles,
        };
      }
      return lottery;
    });

    const nonEmptyLotteryRaffle = newAnimalBetTicketInfo.filter(
      (lottery) => lottery.rafflesAnimalBet.length > 0,
    );

    dispatch(actionSetAnimalBetTicketInfo(nonEmptyLotteryRaffle));
    deleteRaffleOnTicketBet(lotteryId, raffleId);
  };

  const setEditableRaffle = (indexLottery: number, index: number, finishEdit?: boolean) => {
    const lotteryObject = animalBetTicketInfo[indexLottery];
    const raffleObject = {
      ...lotteryObject.rafflesAnimalBet[index],
      isEditing: !finishEdit,
    };
    setIsEditingForm(!!finishEdit);

    const newAnimalBetTicketInfo = animalBetTicketInfo.map((lottery, i) => {
      if (i === indexLottery) {
        return {
          ...lottery,
          rafflesAnimalBet: lottery.rafflesAnimalBet.map((raffle, j) => {
            if (j === index) {
              return raffleObject;
            }
            return raffle;
          }),
        };
      }
      return lottery;
    });

    dispatch(actionSetAnimalBetTicketInfo(newAnimalBetTicketInfo));
    if (finishEdit) {
      dispatch(
        actionBetToTicket(
          buildBetFromTicket(selectedLotterySetting.currencyId, newAnimalBetTicketInfo),
        ),
      );
    }
  };

  const updateAnimalBetAmount = (
    indexLottery: number,
    indexRaffle: number,
    indexAnimal: number,
    amount: string,
  ) => {
    let hasErrorForm = false;
    const newAnimalBetTicketInfo: ITicketAnimal[] = JSON.parse(JSON.stringify(animalBetTicketInfo));

    const exceedMaxAmount = amountIsExceedingMaxValue(
      selectedLotterySetting.maxBetByAnimal,
      Number(amount),
    );
    const isAmountNotValid = amountIsNotValid(amount);

    let errorMessage = '';
    if (exceedMaxAmount) {
      hasErrorForm = true;
      errorMessage = `Monto máximo es de ${selectedLotterySetting.maxBetByAnimal}`;
    } else if (Number(amount) <= 0) {
      hasErrorForm = true;
      errorMessage = 'Monto debe ser mayor a 0';
    } else if (isAmountNotValid) {
      hasErrorForm = true;
      errorMessage = 'El número no es válido';
    }

    newAnimalBetTicketInfo[indexLottery].rafflesAnimalBet[indexRaffle].animalsBet[indexAnimal] = {
      ...newAnimalBetTicketInfo[indexLottery].rafflesAnimalBet[indexRaffle].animalsBet[indexAnimal],
      betAmount: amount,
      error: errorMessage,
      hasError: hasErrorForm,
    };

    const newTotalBet = newAnimalBetTicketInfo[indexLottery].rafflesAnimalBet[
      indexRaffle
    ].animalsBet.reduce((acum, animal) => {
      if (animal.betAmount) {
        const betAmount = Number(animal.betAmount);
        return acum + betAmount;
      }
      return acum;
    }, 0);

    if (hasErrorForm) {
      setFormHasErrors(true);
    } else {
      setFormHasErrors(false);
    }

    newAnimalBetTicketInfo[indexLottery].rafflesAnimalBet[indexRaffle].totalBet = newTotalBet;

    dispatch(actionSetAnimalBetTicketInfo(newAnimalBetTicketInfo as ITicketAnimal[]));
  };

  return {
    updateAnimalBetAmount,
    deleteLotteryBet,
    deleteRaffleBet,
    setEditableRaffle,
    animalBetTicketInfo,
    formHasErrors,
    isEditingForm,
  };
};
