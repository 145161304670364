import * as React from 'react';
import List from '@mui/material/List';

import RaffleItem from '@components/RaffleList/components/RaffleItem';

import { IRaffleDetail } from '@models/Raffle.models';

interface RaffleListProps {
  raffles: IRaffleDetail[];
  selectedRaffles: IRaffleDetail[];
  onClickRaffle: (raffle: number) => void;
}

export default function RaffleList({ raffles, onClickRaffle, selectedRaffles }: RaffleListProps) {
  const isRaffleSelected = (raffleId: number) => {
    return selectedRaffles.find((raffle) => raffle.animalitosRaffleId === raffleId);
  };

  const selectedRaffleHasError = selectedRaffles.filter((raffle) => raffle.animalitosRaffleError);
  const raffleHasError = (raffleId: number) =>
    selectedRaffleHasError.find((raffle) => raffle.animalitosRaffleId === raffleId);
  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', paddingLeft: 0 }}
      tabIndex={-1}
    >
      {raffles.map((raffle) => {
        return (
          <RaffleItem
            key={`active-raffle-item-${raffle.animalitosRaffleId}`}
            onClickRaffle={onClickRaffle}
            raffle={raffle}
            isRaffleSelected={!!isRaffleSelected(raffle.animalitosRaffleId)}
            raffleHasError={raffleHasError(raffle.animalitosRaffleId)}
          />
        );
      })}
    </List>
  );
}
