import React from 'react';
import Button from '@mui/material/Button';
import { SxProps, Theme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

interface SubmitButtonProps {
  className?: string;
  variant: 'text' | 'contained' | 'outlined' | undefined;
  onClick: () => void;
  sx?: SxProps<Theme>;
  disabled: boolean;
  isLoading: boolean;
  buttonText?: string;
}
function SubmitButton({
  sx,
  className,
  onClick,
  disabled,
  isLoading,
  buttonText = 'Apostar',
}: SubmitButtonProps) {
  return (
    <Button
      className={className}
      disabled={disabled || isLoading}
      variant='contained'
      sx={sx}
      onClick={onClick}
    >
      {isLoading && <CircularProgress size={24} />}
      {!isLoading && buttonText}
    </Button>
  );
}

export default SubmitButton;
