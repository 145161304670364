import { useEffect, useState } from 'react';
import { duplicateTicketAsync } from './TicketDuplicate.api';
import { IBet, IBetTicket } from '@models/Bet.models';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectedCurrencyIdSelector } from '@selectors/animalcurrency.selector';
import {
  animalBetTicketInfoSelector,
  salePointIdSelector,
  ticketBetsSelector,
} from '@selectors/ticket.selector';
import { validateBetAsync } from '@features/AnimalsGame/AnimalsBet/AnimalsBetSlice';
import {
  buildBetHelperFromAnimalRaffleBet,
  buildRaffleAnimalBetWithError,
  getOnlyBetsFromAnimalGame,
  mergeAnimalBets,
} from '@helpers/bet.helpers';
import {
  betErrorsAnimalBetSelector,
  isAlReadyFetchedAnimalBetSelector,
} from '@selectors/animalsBet.selector';
import { actionBetToTicket, actionSetAnimalBetTicketInfo } from '@features/Ticket/TicketSlice';
import { batch } from 'react-redux';
import { actionSelectRaffle } from '@features/AnimalsGame/Raffles/RaffleSlice';
import { actionSetDisabledChangeCurrency } from '@features/Currency/Currency.slice';
import { selectedActiveLotterySelector } from '@selectors/lottery.selector';
import { IAnimalBet } from '@models/Animal.models';
import {
  convertBetsToTicketAnimals,
  mergeAnimalBetTicketInfo,
} from '@helpers/ticketDuplicate.helpers';
import { animalsLotterySelector } from '@selectors/animalsLottery.selector';
import { enqueueSnackbar } from 'notistack';
import { IRaffleDetail } from '@models/Raffle.models';

export const useTicketDuplicateModal = () => {
  const dispatch = useAppDispatch();
  const [duplicateTicketModalShow, setDuplicateTicketModalShow] = useState(false);
  const [ticketNumber, setTicketNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [betDataState, setBetDataState] = useState({} as IBetTicket);
  const [selectedRaffles, setSelectedRaffles] = useState([] as IRaffleDetail[]);
  const [selectedAnimalBet, setSelectedAnimalBet] = useState([] as IAnimalBet[]);
  const [duplicateDataState, setDuplicateDataState] = useState([] as IBet[]);

  const selectedCurrencyId = useAppSelector(selectedCurrencyIdSelector);
  const selectedSalePointId = useAppSelector(salePointIdSelector);
  const ticketBets = useAppSelector(ticketBetsSelector);
  const isAlReadyFetched = useAppSelector(isAlReadyFetchedAnimalBetSelector);
  const betErrors = useAppSelector(betErrorsAnimalBetSelector);
  const selectedActiveLottery = useAppSelector(selectedActiveLotterySelector);
  const animalBetTicketInfo = useAppSelector(animalBetTicketInfoSelector);
  const activeAnimalsByLottery = useAppSelector(animalsLotterySelector);

  useEffect(() => {
    dispatch(actionSelectRaffle([]));
  }, [duplicateTicketModalShow]);

  const duplicateTicket = async () => {
    setIsLoading(true);
    try {
      const duplicatedTicketData = await duplicateTicketAsync(ticketNumber);
      if (duplicatedTicketData.success) {
        setDuplicateDataState(duplicatedTicketData.response.bets);
        const betData: IBetTicket = {
          bets: duplicatedTicketData.response.bets,
          currencyId: selectedCurrencyId,
          salePointId: selectedSalePointId,
        };

        betData.bets = mergeAnimalBets(ticketBets, betData.bets);
        setBetDataState(betData);
        dispatch(validateBetAsync(getOnlyBetsFromAnimalGame(betData)));
      } else if (duplicatedTicketData?.errors[0]?.errorList[0]?.description) {
        enqueueSnackbar(duplicatedTicketData.errors[0].errorList[0].description, {
          variant: 'error',
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const cleanAnimalRaffleStates = () => {
    batch(() => {
      dispatch(actionSetDisabledChangeCurrency(true));
      setDuplicateTicketModalShow(false);
      setTicketNumber('');
      setSelectedAnimalBet([]);
      setSelectedRaffles([]);
      setBetDataState({} as IBetTicket);
      setDuplicateDataState([]);
    });
  };

  const buildBetToTicket = (betData: IBetTicket) => {
    const animalTicketInfo = convertBetsToTicketAnimals(duplicateDataState, activeAnimalsByLottery);
    const mergedAnimalBetTicketInfo = mergeAnimalBetTicketInfo(
      animalBetTicketInfo,
      animalTicketInfo,
    );

    batch(() => {
      dispatch(actionBetToTicket(betData));
      dispatch(actionSetAnimalBetTicketInfo(mergedAnimalBetTicketInfo));
    });

    enqueueSnackbar('Tiquete duplicado correctamente', {
      variant: 'success',
    });
  };

  useEffect(() => {
    if (betErrors.length > 0 && isAlReadyFetched) {
      const animalBetErrors = buildRaffleAnimalBetWithError(
        selectedRaffles,
        selectedAnimalBet,
        betErrors,
      );
      const betHasError = animalBetErrors.some((animal) => animal.hasError);
      if (betHasError) {
        const animalsWithOutError = animalBetErrors.filter(
          (animal) =>
            !animal.hasError ||
            (animal.hasError && animal.betValueFixed && animal.betValueFixed > 0),
        );
        if (animalsWithOutError.length > 0) {
          const betData = buildBetHelperFromAnimalRaffleBet(
            selectedActiveLottery.animalitosLotteryId,
            selectedCurrencyId,
            animalsWithOutError,
          );
          buildBetToTicket(betData);
          cleanAnimalRaffleStates();
        }
      }
    }

    if (betErrors.length === 0 && isAlReadyFetched && betDataState.bets?.length > 0) {
      buildBetToTicket(betDataState);
      cleanAnimalRaffleStates();
    }
  }, [betErrors, isAlReadyFetched]);

  return {
    duplicateTicketModalShow,
    setDuplicateTicketModalShow,
    ticketNumber,
    setTicketNumber,
    isLoading,
    duplicateTicket,
  };
};
