import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useChance3DigitsTicket } from '@hooks/chance3DigitsTicket';
import { useTicket } from '@features/Ticket/Ticket.hook';
import SubmitButton from '@components/SubmitButton/SubmitButton';
import ChanceTicketInfo from '@components/ChanceTicketInfo/ChanceTicketInfo';

import AnimalTicketInfo from '@components/AnimalTicketInfo/AnimalTicketInfo';
import { ConditionalRendering } from '@components/ConditionalRendering/ConditionalRendering';

// @hooks
import { useAnimalTicket } from '@hooks/animalTicket.hook';

import styles from './Ticket.module.scss';
import { useChance4DigitsTicket } from '@hooks/chance4DigitsTicket';
import Chance4TicketInfo from '@components/Chance4TicketInfo/Chance4TicketInfo';
import { useChanceZodiacTicket } from '@hooks/chanceZodiacTicket';
import ChanceZodiacTicketInfo from '@components/ChanceZodiacTicketInfo/ChanceZodiacTicketInfo';
import TicketDuplicateModal from '@features/TicketDuplicateModal/TicketDuplicateModal';

function Ticket() {
  const { formatCurrency, isLoading, totalBet, onCreateTicket, formattedTotalBet } = useTicket();
  const animalTicketHook = useAnimalTicket();
  const chanceTicketInfoHook = useChance3DigitsTicket();
  const chance4TicketInfoHook = useChance4DigitsTicket();
  const chanceZodiacTicketInfoHook = useChanceZodiacTicket();
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 1 }}>
        <TicketDuplicateModal />
        <Typography sx={{ flexGrow: 1, textAlign: 'right' }} component='p' variant='h5'>
          <strong>Total Apuesta: </strong>
          {formattedTotalBet}
        </Typography>
      </Box>
      <Box className={styles.Ticket}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }} className={styles.TicketContent}>
          <Typography sx={{ marginBottom: 2, padding: 1 }} component='p' variant='h5'>
            <strong>Resumen Apuesta</strong>
          </Typography>
          <Box sx={{ flex: 1, overflow: 'auto', paddingBottom: 2 }}>
            <AnimalTicketInfo
              rafflesAnimalBet={animalTicketHook.animalBetTicketInfo}
              deleteLotteryBet={animalTicketHook.deleteLotteryBet}
              deleteRaffleBet={animalTicketHook.deleteRaffleBet}
              setEditableRaffle={animalTicketHook.setEditableRaffle}
              updateAnimalBetAmount={animalTicketHook.updateAnimalBetAmount}
              formHasErrors={animalTicketHook.formHasErrors}
              formatCurrency={formatCurrency}
              isPrintingTicket={isLoading}
            />
            <ChanceTicketInfo
              chanceTicketInfo={chanceTicketInfoHook.chance3BetTicketInfo}
              deleteLottery={chanceTicketInfoHook.deleteLottery}
              deleteRaffle={chanceTicketInfoHook.deleteLotteryRaffle}
              setEditableRaffle={chanceTicketInfoHook.setEditableRaffle}
              updateChanceBetAmount={chanceTicketInfoHook.updateChanceBetAmount}
              formHasErrors={chanceTicketInfoHook.formHasErrors}
              formatCurrency={formatCurrency}
              isPrintingTicket={isLoading}
            />
            <Chance4TicketInfo
              chanceTicketInfo={chance4TicketInfoHook.chance4BetTicketInfo}
              deleteLottery={chance4TicketInfoHook.deleteLottery}
              deleteRaffle={chance4TicketInfoHook.deleteLotteryRaffle}
              setEditableRaffle={chance4TicketInfoHook.setEditableRaffle}
              updateChanceBetAmount={chance4TicketInfoHook.updateChanceBetAmount}
              formHasErrors={chance4TicketInfoHook.formHasErrors}
              formatCurrency={formatCurrency}
              isPrintingTicket={isLoading}
            />
            <ChanceZodiacTicketInfo
              chanceTicketInfo={chanceZodiacTicketInfoHook.chanceZodiacBetTicketInfo}
              deleteLottery={chanceZodiacTicketInfoHook.deleteLottery}
              deleteRaffle={chanceZodiacTicketInfoHook.deleteLotteryRaffle}
              setEditableRaffle={chanceZodiacTicketInfoHook.setEditableRaffle}
              updateChanceBetAmount={chanceZodiacTicketInfoHook.updateChanceBetAmount}
              formHasErrors={chanceZodiacTicketInfoHook.formHasErrors}
              formatCurrency={formatCurrency}
              isPrintingTicket={isLoading}
            />
          </Box>
          <ConditionalRendering isTrue={totalBet > 0}>
            <SubmitButton
              isLoading={isLoading}
              disabled={isLoading}
              buttonText='Guardar e Imprimir Apuesta'
              variant='contained'
              onClick={onCreateTicket}
            />
          </ConditionalRendering>
        </Box>
      </Box>
    </>
  );
}

export default React.memo(Ticket);
