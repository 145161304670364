import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const animalsSelector = createSelector(
  (state: RootState) => state.animals.animals,
  (animals) => {
    return animals;
  },
);

export const selectedAnimalsSelector = createSelector(
  (state: RootState) => state.animals.selectedAnimals,
  (selectedAnimals) => {
    return selectedAnimals;
  },
);
