import React, { useMemo, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { grey } from '@mui/material/colors';
import styles from './AdministrationMenu.module.scss';
import { ADMIN_LINKS } from '@components/Toolbar/components/MenuApp/links';
import { buttonStyle, buttonStyleActive } from '../MenuItemApp/MenuItemAppStyles';

interface AdministrationMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  isActive: boolean;
  onClose: () => void;
  checkLinkIsActive: (link: string) => boolean;
}

const AdministrationMenu = ({
  anchorEl,
  open,
  onClick,
  isActive,
  onClose,
  checkLinkIsActive,
}: AdministrationMenuProps) => {
  const buttonStyleFinal = isActive ? { ...buttonStyle, ...buttonStyleActive } : buttonStyle;
  const menuItemStyle = {
    color: grey[50],
    fontWeight: 'bold',
    textDecoration: 'none',
  };

  const linkRef = useRef<HTMLAnchorElement>(null); // Define linkRef here

  const activeLink = ADMIN_LINKS.find((link) => checkLinkIsActive(link.path));

  const buildAdminItems = ADMIN_LINKS.map((menuLink, index) => (
    <MenuItem
      className={clsx(styles.MenuList, {
        [styles.Selected]: checkLinkIsActive(menuLink.path),
      })}
      sx={{ height: '70px' }}
      onClick={onClose}
      key={`menu-admin-${menuLink.label}-${index}`}
    >
      <Link
        ref={checkLinkIsActive(menuLink.path) ? linkRef : undefined}
        className={clsx(styles.Link)}
        to={menuLink.path}
      >
        <Typography
          className={clsx({
            [styles.LinkSelected]: checkLinkIsActive(menuLink.path),
          })}
          sx={menuItemStyle}
          component='span'
        >
          {menuLink.label}
        </Typography>
      </Link>
    </MenuItem>
  ));

  useEffect(() => {
    // Simulate a click event on the active link after everything has been loaded
    if (activeLink) {
      const activeLinkElement = document.querySelector(
        `a[href='${activeLink.path}']`,
      ) as HTMLAnchorElement | null;
      if (activeLinkElement) {
        activeLinkElement.click();
      }
    }
  }, [activeLink]);

  return (
    <div>
      <Button
        sx={buttonStyleFinal}
        variant='text'
        id='demo-positioned-button'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={onClick}
      >
        Administración
      </Button>
      <Menu
        className={styles.AdministrationMenu}
        classes={{
          paper: styles.MenuPaper,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        {buildAdminItems}
      </Menu>
    </div>
  );
};

export default AdministrationMenu;
