import { Chance3DigitsLottery } from '@models/Chance3Digits.models';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getActiveAnimalByRafflesCurrentDate } from '@controllers/Chance3Digits/Chance3Digits.api';

interface Chance3DigitsState {
  chance3DigitsLotteries: Chance3DigitsLottery[];
  isLoading: boolean;
  status: 'idle' | 'failed' | 'success';
}

const initialState: Chance3DigitsState = {
  chance3DigitsLotteries: [],
  isLoading: false,
  status: 'idle',
};

export const getChance3DigitsLotteriesAsync = createAsyncThunk(
  'chance3Digits/getChance3DigitsLotteriesAsync',
  async (filter: number | void) => {
    const response = await getActiveAnimalByRafflesCurrentDate(filter);
    return response as Chance3DigitsLottery[];
  },
);

export const chance3DigitsSlice = createSlice({
  name: 'chance3Digits',
  initialState,
  reducers: {
    actionSetChance3DigitsLotteries: (state, action) => {
      state.chance3DigitsLotteries = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChance3DigitsLotteriesAsync.pending, (state) => {
        state.chance3DigitsLotteries = [];
        state.isLoading = true;
      })
      .addCase(getChance3DigitsLotteriesAsync.fulfilled, (state, action) => {
        state.chance3DigitsLotteries = action.payload;
        state.isLoading = false;
        state.status = 'success';
      })
      .addCase(getChance3DigitsLotteriesAsync.rejected, (state) => {
        state.isLoading = false;
        state.status = 'failed';
        state.chance3DigitsLotteries = [];
      });
  },
});

export const { actionSetChance3DigitsLotteries } = chance3DigitsSlice.actions;

export const chance3DigitsReducer = chance3DigitsSlice.reducer;
