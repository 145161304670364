import React, { useEffect } from 'react';
import { isLoggedIn } from '../helpers/localstorage.helper';
import { useNavigate } from 'react-router-dom';
import ButtonAppBar from '@components/Toolbar/Toolbar';
import { useCheckSessionStatus } from '@hooks/session.hook';

type PrivateRouteProps = {
  children: JSX.Element;
};

function PrivateRoute({ children }: PrivateRouteProps) {
  const navigate = useNavigate();
  useCheckSessionStatus();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate('/login');
    } else {
      if (window.location.pathname === '/login') {
        navigate('/');
      }
    }
  }, []);

  return (
    <>
      <ButtonAppBar />
      {children}
    </>
  );
}

export default PrivateRoute;
