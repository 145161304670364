import React from 'react';
import Grid from '@mui/material/Grid';

import { IAnimalDetail } from '@models/Animal.models';
import grey from '@mui/material/colors/grey';
import FruitCard from './components/AnimalCard/FruitCard';

interface FruitListProps {
  animals: IAnimalDetail[];
  selectedAnimals: IAnimalDetail[];
  selectAnimalAction: (animal: IAnimalDetail) => void;
  canSelectANewAnimal: boolean;
  disableAnimalsCard: boolean;
  disableFruitsCard: boolean;
  selectedLotteryFruitCombined: boolean | undefined;
  animalId: string | undefined;
  fruitId: string | undefined;
  selectedAnimalBet: IAnimalDetail[];
}

function FruitList({
  animals,
  selectedAnimals,
  selectAnimalAction,
  canSelectANewAnimal,
  disableAnimalsCard,
  disableFruitsCard,
  animalId,
  fruitId,
  selectedAnimalBet,
}: FruitListProps) {
  const disabledAnimalsCardStyle = {
    opacity: disableAnimalsCard ? 0.5 : 1,
    cursor: disableAnimalsCard ? 'not-allowed' : 'pointer',
    backgroundColor: grey[200],
    pointerEvents: 'none',
  };

  const disabledFruitsCardStyle = {
    opacity: disableFruitsCard ? 0.5 : 1,
    cursor: disableFruitsCard ? 'not-allowed' : 'pointer',
    backgroundColor: grey[200],
    pointerEvents: 'none',
  };
  return (
    <Grid container spacing={1}>
      {/* Left Column (Non-Fruit Animals) */}
      <Grid
        item
        xs={6}
        style={{ padding: '10px' }}
        sx={disableAnimalsCard ? disabledAnimalsCardStyle : undefined}
      >
        <Grid container spacing={1}>
          {animals
            .filter((animal) => !animal.animalIsFruit)
            .map((animal) => (
              <Grid item xs={3} md={3} key={`${animal.animalId}-${animal.animalUserId}`}>
                <FruitCard
                  animal={animal}
                  selectedAnimals={selectedAnimals}
                  selectAnimalAction={selectAnimalAction}
                  canSelectANewAnimal={canSelectANewAnimal}
                  animalId={animalId}
                  fruitId={fruitId}
                  selectedAnimalBet={selectedAnimalBet}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>

      {/* Right Column (Fruit Animals) */}
      <Grid
        item
        xs={6}
        style={{ padding: '10px' }}
        sx={disableFruitsCard ? disabledFruitsCardStyle : undefined}
      >
        <Grid container spacing={1}>
          {animals
            .filter((animal) => animal.animalIsFruit)
            .map((animal) => (
              <Grid item xs={2} md={3} key={`${animal.animalId}-${animal.animalUserId}`}>
                <FruitCard
                  animal={animal}
                  selectedAnimals={selectedAnimals}
                  selectAnimalAction={selectAnimalAction}
                  canSelectANewAnimal={canSelectANewAnimal}
                  animalId={animalId}
                  fruitId={fruitId}
                  selectedAnimalBet={selectedAnimalBet}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default FruitList;
