import React from 'react';
import { useLogin } from '@features/Login/Login.hook';
import CircularProgress from '@mui/material/CircularProgress';

function Login() {
  useLogin();
  return (
    <>
      <h1 className='login-form__title'>Por favor espere</h1>
      <h2>
        <CircularProgress size={54} />
      </h2>
    </>
  );
}

export default Login;
