import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useEffect, useRef, useState } from 'react';
import {
  actionSetChance4MaxBet,
  actionSetIsRaffleChance4Selected,
  actionSetSelectedChance4RaffleMaxBet4,
} from '@features/Chance4Digits/Chance4Raffles/Chance4Raffle.slice';
import { Chance4DigitsRaffle } from '@models/Chance4Digits.models';
import {
  actionSetActiveChance4DigitsSettings,
  actionSetSelectedChance4DigitsSetting,
} from '@features/Currency/Currency.slice';
import { filterChance4RaffleByMaxBet } from '@helpers/chance4digits.helpers';
import { ChanceSettings } from '@models/chance.models';
import { useHotkeys } from 'react-hotkeys-hook';
import { SEARCH_ANIMALS_SHORTCUT, SEARCH_RAFFLES_SHORTCUT } from '@constants/shortcuts.constants';
import { cloneDeep } from 'lodash';
import { CHANCE_4_CIFRAS_PATH } from '@constants/url.constants';
import { chance4DigitsLotteriesSelector } from '@selectors/chance4Digits.selector';
import {
  chance4RaffleMaxBet4Selector,
  selectedChance4RaffleMaxBet4Selector,
} from '@selectors/chance4Raffles.selector';
import { selectedCurrencyIdSelector } from '@selectors/animalcurrency.selector';

export const useChance4Raffles = () => {
  const dispatch = useAppDispatch();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const chance4DigitsLotteries = useAppSelector(chance4DigitsLotteriesSelector);
  const chance4RaffleMaxBet4 = useAppSelector(chance4RaffleMaxBet4Selector);
  const selectedCurrencyId = useAppSelector(selectedCurrencyIdSelector);
  const selectedChance4RaffleMaxBet4 = useAppSelector(selectedChance4RaffleMaxBet4Selector);

  const [searchInput, setSearchInput] = useState('');
  const [rafflesMax4Digits, setRafflesMax4Digits] =
    useState<Chance4DigitsRaffle[]>(chance4RaffleMaxBet4);

  useHotkeys(
    SEARCH_RAFFLES_SHORTCUT,
    () => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    },
    {
      enabled: window.location.pathname === CHANCE_4_CIFRAS_PATH,
    },
  );

  useEffect(() => {
    setRafflesMax4Digits(chance4RaffleMaxBet4);
  }, [chance4RaffleMaxBet4]);

  useHotkeys(SEARCH_ANIMALS_SHORTCUT, () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  });

  const setRaffleSettings = (isMaxBet4: boolean, settings4Digits: ChanceSettings[] | undefined) => {
    let raffleSettings;
    if (isMaxBet4 && settings4Digits) {
      raffleSettings = settings4Digits.find((setting) => setting.currencyId === selectedCurrencyId);
      dispatch(actionSetSelectedChance4DigitsSetting(raffleSettings));
    }
  };

  const setChance4DigitsLotteriesSettings = (lotteryId: number, isMaxBet4: boolean) => {
    const settings4Digits = chance4DigitsLotteries.find(
      (lottery) =>
        lottery.chanceFourLotteryId === lotteryId && lottery.chanceFourLotteryMaxDigitsByBet === 4,
    );
    dispatch(
      actionSetActiveChance4DigitsSettings({
        settings4Digits: settings4Digits?.chanceLotterySettings,
      }),
    );
    setRaffleSettings(isMaxBet4, settings4Digits?.chanceLotterySettings);
  };

  const searchForRaffles = () => {
    if (searchInput.length > 0) {
      const auxChance4RaffleMaxBet4 = cloneDeep(chance4RaffleMaxBet4);
      const filterRaffle4Digits = auxChance4RaffleMaxBet4.filter((raffle) =>
        raffle.chanceFourLotteryName.toLowerCase().includes(searchInput.toLowerCase()),
      );

      selectedChance4RaffleMaxBet4.forEach((raffle) => {
        const indexChange4Digits = filterRaffle4Digits.findIndex(
          (raffleOriginal) => raffleOriginal.chanceFourRaffleId === raffle.chanceFourRaffleId,
        );
        if (indexChange4Digits !== -1) {
          filterRaffle4Digits[indexChange4Digits].chanceFourRaffleSelected = true;
        }
      });

      setRafflesMax4Digits(filterRaffle4Digits);
    }

    if (searchInput.length === 0) {
      const auxChance4RaffleMaxBet4 = cloneDeep(chance4RaffleMaxBet4);
      selectedChance4RaffleMaxBet4.forEach((raffle) => {
        const indexChange3Digits = auxChance4RaffleMaxBet4.findIndex(
          (raffleOriginal) => raffleOriginal.chanceFourRaffleId === raffle.chanceFourRaffleId,
        );
        if (indexChange3Digits !== -1) {
          auxChance4RaffleMaxBet4[indexChange3Digits].chanceFourRaffleSelected = true;
        }
      });

      setRafflesMax4Digits(auxChance4RaffleMaxBet4);
    }
  };

  const checkIfRaffleIsSelected = (raffles: Chance4DigitsRaffle[], raffleId: number) => {
    const raffleSelected = raffles.find((raffle) => raffle.chanceFourRaffleSelected);
    let alreadySelectedRaffles = selectedChance4RaffleMaxBet4.filter(
      (raffle) => raffle.chanceFourRaffleId !== raffleId,
    );
    alreadySelectedRaffles = Array.from(
      new Set([
        ...selectedChance4RaffleMaxBet4.filter((raffle) => raffle.chanceFourRaffleId !== raffleId),
        ...raffles.filter((raffle) => raffle.chanceFourRaffleId),
      ]),
    );
    dispatch(actionSetSelectedChance4RaffleMaxBet4([...alreadySelectedRaffles]));

    if (raffleSelected || alreadySelectedRaffles.length > 0) {
      dispatch(actionSetIsRaffleChance4Selected(true));
    } else {
      dispatch(actionSetIsRaffleChance4Selected(false));
    }
  };

  const onSelectChanceRaffle = (raffleId: number, isMaxBet4: boolean) => {
    if (isMaxBet4) {
      let raffleSelected = false;
      let lotterySelectedId = 0;
      const updatedRaffles = rafflesMax4Digits.map((raffle) => {
        if (raffle.chanceFourRaffleId === raffleId) {
          lotterySelectedId = raffle.chanceFourLotteryId;
          if (!raffle.chanceFourRaffleSelected) {
            raffleSelected = true;
          }
          return { ...raffle, chanceFourRaffleSelected: !raffle.chanceFourRaffleSelected };
        }
        if (raffle.chanceFourRaffleSelected) {
          raffleSelected = true;
        }
        return raffle;
      });
      setRafflesMax4Digits(updatedRaffles);

      if (raffleSelected) {
        setChance4DigitsLotteriesSettings(lotterySelectedId, isMaxBet4);
      }
      checkIfRaffleIsSelected(updatedRaffles, raffleId);
    }
  };

  const setChanceMaxBetBy = () => {
    const raffles = filterChance4RaffleByMaxBet(chance4DigitsLotteries);
    dispatch(
      actionSetChance4MaxBet({
        maxBet4: raffles.maxBet4,
      }),
    );
  };

  useEffect(() => {
    setChanceMaxBetBy();
  }, [chance4DigitsLotteries]);

  return {
    searchForRaffles,
    setSearchInput,
    searchInputRef,
    onSelectChanceRaffle,
    chance4RaffleMaxBet4: rafflesMax4Digits,
    setChanceMaxBetBy,
    searchInput,
  };
};
