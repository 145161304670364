import {
  ChanceZodiacLottery,
  ChanceZodiacRaffle,
  ChanceZodiacTicketBetArray,
  ITicketChanceZodiac,
  ITicketChanceZodiacDetail,
} from '@models/ChanceZodiac.models';
import { cloneDeep, groupBy, uniqBy } from 'lodash';
import { filterRaffleZodiacSelected, isRaffleActive } from '@helpers/chanceZ.helpers';
import { ErrorValidateBet, IBet } from '@models/Bet.models';
import { CHANCE_ZODIAC_RAFFLES_ID } from '@constants/app.constants';
import { starSignList } from '@constants/star-signs.constants';
import { lineBreak } from '@constants/printer.constants';

export const groupChanceDigitsByLottery = (
  raffles: ChanceZodiacRaffle[],
  numbers: string[],
  betAmount: number,
  starSignId: number,
): ITicketChanceZodiac[] => {
  const groupByLottery = groupBy(raffles, 'chanceZodiacLotteryId');
  const starSign = starSignList.find((st) => st.starSignId === starSignId);
  return Object.keys(groupByLottery).map((key) => {
    const lotteryId = Number(key);
    const lotteryName = groupByLottery[key][0].chanceZodiacLotteryName;
    const totalBet = betAmount * numbers.length;
    const raffleChanceZodiacBet: ITicketChanceZodiacDetail[] = groupByLottery[key].map((raffle) => {
      const raffleId = raffle.chanceZodiacRaffleId;
      const raffleName = raffle.chanceZodiacRaffleName;
      const totalBet = betAmount * numbers.length;

      const ChanceZodiacBet = numbers.map((number) => {
        return {
          betNumber: number + '-' + starSignId,
          betAmount: betAmount,
          starSignId: starSignId,
          starSignName: starSign?.starSignName,
        };
      });
      return {
        raffleId,
        raffleName,
        totalBet,
        ChanceZodiacBet,
      };
    });
    return {
      lotteryId,
      lotteryName,
      totalBet,
      raffleChanceZodiacBet,
    };
  });
};

export const mergeCurrentChanceTicket = (
  currentChanceTicketInfo: ITicketChanceZodiac[],
  newChanceTicketInfo: ITicketChanceZodiac[],
): ITicketChanceZodiac[] => {
  if (currentChanceTicketInfo.length === 0) {
    return newChanceTicketInfo;
  }
  const currentTicketInfo = cloneDeep(currentChanceTicketInfo);
  const newTicketInfo = cloneDeep(newChanceTicketInfo);

  if (checkIfLotteryIsInTicket(currentTicketInfo, newTicketInfo)) {
    newTicketInfo.forEach((newTicket) => {
      const index = currentTicketInfo.findIndex(
        (currentTicket) => currentTicket.lotteryId === newTicket.lotteryId,
      );
      if (index !== -1) {
        newTicket.raffleChanceZodiacBet.forEach((newRaffle) => {
          const raffleIndex = currentTicketInfo[index].raffleChanceZodiacBet.findIndex(
            (currentRaffle) => currentRaffle.raffleId === newRaffle.raffleId,
          );
          if (raffleIndex !== -1) {
            currentTicketInfo[index].raffleChanceZodiacBet[raffleIndex].ChanceZodiacBet = uniqBy(
              newRaffle.ChanceZodiacBet.concat(
                currentTicketInfo[index].raffleChanceZodiacBet[raffleIndex].ChanceZodiacBet,
              ),
              'betNumber',
            );
            currentTicketInfo[index].raffleChanceZodiacBet[raffleIndex].totalBet =
              currentTicketInfo[index].raffleChanceZodiacBet[raffleIndex].ChanceZodiacBet.reduce(
                (accumulator, currentValue) => accumulator + Number(currentValue.betAmount),
                0,
              );
          } else {
            currentTicketInfo[index].raffleChanceZodiacBet.push(newRaffle);
          }
        });
      } else {
        currentTicketInfo.push(newTicket);
      }
    });
  } else {
    return currentTicketInfo.concat(newTicketInfo);
  }
  return currentTicketInfo;
};

export const checkIfLotteryIsInTicket = (
  currentChanceTicketInfo: ITicketChanceZodiac[],
  newChanceTicketInfo: ITicketChanceZodiac[],
) => {
  const result = currentChanceTicketInfo.map((a) => a.lotteryId);
  const result2 = newChanceTicketInfo.map((a) => a.lotteryId);
  return result.some((val) => result2.includes(val));
};

export const deleteLotteryFromTicket = (
  chanceZodiacBetTicketInfo: ITicketChanceZodiac[],
  lotteryId: number,
) => chanceZodiacBetTicketInfo.filter((lottery) => lottery.lotteryId !== lotteryId);

export const deleteRaffleFromTicket = (
  chanceZodiacBetTicketInfo: ITicketChanceZodiac[],
  lotteryId: number,
  raffleId: number,
) => {
  const newChanceBetTicketInfo = chanceZodiacBetTicketInfo.map((lottery) => {
    if (lottery.lotteryId === lotteryId) {
      return {
        ...lottery,
        raffleChanceZodiacBet: lottery.raffleChanceZodiacBet.filter(
          (raffle) => raffle.raffleId !== raffleId,
        ),
      };
    }
    return lottery;
  });
  return newChanceBetTicketInfo.filter((lottery) => lottery.raffleChanceZodiacBet.length > 0);
};

export const onAddNumberToTicket = (
  chanceZodiacBetTicketInfo: ITicketChanceZodiac[],
  chanceZodiacRaffleMaxBet3: ChanceZodiacRaffle[],
  chanceZodiacRaffleMaxBet2: ChanceZodiacRaffle[],
  numbers: string[],
  betAmount: number,
  starSignId: number,
) => {
  const filteredMaxBet3 = filterRaffleZodiacSelected(chanceZodiacRaffleMaxBet3);
  const filteredMaxBet2 = filterRaffleZodiacSelected(chanceZodiacRaffleMaxBet2);
  if (filteredMaxBet3.length > 0) {
    const ticketInfo = groupChanceDigitsByLottery(filteredMaxBet3, numbers, betAmount, starSignId);
    return mergeCurrentChanceTicket(chanceZodiacBetTicketInfo, ticketInfo);
  }
  if (filteredMaxBet2.length > 0) {
    const ticketInfo = groupChanceDigitsByLottery(filteredMaxBet2, numbers, betAmount, starSignId);
    return mergeCurrentChanceTicket(chanceZodiacBetTicketInfo, ticketInfo);
  }
};

export const createBetFromCurrentChanceZodiacBetTicket = (
  chanceZodiacBetTicketInfo: ITicketChanceZodiac[],
): IBet[] => {
  const bets: IBet[] = [];
  chanceZodiacBetTicketInfo.forEach((lottery) => {
    lottery.raffleChanceZodiacBet.forEach((raffle) => {
      raffle.ChanceZodiacBet.forEach((number) => {
        bets.push({
          lotteryId: lottery.lotteryId,
          betValue: number.betNumber,
          betTotal: Number(number.betAmount),
          gameTypeId: CHANCE_ZODIAC_RAFFLES_ID,
          raffleId: raffle.raffleId,
          raffleName: raffle.raffleName,
        });
      });
    });
  });
  return bets;
};

export const filterRaffleZodiacByMaxBet = (
  chanceZodiacLotteries: ChanceZodiacLottery[],
): {
  maxBet3: ChanceZodiacRaffle[];
  maxBet2: ChanceZodiacRaffle[];
} => {
  if (chanceZodiacLotteries !== undefined) {
    const filteredChanceRaffleDetailMaxBet2: ChanceZodiacRaffle[] = [];
    const filteredChanceRaffleDetailMaxBet3: ChanceZodiacRaffle[] = [];
    chanceZodiacLotteries.forEach((raffle) =>
      raffle.chanceZodiacRaffleDetail.forEach((raffleDetail) => {
        if (isRaffleActive(raffleDetail)) {
          if (raffle.chanceZodiacLotteryMaxDigitsByBet === 2) {
            filteredChanceRaffleDetailMaxBet2.push({
              ...raffleDetail,
              chanceZodiacLotteryId: raffle.chanceZodiacLotteryId,
              chanceZodiacLotteryName: raffle.chanceZodiacLotteryName,
              chanceZodiacRaffleName: raffleDetail.chanceZodiacRaffleName,
            });
          } else if (raffle.chanceZodiacLotteryMaxDigitsByBet === 3) {
            filteredChanceRaffleDetailMaxBet3.push({
              ...raffleDetail,
              chanceZodiacLotteryId: raffle.chanceZodiacLotteryId,
              chanceZodiacLotteryName: raffle.chanceZodiacLotteryName,
              chanceZodiacRaffleName: raffleDetail.chanceZodiacRaffleName,
            });
          }
        }
      }),
    );

    return {
      maxBet3: filteredChanceRaffleDetailMaxBet3,
      maxBet2: filteredChanceRaffleDetailMaxBet2,
    };
  }
  return { maxBet3: [], maxBet2: [] };
};

export const buildChanceZodiacDigitsBetWithErrors = (
  currentChanceZodiacDigitsTicketInfo: ITicketChanceZodiac[],
  errorBetList: ErrorValidateBet[],
): ITicketChanceZodiac[] => {
  return currentChanceZodiacDigitsTicketInfo.map((lottery) => {
    const newRaffleChanceZodiacBet = lottery.raffleChanceZodiacBet.map((raffle) => {
      return {
        ...raffle,
        ChanceZodiacBet: evaluateChanceDigitBetHasError(raffle.ChanceZodiacBet, errorBetList),
      };
    });
    return {
      ...lottery,
      raffleChanceZodiacBet: newRaffleChanceZodiacBet,
    };
  });
};

export const evaluateChanceDigitBetHasError = (
  chanceZodiacDigitBet: ChanceZodiacTicketBetArray[],
  errorBetList: ErrorValidateBet[],
): ChanceZodiacTicketBetArray[] => {
  return chanceZodiacDigitBet.map((chance) => {
    let foundAError = false;
    let finalIteration = false;
    let iterator = 0,
      internalListIterator = 0;
    while (!foundAError && !finalIteration) {
      errorBetList.forEach((errorBet, index) => {
        errorBet.errorList.forEach((errorDetail, indexList) => {
          if (
            errorDetail.betValue === chance.betNumber &&
            errorDetail.gameTypeId === CHANCE_ZODIAC_RAFFLES_ID
          ) {
            foundAError = true;
            iterator = index;
            internalListIterator = indexList;
          }
        });
      });
      finalIteration = true;
    }

    if (foundAError) {
      return {
        ...chance,
        error: errorBetList[iterator].errorList[internalListIterator].error,
        hasError: true,
      };
    }
    return chance;
  });
};

export const evaluateRaffleHasError = (
  raffleDetail: ITicketChanceZodiacDetail,
  errorBetList: ErrorValidateBet[],
): ITicketChanceZodiacDetail => {
  let foundAError = false;
  let finalIteration = false;
  let iterator = 0,
    internalListIterator = 0;
  while (!foundAError && !finalIteration) {
    errorBetList.forEach((errorBet, index) => {
      errorBet.errorList.forEach((errorDetail, indexList) => {
        if (
          errorDetail.raffleId === raffleDetail.raffleId &&
          errorDetail.gameTypeId === CHANCE_ZODIAC_RAFFLES_ID
        ) {
          foundAError = true;
          iterator = index;
          internalListIterator = indexList;
        }
      });
    });
    finalIteration = true;
  }

  if (foundAError) {
    return {
      ...raffleDetail,
      error: errorBetList[iterator].errorList[internalListIterator].error,
      hasError: true,
    };
  }
  return raffleDetail;
};

export const processBuiltChanceZodiacTicketWithError = (
  chanceZodiacDigitsWithError: ITicketChanceZodiac[],
) => {
  const errorsFound: string[] = [];
  const raffleWithoutError: ITicketChanceZodiac[] = [];

  chanceZodiacDigitsWithError.forEach((lottery) => {
    const raffleWithError: ITicketChanceZodiacDetail[] = [];
    lottery.raffleChanceZodiacBet.forEach((raffle) => {
      if (raffle.hasError && raffle.error) {
        errorsFound.push(raffle.error);
      } else {
        const raffleAux = cloneDeep(raffle);
        raffleAux.ChanceZodiacBet = [];
        raffle.ChanceZodiacBet.forEach((chance) => {
          if (chance.hasError && chance.error) {
            errorsFound.push(chance.error);
          } else {
            raffleAux.ChanceZodiacBet.push(chance);
          }
        });
        if (raffleAux.ChanceZodiacBet.length > 0) {
          raffleWithError.push(raffleAux);
        }
      }
    });
    if (raffleWithError.length > 0) {
      raffleWithoutError.push({
        ...lottery,
        raffleChanceZodiacBet: raffleWithError,
      });
    }
  });
  return {
    errorsFound,
    raffleWithoutError,
  };
};

export const createChanceZodiacPrintTicket = (ticketData: ITicketChanceZodiac[]): string => {
  let ticket = '';
  ticketData.forEach((lottery) => {
    lottery.raffleChanceZodiacBet.forEach((raffle) => {
      raffle.ChanceZodiacBet.forEach((chance) => {
        if (chance.betNumber !== '') {
          ticket += `${chance.betNumber} $${chance.betAmount},`;
        }
      });
      ticket += lineBreak;
    });
    ticket += lineBreak;
  });
  return ticket;
};
