import { Grid } from '@mui/material';
import { Raffles } from '@features/AnimalsGame/Raffles/Raffles';

import styles from './AnimalsView.module.scss';
import { Animals } from '@features/AnimalsGame/Animals/Animals';
import Ticket from '@features/Ticket/Ticket';
import AnimalsBet from '@features/AnimalsGame/AnimalsBet/AnimalsBet';
import { ConditionalRendering } from '@components/ConditionalRendering/ConditionalRendering';
import Box from '@mui/material/Box';
import AnimalTypeGame from '@features/AnimalsGame/AnimalTypeGame/AnimalTypeGame';
import SelectCurrencyModal from '@components/SelectCurrencyModal/SelectCurrencyModal';
import React from 'react';
import { IRaffleDetail } from '@models/Raffle.models';

export function AnimalsView({
  showAnimalGame,
  currentSelectedCurrency,
  selectedRaffles,
}: {
  showAnimalGame: boolean;
  currentSelectedCurrency: boolean;
  selectedRaffles: IRaffleDetail[];
}) {
  return (
    <Box className={styles.AnimalsView}>
      <Box sx={{ display: 'flex', flex: 1 }}>
        <Box sx={{ width: '75%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <AnimalTypeGame />
            <Grid sx={{ flex: 1, marginTop: 2 }} container spacing={2}>
              <ConditionalRendering isTrue={showAnimalGame}>
                <Grid
                  sx={{
                    paddingLeft: 0,
                    marginTop: 2,
                    height: 'calc(90vh - 38px)',
                    overflowY: 'auto',
                  }}
                  classes={{
                    root: styles.RaffleContainer,
                    item: styles.RaffleItem,
                  }}
                  item
                  xs={2.5}
                >
                  <Raffles />
                </Grid>
              </ConditionalRendering>
              <ConditionalRendering isTrue={showAnimalGame && selectedRaffles.length > 0}>
                <Grid item xs={6.5}>
                  <Animals />
                </Grid>
              </ConditionalRendering>
              <ConditionalRendering isTrue={selectedRaffles.length > 0}>
                <Grid
                  item
                  xs={2}
                  sx={{ marginTop: 2, paddingRight: 2 }}
                  className={styles.AnimalsBet}
                >
                  <AnimalsBet />
                </Grid>
              </ConditionalRendering>
            </Grid>
          </Box>
        </Box>
        <Grid sx={{ flex: 1 }} item xs={3}>
          <Ticket />
        </Grid>
      </Box>
      <SelectCurrencyModal isOpen={currentSelectedCurrency} />
    </Box>
  );
}
