import { AxiosError, AxiosResponse } from 'axios';
import httpCommon from '@config/http-common';
import { ITicketWinnerResponse, ITicketWinnerSubmit } from '@models/TicketWinner.models';

export const submitTicketWinnerApi = async (data: ITicketWinnerSubmit) => {
  try {
    const response: AxiosResponse<ITicketWinnerResponse<string>> = await httpCommon.post(
      'TicketWinner/submit-ticket-winner',
      data,
    );
    return response?.data || [];
  } catch (error) {
    throw (error as AxiosError).response?.data;
  }
};
