import { IRaffleDetail } from '@models/Raffle.models';
import { IBet } from '@models/Bet.models';
import { IAnimalBet, IAnimalDetail } from '@models/Animal.models';
import { IAnimalsActiveByLottery } from '@models/AnimalsLottery.models';
import { ITicketAnimal } from '@models/Ticket.models';
import { orderBy } from 'lodash';
import { GAME_TYPE_ID } from '@constants/enums.constants';

export const convertBetsToTicketAnimals = (
  bets: IBet[],
  activeAnimalsByLottery: IAnimalsActiveByLottery[],
): ITicketAnimal[] => {
  const ticketAnimalsMap: { [lotteryId: number]: ITicketAnimal } = {};

  bets.forEach((bet) => {
    const { lotteryId, betValue, betTotal, raffleId, raffleName } = bet;

    if (!ticketAnimalsMap[lotteryId]) {
      ticketAnimalsMap[lotteryId] = {
        lotteryId,
        animalGameTypeId: GAME_TYPE_ID.ANIMAL_GAME,
        lotteryName:
          activeAnimalsByLottery.find((lottery) => lottery.animalitosLotteryId === lotteryId)
            ?.animalitosLotteryName ?? '',
        rafflesAnimalBet: [],
        totalBet: 0,
      };
    }

    const currentTicketAnimal = ticketAnimalsMap[lotteryId];

    let raffleDetail = currentTicketAnimal.rafflesAnimalBet.find(
      (raffle) => raffle.raffleId === raffleId,
    );

    if (!raffleDetail) {
      raffleDetail = {
        raffleId,
        raffleName,
        animalsBet: [],
        totalBet: 0,
      };
      currentTicketAnimal.rafflesAnimalBet.push(raffleDetail);
    }

    const animal = getAnimalFromLotteriesByAnimal(activeAnimalsByLottery, lotteryId, betValue);
    if (animal) {
      const animalBet: IAnimalBet = {
        animalId: animal.animalId,
        animalUserId: animal.animalUserId,
        animalName: animal.animalName,
        animalImageUrl: animal.animalImageUrl,
        animalIsFruit: animal.animalIsFruit,
        betAmount: betTotal,
        hasError: false,
      };
      raffleDetail.animalsBet.push(animalBet);
      raffleDetail.totalBet += betTotal || 0;
      currentTicketAnimal.totalBet += betTotal || 0;
    }
  });
  // Sort animalsBet array by animalName for each raffleDetail
  Object.values(ticketAnimalsMap).forEach((ticketAnimal) => {
    ticketAnimal.rafflesAnimalBet.forEach((raffleDetail) => {
      raffleDetail.animalsBet = orderBy(raffleDetail.animalsBet, ['animalName'], ['asc']);
    });
  });
  return Object.values(ticketAnimalsMap);
};

const getAnimalFromLotteriesByAnimal = (
  activeAnimalsByLottery: IAnimalsActiveByLottery[],
  lotteryId: number,
  animalId: string,
): IAnimalDetail | undefined => {
  const animalsByLottery = activeAnimalsByLottery.find(
    (animalLottery) => animalLottery.animalitosLotteryId === lotteryId,
  )?.animalDetails;
  if (animalsByLottery) {
    return animalsByLottery.find((animal) => animal.animalId.toString() === animalId);
  }
};

export const convertDuplicateBetsToSelectedRaffle = (
  bets: IBet[],
  activeRaffles: IRaffleDetail[],
): IRaffleDetail[] => {
  const raffleIdSet = new Set<number>();
  const activeRaffle: IRaffleDetail[] = [];

  bets.forEach((bet) => {
    if (!raffleIdSet.has(bet.raffleId)) {
      raffleIdSet.add(bet.raffleId);
      const raffleDetail = activeRaffles.find(
        (raffle) => raffle.animalitosRaffleId === bet.raffleId,
      );
      if (raffleDetail) {
        activeRaffle.push(raffleDetail);
      }
    }
  });

  return activeRaffle;
};

export const mergeAnimalBetTicketInfo = (
  current: ITicketAnimal[],
  newInfo: ITicketAnimal[],
): ITicketAnimal[] => {
  // Create a deep copy of the current state to ensure immutability
  const merged = current.map((ticket) => ({
    ...ticket,
    rafflesAnimalBet: ticket.rafflesAnimalBet.map((raffle) => ({
      ...raffle,
      animalsBet: [...raffle.animalsBet],
    })),
  }));

  newInfo.forEach((newTicket) => {
    const existingIndex = merged.findIndex((ticket) => ticket.lotteryId === newTicket.lotteryId);

    if (existingIndex !== -1) {
      // Merge raffles
      newTicket.rafflesAnimalBet.forEach((newRaffle) => {
        const existingRaffleIndex = merged[existingIndex].rafflesAnimalBet.findIndex(
          (raffle) => raffle.raffleId === newRaffle.raffleId,
        );

        if (existingRaffleIndex !== -1) {
          // Merge animals
          newRaffle.animalsBet.forEach((newAnimalBet) => {
            const existingAnimalIndex = merged[existingIndex].rafflesAnimalBet[
              existingRaffleIndex
            ].animalsBet.findIndex((animalBet) => animalBet.animalId === newAnimalBet.animalId);

            if (existingAnimalIndex === -1) {
              // Add new animal bet
              merged[existingIndex].rafflesAnimalBet[existingRaffleIndex].animalsBet.push(
                newAnimalBet,
              );
            } else {
              // Update existing animal bet
              merged[existingIndex].rafflesAnimalBet[existingRaffleIndex].animalsBet[
                existingAnimalIndex
              ] = {
                ...merged[existingIndex].rafflesAnimalBet[existingRaffleIndex].animalsBet[
                  existingAnimalIndex
                ],
                ...newAnimalBet,
              };
            }
          });
        } else {
          // Add new raffle
          merged[existingIndex].rafflesAnimalBet.push({
            ...newRaffle,
            animalsBet: [...newRaffle.animalsBet],
          });
        }
      });
    } else {
      // Add new ticket
      merged.push({
        ...newTicket,
        rafflesAnimalBet: newTicket.rafflesAnimalBet.map((raffle) => ({
          ...raffle,
          animalsBet: [...raffle.animalsBet],
        })),
      });
    }
  });

  return merged;
};
