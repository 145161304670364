import { IAnimalsActiveByLottery } from '@models/AnimalsLottery.models';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getActiveAnimalByRafflesCurrentDate } from './AnimalsLottery.api';

interface IAnimalsLotteryState {
  status: 'success' | 'failed' | 'idle';
  isLoading: boolean;
  activeAnimalsByLottery: IAnimalsActiveByLottery[];
}

const initialState: IAnimalsLotteryState = {
  status: 'idle',
  isLoading: false,
  activeAnimalsByLottery: [],
};

export const getActiveAnimalByLotteryAsync = createAsyncThunk(
  'animalsLottery/getActiveAnimalByLotteryAsync',
  getActiveAnimalByRafflesCurrentDate,
);

export const animalsLotterySlice = createSlice({
  name: 'animalsLottery',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActiveAnimalByLotteryAsync.pending, (state) => {
        state.isLoading = true;
        state.activeAnimalsByLottery = [];
      })
      .addCase(getActiveAnimalByLotteryAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activeAnimalsByLottery = action.payload;
        state.status = 'success';
      })
      .addCase(getActiveAnimalByLotteryAsync.rejected, (state) => {
        state.isLoading = false;
        state.status = 'failed';
      });
  },
});

export const animalsLotteryReducer = animalsLotterySlice.reducer;
