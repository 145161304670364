import React from 'react';
import { dollarTheme } from '../../theme/theme';
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';
import { closeSnackbar, SnackbarProvider } from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { useAppSelector } from '@app/hooks';
import { appThemes } from '@constants/app.constants';

interface ThemeProviderProps {
  children: React.ReactNode;
}

const AppThemeProvider = ({ children }: ThemeProviderProps) => {
  const selectedCurrencyId = useAppSelector((state) => state.currency.selectedCurrencyId);

  return (
    <ThemeProvider theme={appThemes[selectedCurrencyId - 1] ?? dollarTheme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        action={(snackbarId) => (
          <IconButton sx={{ color: grey[50] }} onClick={() => closeSnackbar(snackbarId)}>
            <CancelIcon />
          </IconButton>
        )}
        maxSnack={10}
      >
        {children}
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default AppThemeProvider;
