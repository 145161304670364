import { AxiosError, AxiosResponse } from 'axios';
import httpCommon from '@config/http-common';
import { IAnimalResponse, IAnimalsActiveByLottery } from '@models/AnimalsLottery.models';

export const getActiveAnimalByRafflesCurrentDate = async () => {
  try {
    const response: AxiosResponse<IAnimalResponse<IAnimalsActiveByLottery[]>> =
      await httpCommon.get('AnimalitosRaffle/get-active-animalitos-raffle-list-for-current-date');
    return response?.data?.response || [];
  } catch (error) {
    if (error instanceof AxiosError) {
      return error.response?.data?.message || 'unknown error';
    }
    return 'unknown error';
  }
};
