import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ITicketRaffleAnimalDetail } from '@models/Ticket.models';
import AnimalBetTicketInfo from '@components/AnimalTicketInfo/components/AnimalBetTicketInfo';
import Grid from '@mui/material/Grid';
import { IconButton } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConditionalRendering } from '@components/ConditionalRendering/ConditionalRendering';
import { IAnimalBet } from '@models/Animal.models';

interface AnimalBetTicketInfoProps {
  rafflesAnimalBet: ITicketRaffleAnimalDetail[];
  deleteRaffleBetAction: (raffleId: number) => void;
  setEditableRaffleAction: (raffleId: number, finishEdit?: boolean) => void;
  updateAnimalBetAmount: (raffleIndex: number, animalIndex: number, amount: string) => void;
  formHasErrors: boolean;
  formatCurrency: (amount: number) => string;
  animalGameTypeId: number;
  isPrintingTicket: boolean;
}

function RaffleBetTicketInfo({
  rafflesAnimalBet,
  deleteRaffleBetAction,
  setEditableRaffleAction,
  updateAnimalBetAmount,
  formHasErrors,
  formatCurrency,
  isPrintingTicket,
}: AnimalBetTicketInfoProps) {
  const calculateTotalByRaffle = (animalsBet: IAnimalBet[]) =>
    animalsBet.reduce((acc, animal) => acc + Number(animal.betAmount), 0);

  const renderRaffleAnimalBet = rafflesAnimalBet.map((raffleAnimalBet, raffleIndex) => (
    <Box key={`raffle-ticket-animal-${raffleAnimalBet.raffleName}`}>
      <Grid container spacing={2}>
        <Grid item xs={1} />
        <Grid item xs={7}>
          <Typography sx={{ marginTop: 1 }} component='p' variant='body1'>
            <strong>{raffleAnimalBet.raffleName}</strong>
          </Typography>
        </Grid>
        <ConditionalRendering isTrue={!!raffleAnimalBet.isEditing}>
          <Grid item xs={2}>
            <IconButton
              disabled={formHasErrors || isPrintingTicket}
              onClick={() => setEditableRaffleAction(raffleIndex, true)}
            >
              <SvgIcon component={CheckIcon} />
            </IconButton>
          </Grid>
        </ConditionalRendering>
        <ConditionalRendering isTrue={!raffleAnimalBet.isEditing}>
          <Grid item xs={2}>
            <IconButton
              disabled={isPrintingTicket}
              onClick={() => setEditableRaffleAction(raffleIndex)}
            >
              <SvgIcon component={EditIcon} />
            </IconButton>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              disabled={isPrintingTicket}
              onClick={() => deleteRaffleBetAction(raffleAnimalBet.raffleId)}
            >
              <SvgIcon component={DeleteIcon} />
            </IconButton>
          </Grid>
        </ConditionalRendering>
      </Grid>

      <AnimalBetTicketInfo
        animalsBet={raffleAnimalBet.animalsBet}
        isEditable={!!raffleAnimalBet.isEditing}
        updateAnimalBetAmountAction={(animalIndex: number, amount: string) =>
          updateAnimalBetAmount(raffleIndex, animalIndex, amount)
        }
        formatCurrency={formatCurrency}
      />

      <Typography sx={{ paddingLeft: 2, paddingTop: 1 }} component='p' variant='body1'>
        Total:{' '}
        <strong> {formatCurrency(calculateTotalByRaffle(raffleAnimalBet.animalsBet))}</strong>
      </Typography>
    </Box>
  ));

  return <>{renderRaffleAnimalBet}</>;
}

export default RaffleBetTicketInfo;
