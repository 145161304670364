import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const raffleSettingsSelector = createSelector(
  (state: RootState) => state.currency.raffleSettings,
  (raffleSettings) => {
    return raffleSettings;
  },
);

export const selectedCurrencyIdSelector = createSelector(
  (state: RootState) => state.currency.selectedCurrencyId,
  (selectedCurrencyId) => {
    return selectedCurrencyId;
  },
);

export const currencyListSelector = createSelector(
  (state: RootState) => state.currency.currencyList,
  (currencyList) => {
    return currencyList;
  },
);

export const selectedChance3DigitsSettingSelector = createSelector(
  (state: RootState) => state.currency.selectedChance3DigitsSetting,
  (selectedChance3DigitsSetting) => {
    return selectedChance3DigitsSetting;
  },
);

export const activeChance3DigitsSettingsSelector = createSelector(
  (state: RootState) => state.currency.activeChance3DigitsSettings,
  (activeChance3DigitsSettings) => {
    return activeChance3DigitsSettings;
  },
);

export const selectedChanceZodiacSettingSelector = createSelector(
  (state: RootState) => state.currency.selectedChanceZodiacSetting,
  (selectedChanceZodiacSetting) => {
    return selectedChanceZodiacSetting;
  },
);

export const activeChanceZodiacSettingsSelector = createSelector(
  (state: RootState) => state.currency.activeChanceZodiacSettings,
  (activeChanceZodiacSettings) => {
    return activeChanceZodiacSettings;
  },
);

export const sessionConfirmChangeCurrencySelector = createSelector(
  (state: RootState) => state.currency.showSessionConfirmChangeCurrency,
  (sessionConfirmChangeCurrency) => {
    return sessionConfirmChangeCurrency;
  },
);

export const selectedChance4DigitsSettingSelector = createSelector(
  (state: RootState) => state.currency.selectedChance4DigitsSetting,
  (selectedChance4DigitsSetting) => {
    return selectedChance4DigitsSetting;
  },
);

export const activeChance4DigitsSettingsSelector = createSelector(
  (state: RootState) => state.currency.activeChance4DigitsSettings,
  (activeChance4DigitsSettings) => {
    return activeChance4DigitsSettings;
  },
);

export const changeCurrencyOpenDialogSelector = createSelector(
  (state: RootState) => state.currency.changeCurrencyOpenDialog,
  (changeCurrencyOpenDialog) => {
    return changeCurrencyOpenDialog;
  },
);

export const disabledChangeCurrencySelector = createSelector(
  (state: RootState) => state.currency.disabledChangeCurrency,
  (disabledChangeCurrency) => {
    return disabledChangeCurrency;
  },
);

export const openDialogSelector = createSelector(
  (state: RootState) => state.currency.openDialog,
  (openDialog) => {
    return openDialog;
  },
);

export const shouldAskBeforeChangeCurrencySelector = createSelector(
  (state: RootState) => state.currency.shouldAskBeforeChangeCurrency,
  (shouldAskBeforeChangeCurrency) => {
    return shouldAskBeforeChangeCurrency;
  },
);
