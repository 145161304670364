import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const animalTypeGameSelector = createSelector(
  (state: RootState) => state.animalTypeGame,
  (animalTypeGame) => animalTypeGame,
);

export const selectedAnimalTypeGameSelector = createSelector(
  (state: RootState) => state.animalTypeGame.selectedAnimalTypeGame,
  (selectedAnimalTypeGame) => selectedAnimalTypeGame,
);
