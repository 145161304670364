export enum COMMON_ACTION_TYPES {
  SET_NUMBER = 'SET_NUMBER',
  SET_ERROR = 'SET_ERROR',
  CLEAN_STATE = 'CLEAN_STATE',
  CLEAN_ERROR = 'CLEAN_ERROR',
}

export enum TICKET_CANCEL_REASON {
  WRONG_NUMBER = 'wrong_number',
  PRINTER_ISSUE = 'printer_issue',
  WRONG_NUMBER_LABEL = 'Cifra Errada',
  PRINTER_ISSUE_LABEL = 'Error Impresora',
}

export enum GAME_TYPE_LABEL {
  ANIMAL_GAME = 'Animalitos',
  CHANCE_THREE_GAME = 'Chance 3 cifras',
  CHANCE_FOUR_GAME = 'Chance 4 cifras',
  CHANCE_ZODIAC_GAME = 'Chance zodiacal',
}

export enum GAME_TYPE_ID {
  ANIMAL_GAME = 1,
  CHANCE_THREE_GAME = 2,
  CHANCE_FOUR_GAME = 3,
  CHANCE_ZODIAC_GAME = 4,
}
