import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { actionCleanAnimalsState } from '@features/AnimalsGame/Animals/AnimalsSlice';
import { actionCleanAnimalsBetState } from '@features/AnimalsGame/AnimalsBet/AnimalsBetSlice';
import { enqueueSnackbar } from 'notistack';
import {
  actionCleanTicketState,
  actionSetIsLoading,
  actionSetIsNotLoading,
  addTicketAsync,
} from '@features/Ticket/TicketSlice';
import { IBetTicket } from '@models/Bet.models';
import { actionSetChanceMaxBet } from '@features/Chance3Digits/Chance3Raffles/Chance3Raffle.slice';
import { filterRaffleByMaxBet } from '@helpers/chance3digits.helpers';
import { useAddTicket } from '@hooks/addTicket.hook';
import { actionSetChance4DigitsLotteries } from '@controllers/Chance4Digits/Chance4Digits.slice';
import { actionSetChanceZodiacLotteries } from '@controllers/ChanceZodiac/ChanceZodiac.slice';
import { Print } from '@helpers/printer.helper';
import { ITicketPrint } from '@models/Ticket.models';
import { createTicketPrintString } from '@helpers/ticket.helpers';
import { filterChance4RaffleByMaxBet } from '@helpers/chance4digits.helpers';
import { actionSetChance4MaxBet } from '@features/Chance4Digits/Chance4Raffles/Chance4Raffle.slice';
import { filterChanceZodiacRaffleByMaxBet } from '@helpers/chanceZ.helpers';
import { actionSetChanceZodiacMaxBet } from '@features/ChanceZodiac/ChanceZodiacRaffles/ChanceZodiacRaffle.slice';
import { useHotkeys } from 'react-hotkeys-hook';
import { PRINT_TICKET_SHORTCUT } from '@constants/shortcuts.constants';
import {
  betErrorsSelector,
  betResponseSelector,
  isLoadingSelector,
  statusTicketSelector,
  ticketBetsSelector,
} from '@selectors/ticket.selector';
import { chance3digitsSelector } from '@selectors/chance3digits.selector';
import { chance4DigitsLotteriesSelector } from '@selectors/chance4Digits.selector';
import { chanceZodiacLotteriesSelector } from '@selectors/chanceZodiac.selector';
import {
  raffleSettingsSelector,
  selectedCurrencyIdSelector,
} from '@selectors/animalcurrency.selector';

export const useTicket = () => {
  const dispatch = useAppDispatch();

  const betErrors = useAppSelector(betErrorsSelector);
  const betResponse = useAppSelector(betResponseSelector);
  const chance3DigitsLotteries = useAppSelector(chance3digitsSelector);
  const chance4DigitsLotteries = useAppSelector(chance4DigitsLotteriesSelector);
  const chanceZodiacLotteries = useAppSelector(chanceZodiacLotteriesSelector);
  const currencySetting = useAppSelector(raffleSettingsSelector);
  const isLoading = useAppSelector(isLoadingSelector);
  const selectedCurrencyId = useAppSelector(selectedCurrencyIdSelector);
  const ticketBets = useAppSelector(ticketBetsSelector);
  const ticketStatusRequest = useAppSelector(statusTicketSelector);

  const [totalBet, setTotalBet] = useState(0);
  

  useAddTicket();
  const formatCurrency = (value: number) => {
    switch (selectedCurrencyId) {
      case 2:
        return new Intl.NumberFormat('es-VE', { style: 'currency', currency: 'VES' }).format(value);
      default:
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    }
  };

  useHotkeys(PRINT_TICKET_SHORTCUT, () => {
    if (totalBet > 0) {
      onCreateTicket();
    }
  });

  const printTicket = () => {
    const printString = createTicketPrintString(betResponse.bets);

    const ticketPrint: ITicketPrint = {
      ticketId: betResponse.ticketId.toString(),
      ticketTotalAmount: formatCurrency(betResponse.ticketTotal),
      ticketNumber: betResponse.ticketNumber,
      ticketCreatedAt: betResponse.ticketCreatedAt,
      currencyCode: betResponse.currencyCode,
      ticketData: printString,
      ticketDueDays: betResponse.ticketDueDays,
      ticketCompanyName: betResponse.ticketCompanyName,
    };
    return Print(ticketPrint);
  };

  useEffect(() => {
    if (ticketStatusRequest === 'success') {
      dispatch(actionSetIsLoading());
      enqueueSnackbar('Ticket creado correctamente', { variant: 'success' });
      printTicket()
        .then(() => {
          enqueueSnackbar('Ticket se ha impreso correctamente', { variant: 'success' });
        })
        .catch((error) => {
          enqueueSnackbar(`Ha ocurrido un error al imprimir el ticket ${error}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          batch(() => {
            dispatch(actionCleanAnimalsState());
            dispatch(actionCleanAnimalsBetState());
            dispatch(actionCleanTicketState());
            const rafflesChance3Digits = filterRaffleByMaxBet(chance3DigitsLotteries);
            dispatch(
              actionSetChanceMaxBet({
                maxBet3: rafflesChance3Digits.maxBet3,
                maxBet2: rafflesChance3Digits.maxBet2,
              }),
            );
            const rafflesChance4Digits = filterChance4RaffleByMaxBet(chance4DigitsLotteries);
            const rafflesChanceZodiac = filterChanceZodiacRaffleByMaxBet(chanceZodiacLotteries);
            dispatch(actionSetChance4MaxBet({ maxBet4: rafflesChance4Digits.maxBet4 }));
            dispatch(actionSetChanceZodiacMaxBet({ maxBet3: rafflesChanceZodiac.maxBet3 }));
            dispatch(actionSetChance4DigitsLotteries(chance4DigitsLotteries));
            dispatch(actionSetChanceZodiacLotteries(chanceZodiacLotteries));
            dispatch(actionSetIsNotLoading());
          });
        });
    } else if (betErrors.length > 0) {
      betErrors
        .flatMap((error) => error.errorList)
        .forEach((detailError) => enqueueSnackbar(detailError.error, { variant: 'error' }));
    }
  }, [ticketStatusRequest]);

  useEffect(() => {
    const totalBet = ticketBets.reduce((acc, bet) => acc + Number(bet.betTotal), 0);
    setTotalBet(totalBet);
  }, [ticketBets]);

  const onCreateTicket = () => {
    const betTicket: IBetTicket = {
      bets: ticketBets,
      currencyId: selectedCurrencyId,
      salePointId: 1,
    };
    dispatch(addTicketAsync(betTicket));
  };

  const formattedTotalBet = formatCurrency(totalBet);

  return {
    currencySetting,
    formatCurrency,
    isLoading,
    totalBet,
    onCreateTicket,
    printTicket,
    formattedTotalBet,    
  };
};
