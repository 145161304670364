export const printInit = '\x1B' + '\x40';
export const lineBreak = '\x0A';
export const encodingCp1522 = '\x1B' + '\x74' + '\x10';
export const centerAlign = '\x1B' + '\x61' + '\x31';
export const leftAlign = '\x1B' + '\x61' + '\x30';
export const rightAlign = '\x1B' + '\x61' + '\x32';
export const boldOn = '\x1B' + '\x45' + '\x0D';
export const boldOff = '\x1B' + '\x45' + '\x0A';
export const emModeOn = '\x1B' + '\x21' + '\x30';
export const emModeOff = '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A';
export const smallText = '\x1B' + '\x4D' + '\x31';
export const normalText = '\x1B' + '\x4D' + '\x30';
export const kickCashDrawer = '\x10' + '\x14' + '\x01' + '\x00' + '\x05';
export const defaultSize = '\x1D\x21\x00';
