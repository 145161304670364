import { useEffect } from 'react';
import {
  buildAnimalTicketBetWithError,
  processBuildTicketWithError,
} from '@helpers/ticket.helpers';
import {
  actionCleanBetErrors,
  actionSetAnimalBetTicketInfo,
  actionSetBet,
  actionSetChance3BetTicketInfo,
  actionSetChance4BetTicketInfo,
  actionSetChanceZodiacBetTicketInfo,
  actionSetFoundErrorsOnAddTicket,
} from '@features/Ticket/TicketSlice';
import { buildBetFromTicket } from '@helpers/bet.helpers';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  buildChance3DigitsBetWithErrors,
  createBetFromCurrentChanceBetTicket,
  processBuiltChance3DigitsTicketWithError,
} from '@helpers/chance3digits.helpers';
import { uniq } from 'lodash';
import {
  buildChance4DigitsBetWithErrors,
  createBetFromCurrentChance4BetTicket,
  processBuiltChance4DigitsTicketWithError,
} from '@helpers/chance4digits.helpers';
import {
  buildChanceZodiacDigitsBetWithErrors,
  createBetFromCurrentChanceZodiacBetTicket,
  processBuiltChanceZodiacTicketWithError,
} from '@helpers/chanceZodiac.helpers';
import { selectedCurrencyIdSelector } from '@selectors/animalcurrency.selector';
import {
  animalBetTicketInfoSelector,
  betErrorsSelector,
  chance3BetTicketInfoSelector,
  chance4BetTicketInfoSelector,
  chanceZodiacBetTicketInfoSelector,
  foundErrorsOnAddTicketSelector,
} from '@selectors/ticket.selector';

export const useAddTicket = () => {
  const dispatch = useAppDispatch();

  const betErrorsTickets = useAppSelector(betErrorsSelector);
  const foundErrorsOnAddTicket = useAppSelector(foundErrorsOnAddTicketSelector);
  const selectedCurrencyId = useAppSelector(selectedCurrencyIdSelector);
  const animalBetTicketInfo = useAppSelector(animalBetTicketInfoSelector);
  const chance3BetTicketInfo = useAppSelector(chance3BetTicketInfoSelector);
  const chance4BetTicketInfo = useAppSelector(chance4BetTicketInfoSelector);
  const chanceZodiacBetTicketInfo = useAppSelector(chanceZodiacBetTicketInfoSelector);

  useEffect(() => {
    if (foundErrorsOnAddTicket.length > 0) {
      foundErrorsOnAddTicket.forEach((error) => {
        enqueueSnackbar(error, { variant: 'error', hideIconVariant: true });
      });
    }
  }, [foundErrorsOnAddTicket]);

  const processAnimalAddTicketErrors = async () => {
    const newAnimalBetTicketInfo = buildAnimalTicketBetWithError(
      animalBetTicketInfo,
      betErrorsTickets,
    );
    const processBuiltTicketInfo = processBuildTicketWithError(newAnimalBetTicketInfo);
    await dispatch(actionSetAnimalBetTicketInfo(processBuiltTicketInfo.raffleWithoutError));

    const bets = buildBetFromTicket(
      selectedCurrencyId,
      processBuiltTicketInfo.raffleWithoutError,
    ).bets;
    return { bets, errors: processBuiltTicketInfo.errorsFound };
  };

  const processChance3DigitsAddTicketError = async () => {
    const newChance3DigitsTicketInfo = buildChance3DigitsBetWithErrors(
      chance3BetTicketInfo,
      betErrorsTickets,
    );
    const processBuiltChance3DigitsTicketInfo = processBuiltChance3DigitsTicketWithError(
      newChance3DigitsTicketInfo,
    );

    await dispatch(
      actionSetChance3BetTicketInfo(processBuiltChance3DigitsTicketInfo.raffleWithoutError),
    );

    const bets = createBetFromCurrentChanceBetTicket(
      processBuiltChance3DigitsTicketInfo.raffleWithoutError,
    );

    return { bets, errors: processBuiltChance3DigitsTicketInfo.errorsFound };
  };

  const processChance4DigitsAddTicketError = async () => {
    const newChance4DigitsTicketInfo = buildChance4DigitsBetWithErrors(
      chance4BetTicketInfo,
      betErrorsTickets,
    );
    const processBuiltChance4DigitsTicketInfo = processBuiltChance4DigitsTicketWithError(
      newChance4DigitsTicketInfo,
    );

    await dispatch(
      actionSetChance4BetTicketInfo(processBuiltChance4DigitsTicketInfo.raffleWithoutError),
    );

    const bets = createBetFromCurrentChance4BetTicket(
      processBuiltChance4DigitsTicketInfo.raffleWithoutError,
    );

    return { bets, errors: processBuiltChance4DigitsTicketInfo.errorsFound };
  };

  const processChanceZodiacAddTicketError = async () => {
    const newChanceZodiacDigitsTicketInfo = buildChanceZodiacDigitsBetWithErrors(
      chanceZodiacBetTicketInfo,
      betErrorsTickets,
    );
    const processBuiltChanceZodiacDigitsTicketInfo = processBuiltChanceZodiacTicketWithError(
      newChanceZodiacDigitsTicketInfo,
    );

    await dispatch(
      actionSetChanceZodiacBetTicketInfo(
        processBuiltChanceZodiacDigitsTicketInfo.raffleWithoutError,
      ),
    );

    const bets = createBetFromCurrentChanceZodiacBetTicket(
      processBuiltChanceZodiacDigitsTicketInfo.raffleWithoutError,
    );

    return { bets, errors: processBuiltChanceZodiacDigitsTicketInfo.errorsFound };
  };

  const processAddTicket = async () => {
    const errorsFoundAnimalTicket = await processAnimalAddTicketErrors();
    const errorsFoundChance3DigitsTicket = await processChance3DigitsAddTicketError();
    const errorsFoundChance4DigitsTicket = await processChance4DigitsAddTicketError();
    const errorsFoundChanceZodiacTicket = await processChanceZodiacAddTicketError();

    await dispatch(
      actionSetBet(
        errorsFoundAnimalTicket.bets
          .concat(errorsFoundChance3DigitsTicket.bets)
          .concat(errorsFoundChance4DigitsTicket.bets)
          .concat(errorsFoundChanceZodiacTicket.bets),
      ),
    );

    return uniq(
      errorsFoundAnimalTicket.errors
        .concat(errorsFoundChance3DigitsTicket.errors)
        .concat(errorsFoundChance4DigitsTicket.errors)
        .concat(errorsFoundChanceZodiacTicket.errors),
    );
  };

  const setErrorsOnAddTicket = async (errors: string[]) => {
    await dispatch(actionSetFoundErrorsOnAddTicket(errors));
  };

  useEffect(() => {
    if (betErrorsTickets.length > 0) {
      processAddTicket().then((errors) => {
        if (errors) {
          setErrorsOnAddTicket(errors).then(() => {
            dispatch(actionCleanBetErrors());
          });
        }
      });
    } else {
      dispatch(actionSetFoundErrorsOnAddTicket([]));
    }
  }, [betErrorsTickets]);
};
