import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const selectedAnimalBetSelector = createSelector(
  (state: RootState) => state.animalsBet.selectedAnimalBet,
  (selectedAnimalBet) => {
    return selectedAnimalBet;
  },
);

export const statusAnimalBetSelector = createSelector(
  (state: RootState) => state.animalsBet.status,
  (status) => {
    return status;
  },
);

export const isLoadingAnimalBetSelector = createSelector(
  (state: RootState) => state.animalsBet.isLoading,
  (isLoading) => {
    return isLoading;
  },
);

export const isAlReadyFetchedAnimalBetSelector = createSelector(
  (state: RootState) => state.animalsBet.isAlReadyFetched,
  (isAlReadyFetched) => {
    return isAlReadyFetched;
  },
);

export const betErrorsAnimalBetSelector = createSelector(
  (state: RootState) => state.animalsBet.betErrors,
  (betErrors) => {
    return betErrors;
  },
);
