import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { submitTicketWinnerApi } from './TicketWinnerStepper.api';
import { ITicketWinnerResponse, ITicketWinnerSubmit } from '@models/TicketWinner.models';

export interface TicketWinnerStepperState {
  selectedTicketWinner: ITicketWinnerSubmit;
  openDialog: boolean;
  error: string;
  isLoading: boolean;
  status: 'success' | 'failed' | 'idle' | 'pending';
  step: number;
  totalToPay: number;
  ticketNumber?: number;
  ticketCode?: string;
}

export const initialState: TicketWinnerStepperState = {
  selectedTicketWinner: {} as ITicketWinnerSubmit,
  openDialog: false,
  error: '',
  isLoading: false,
  status: 'idle',
  step: 0,
  totalToPay: 0,
  ticketNumber: undefined,
  ticketCode: '',
};

export const confirmTicketWinnerPaymentAsync = createAsyncThunk(
  'ticketWinner/submit-ticket-winner',
  async (ticketData: ITicketWinnerSubmit) => {
    const response = await submitTicketWinnerApi(ticketData);
    return response as ITicketWinnerResponse<string>;
  },
);

export const confirmTicketWinnerPaymentSlice = createSlice({
  name: 'ticketWinnerPayment',
  initialState,
  reducers: {
    actionSetTicketNumber: (state, action: PayloadAction<number>) => {
      state.ticketNumber = action.payload;
    },
    actionSetTicketCode: (state, action: PayloadAction<string>) => {
      state.ticketCode = action.payload;
    },
    actionSetTotalToPay: (state, action: PayloadAction<number>) => {
      state.totalToPay = action.payload;
    },
    actionSetStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    actionCleanTicketSelectedWinner: () => {
      return initialState;
    },
    actionShowTicketWinnerPaymentDialogConfirm: (state) => {
      state.openDialog = true;
    },
    actionHideTicketWinnerPaymentDialogConfirm: (state) => {
      state.openDialog = false;
    },
    actionSetSelectedTicketWinner: (state, action) => {
      state.selectedTicketWinner = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(confirmTicketWinnerPaymentAsync.pending, (state) => {
        state.isLoading = true;
        state.status = 'pending';
        state.error = '';
      })
      .addCase(
        confirmTicketWinnerPaymentAsync.fulfilled,
        (state, action: PayloadAction<ITicketWinnerResponse<string>>) => {
          state.isLoading = false;
          state.status = action.payload.success ? 'success' : 'failed';
          if (action.payload.success) {
            state.error = '';
          } else {
            state.error = action.payload.message;
          }
        },
      )
      .addCase(confirmTicketWinnerPaymentAsync.rejected, (state) => {
        state.isLoading = false;
        state.status = 'failed';
        state.error = '';
      });
  },
});

export const {
  actionSetSelectedTicketWinner,
  actionCleanTicketSelectedWinner,
  actionShowTicketWinnerPaymentDialogConfirm,
  actionHideTicketWinnerPaymentDialogConfirm,
  actionSetStep,
  actionSetTotalToPay,
  actionSetTicketNumber,
  actionSetTicketCode,
} = confirmTicketWinnerPaymentSlice.actions;

export const ticketWinnerStepperReducer = confirmTicketWinnerPaymentSlice.reducer;
