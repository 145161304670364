import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';

import { useAnimalCurrency } from '@hooks/animalCurrency.hook';
import {
  actionHideDialogConfirm,
  actionSetDisabledChangeCurrency,
  actionSetSelectedCurrencyId,
  actionSetSelectedLotterySettings,
  actionSetSessionConfirmChangeCurrency,
  actionShowDialogConfirm,
} from '@features/Currency/Currency.slice';

import { useAnimalsBet } from '@features/AnimalsGame/AnimalsBet/AnimalBet.hook';
import { useChance3DigitsCurrency } from '@hooks/chance3DigitsCurrency.hook';
import { useChance4DigitsCurrency } from '@hooks/chance4DigitsCurrency.hook';
import { useChanceZodiacCurrency } from '@hooks/chanceZodiacCurrency.hook';
import {
  currencyListSelector,
  disabledChangeCurrencySelector,
  openDialogSelector,
  selectedCurrencyIdSelector,
} from '@selectors/animalcurrency.selector';
import { ticketBetsSelector } from '@selectors/ticket.selector';

export const useCurrency = () => {
  const dispatch = useAppDispatch();
  const animalCurrency = useAnimalCurrency();
  const animalsBet = useAnimalsBet();
  const chance3DigitsCurrency = useChance3DigitsCurrency();
  const chance4DigitsCurrency = useChance4DigitsCurrency();
  const chanceZodiacCurrency = useChanceZodiacCurrency();

  const currencyList = useAppSelector(currencyListSelector);
  const selectedCurrencyId = useAppSelector(selectedCurrencyIdSelector);
  const disabledChangeCurrency = useAppSelector(disabledChangeCurrencySelector);
  const ticketBets = useAppSelector(ticketBetsSelector);
  const openDialog = useAppSelector(openDialogSelector);

  const [selectedCurrency, setSelectedCurrency] = useState(1);

  const changeAllAppCurrenciesSettings = (currencyId: number) => {
    animalCurrency.onSelectLotterySettings();
    chance3DigitsCurrency.onSelectChanceSetting();
    chance4DigitsCurrency.onSelectChanceSetting();
    chanceZodiacCurrency.onSelectChanceSetting();
    animalsBet.cleanAnimalRaffleStates();
    sessionStorage.setItem('selectedCurrency', currencyId.toString());
    actionSetSelectedLotterySettings;
    dispatch(actionSetSelectedCurrencyId(currencyId));
  };

  const onChangeCurrency = (currencyId: number) => {
    dispatch(actionShowDialogConfirm());
    setSelectedCurrency(currencyId);
  };

  const onConfirmChangeCurrency = (confirm: boolean) => {
    if (confirm) {
      changeAllAppCurrenciesSettings(selectedCurrency);
    }
    dispatch(actionHideDialogConfirm());
  };

  useEffect(() => {
    if (!ticketBets.length) {
      dispatch(actionSetDisabledChangeCurrency(false));
    }
  }, [ticketBets]);

  const onChangeCurrencyDialog = (currencyId: number) => {
    setSelectedCurrency(currencyId);
  };

  const applyChangeCurrency = () => {
    changeAllAppCurrenciesSettings(selectedCurrency);
    dispatch(actionSetSessionConfirmChangeCurrency(false));
  };

  return {
    onChangeCurrency,
    selectedCurrencyId,
    selectedCurrency,
    currencyList,
    disabledChangeCurrency,
    onConfirmChangeCurrency,
    openDialog,
    onChangeCurrencyDialog,
    applyChangeCurrency,
  };
};
