import { amber, green, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const dollarTheme = createTheme({
  palette: {
    primary: {
      main: green[800],
    },
    secondary: {
      main: red[700],
    },
  },
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),
  },
});

export const bolivarTheme = createTheme({
  ...dollarTheme,
  palette: {
    primary: {
      main: red[800],
    },
    secondary: {
      main: amber[800],
    },
  },
});

export const ticketTheme = createTheme({
  ...dollarTheme,
  typography: {
    fontFamily: ['courier-prime', 'monospace'].join(','),
  },
});
