import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAnimalDetail } from '@models/Animal.models';

interface IAnimalState {
  animals: IAnimalDetail[];
  selectedAnimals: IAnimalDetail[];
}

const initialState: IAnimalState = {
  animals: [],
  selectedAnimals: [],
};

export const animalsSlice = createSlice({
  name: 'animals',
  initialState,
  reducers: {
    actionCleanAnimalsState: () => {
      return initialState;
    },
    actionSelectAnimal: (state, action: PayloadAction<IAnimalDetail>) => {
      state.selectedAnimals = [...state.selectedAnimals, action.payload];
    },
    actionSelectMassiveAnimals: (state, action: PayloadAction<IAnimalDetail[]>) => {
      state.selectedAnimals = state.selectedAnimals.concat(action.payload);
    },
    actionDeselectAnimal: (state, action: PayloadAction<IAnimalDetail[]>) => {
      state.selectedAnimals = action.payload;
    },
    actionSetAnimals: (state, action: PayloadAction<IAnimalDetail[]>) => {
      state.animals = action.payload;
    },
  },
});

export const {
  actionSelectAnimal,
  actionDeselectAnimal,
  actionSetAnimals,
  actionCleanAnimalsState,
  actionSelectMassiveAnimals,
} = animalsSlice.actions;

export const animalsReducer = animalsSlice.reducer;
