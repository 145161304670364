import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const chance3RaffleSelector = createSelector(
  (state: RootState) => state.chance3Raffles.chance3Raffles,
  (chance3Raffles) => {
    return chance3Raffles;
  },
);

export const chance3RaffleMaxBet3Selector = createSelector(
  (state: RootState) => state.chance3Raffles.chance3RaffleMaxBet3,
  (chance3RaffleMaxBet3) => {
    return chance3RaffleMaxBet3;
  },
);

export const chance3RaffleMaxBet2Selector = createSelector(
  (state: RootState) => state.chance3Raffles.chance3RaffleMaxBet2,
  (chance3RaffleMaxBet2) => {
    return chance3RaffleMaxBet2;
  },
);

export const chance3RaffleIsRaffleSelectedSelector = createSelector(
  (state: RootState) => state.chance3Raffles.isRaffleSelected,
  (isRaffleSelected) => {
    return isRaffleSelected;
  },
);

export const chance3RaffleSelectedRaffleMaxBet3Selector = createSelector(
  (state: RootState) => state.chance3Raffles.selectedChance3RaffleMaxBet3,
  (selectedChance3RaffleMaxBet3) => {
    return selectedChance3RaffleMaxBet3;
  },
);

export const chance3RaffleSelectedRaffleMaxBet2Selector = createSelector(
  (state: RootState) => state.chance3Raffles.selectedChance3RaffleMaxBet2,
  (selectedChance3RaffleMaxBet2) => {
    return selectedChance3RaffleMaxBet2;
  },
);
