import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppSelector } from '@app/hooks';
import { setToken } from '@helpers/localstorage.helper';
import { useAuth } from 'oidc-react';
import { isLoadingSelector } from '@selectors/login.selector';

export const useLogin = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const isLoading = useAppSelector(isLoadingSelector);

  useEffect(() => {
    auth.userManager.getUser().then((asyncToken) => {
      if (asyncToken?.access_token != undefined && !asyncToken.expired) {
        setToken(asyncToken.access_token);
        navigate('/');
      }
    });
  }, [auth]);

  return {
    isLoading,
  };
};
