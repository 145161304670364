import React, { useState } from 'react';
import Box from '@mui/material/Box';

import CurrencyList from '@components/CurrencyList/CurrencyList';
import ConfirmChangeCurrency from '@components/ConfirmChangeCurrency/ConfirmChangeCurrency';

// @hooks
import { useCurrency } from '@features/Currency/Currency.hook';

function Currency() {
  const [currencyToChange, setCurrencyToChange] = useState(0);

  const {
    onChangeCurrency,
    selectedCurrencyId,
    currencyList,
    onConfirmChangeCurrency,
    openDialog,
  } = useCurrency();

  const _onChangeCurrency = (currencyId: number) => {
    setCurrencyToChange(currencyId);
    onChangeCurrency(currencyId);
  };

  return (
    <Box>
      <CurrencyList
        currencySettingsList={currencyList}
        selectedCurrentSetting={selectedCurrencyId}
        onSelectCurrency={_onChangeCurrency}
      />
      <ConfirmChangeCurrency
        open={openDialog}
        selectedCurrencyId={selectedCurrencyId}
        currencyToChangeId={currencyToChange}
        onConfirmChangeCurrency={onConfirmChangeCurrency}
      />
    </Box>
  );
}

export default Currency;
