import { Currency } from '@models/currency.models';

export const usdCurrency: Currency = {
  id: 1,
  name: 'Dólar',
  code: 'USD',
};

export const bolivarCurrency: Currency = {
  id: 2,
  name: 'Bolívar',
  code: 'VES',
};
