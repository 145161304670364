import { IRaffleSetting } from '@models/Raffle.models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Currency } from '@models/currency.models';
import { bolivarCurrency, usdCurrency } from '@constants/currency.constants';
import { ChanceSettings } from '@models/chance.models';

export interface CurrencyState {
  selectedCurrencyId: number;
  currencyList: Currency[];
  raffleSettings: IRaffleSetting;
  selectedChance3DigitsSetting: ChanceSettings;
  activeChance3DigitsSettings: {
    settings3Digits: ChanceSettings[];
    settings2Digits: ChanceSettings[];
  };
  selectedChanceZodiacSetting: ChanceSettings;
  activeChanceZodiacSettings: {
    settings3Digits: ChanceSettings[];
    settings2Digits: ChanceSettings[];
  };
  selectedChance4DigitsSetting: ChanceSettings;
  activeChance4DigitsSettings: {
    settings4Digits: ChanceSettings[];
  };
  changeCurrencyOpenDialog: boolean;
  disabledChangeCurrency: boolean;
  openDialog: boolean;
  shouldAskBeforeChangeCurrency: boolean;
  showSessionConfirmChangeCurrency: boolean;
}

export const initialState: CurrencyState = {
  currencyList: [usdCurrency, bolivarCurrency],
  selectedCurrencyId: Number(sessionStorage.getItem('selectedCurrency') ?? 1),
  raffleSettings: {} as IRaffleSetting,
  selectedChance3DigitsSetting: {} as ChanceSettings,
  activeChance3DigitsSettings: {
    settings3Digits: [],
    settings2Digits: [],
  },
  selectedChanceZodiacSetting: {} as ChanceSettings,
  activeChanceZodiacSettings: {
    settings3Digits: [],
    settings2Digits: [],
  },
  selectedChance4DigitsSetting: {} as ChanceSettings,
  activeChance4DigitsSettings: {
    settings4Digits: [],
  },
  changeCurrencyOpenDialog: false,
  disabledChangeCurrency: false,
  openDialog: false,
  shouldAskBeforeChangeCurrency: false,
  showSessionConfirmChangeCurrency: !sessionStorage.getItem('selectedCurrency'),
};

export const currencySlice = createSlice({
  name: 'animalCurrency',
  initialState,
  reducers: {
    actionSetSelectedLotterySettings: (state, action) => {
      state.raffleSettings = action.payload;
    },
    actionSetAnimalCurrencySetting: (state, action) => {
      state.raffleSettings = action.payload;
    },
    actionSetChangeCurrencyOpenDialog: (state, action) => {
      state.changeCurrencyOpenDialog = action.payload;
    },
    actionSetDisabledChangeCurrency: (state, action) => {
      state.disabledChangeCurrency = action.payload;
    },
    actionShowDialogConfirm: (state) => {
      state.openDialog = true;
    },
    actionHideDialogConfirm: (state) => {
      state.openDialog = false;
      state.shouldAskBeforeChangeCurrency = false;
    },
    actionSetSelectedCurrencyId: (state, action) => {
      state.selectedCurrencyId = action.payload;
    },
    actionSetSelectedChance3DigitsSetting: (state, action) => {
      state.selectedChance3DigitsSetting = action.payload;
    },
    actionSetActiveChance3DigitsSettings: (state, action) => {
      state.activeChance3DigitsSettings = action.payload;
    },
    actionSetSelectedChanceZodiacSetting: (state, action) => {
      state.selectedChanceZodiacSetting = action.payload;
    },
    actionSetActiveChanceZodiacSettings: (state, action) => {
      state.activeChanceZodiacSettings = action.payload;
    },
    actionSetSelectedChance4DigitsSetting: (state, action) => {
      state.selectedChance4DigitsSetting = action.payload;
    },
    actionSetActiveChance4DigitsSettings: (state, action) => {
      state.activeChance4DigitsSettings = action.payload;
    },
    actionSetSessionConfirmChangeCurrency: (state, action: PayloadAction<boolean>) => {
      state.showSessionConfirmChangeCurrency = action.payload;
    },
  },
});

export const {
  actionSetAnimalCurrencySetting,
  actionSetSelectedLotterySettings,
  actionSetChangeCurrencyOpenDialog,
  actionSetDisabledChangeCurrency,
  actionShowDialogConfirm,
  actionHideDialogConfirm,
  actionSetSelectedCurrencyId,
  actionSetActiveChance3DigitsSettings,
  actionSetSelectedChance3DigitsSetting,
  actionSetActiveChanceZodiacSettings,
  actionSetSelectedChanceZodiacSetting,
  actionSetActiveChance4DigitsSettings,
  actionSetSelectedChance4DigitsSetting,
  actionSetSessionConfirmChangeCurrency,
} = currencySlice.actions;

export const currencyReducer = currencySlice.reducer;
