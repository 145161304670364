import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chance3BetArray } from '@models/Chance3Digits.models';

interface IChancePayloadAction {
  swapBet: string[];
  betAmount: number;
}

const initialState: Chance3BetArray = {
  arrayNumbers: [],
  betAmount: 0,
};
export const swapBetSlice = createSlice({
  name: 'swapBet',
  initialState,
  reducers: {
    actionSetSwapArray: (state, action: PayloadAction<IChancePayloadAction>) => {
      state.arrayNumbers = action.payload.swapBet;
      state.betAmount = action.payload.betAmount;
    },
    actionCleanSwapState: (state) => {
      state.arrayNumbers = [];
      state.betAmount = 0;
    },
  },
});

export const { actionSetSwapArray } = swapBetSlice.actions;

export const swapBetReducer = swapBetSlice.reducer;
