import { useAppDispatch, useAppSelector } from '@app/hooks';
import { batch } from 'react-redux';
import { actionSetSelectedChance4DigitsSetting } from '@features/Currency/Currency.slice';
import { actionCleanTicketState } from '@features/Ticket/TicketSlice';
import { useChance4Raffles } from '@features/Chance4Digits/Chance4Raffles/Chance4Raffle.hook';
import { actionCleanChance4RaffleState } from '@features/Chance4Digits/Chance4Raffles/Chance4Raffle.slice';
import {
  chance4RaffleMaxBet2Selector,
  chance4RaffleMaxBet3Selector,
  chance4RaffleMaxBet4Selector,
} from '@selectors/chance4Raffles.selector';

export const useChance4DigitsCurrency = () => {
  const dispatch = useAppDispatch();
  const { setChanceMaxBetBy } = useChance4Raffles();

  const chance4RaffleMaxBet4 = useAppSelector(chance4RaffleMaxBet4Selector);
  const chance4RaffleMaxBet3 = useAppSelector(chance4RaffleMaxBet3Selector);
  const chance4RaffleMaxBet2 = useAppSelector(chance4RaffleMaxBet2Selector);

  const onSelectChanceSetting = () => {
    batch(() => {
      dispatch(actionCleanChance4RaffleState());
      setChanceMaxBetBy();
      dispatch(actionSetSelectedChance4DigitsSetting({}));
      dispatch(actionCleanTicketState());
    });
  };

  const shouldAskBeforeChangeCurrency = () => {
    const raffleMaxBet4Selected = chance4RaffleMaxBet4.find(
      (raffle) => raffle.chanceFourRaffleSelected,
    );
    const raffleMaxBet3Selected = chance4RaffleMaxBet3.find(
      (raffle) => raffle.chanceFourRaffleSelected,
    );
    const raffleMaxBet2Selected = chance4RaffleMaxBet2.find(
      (raffle) => raffle.chanceFourRaffleSelected,
    );

    return raffleMaxBet4Selected || raffleMaxBet3Selected || raffleMaxBet2Selected;
  };

  return {
    shouldAskBeforeChangeCurrency,
    onSelectChanceSetting,
  };
};
