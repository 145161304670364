import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const selectedLotterySelector = createSelector(
  (state: RootState) => state.lottery.selectedLottery,
  (selectedLottery) => {
    return selectedLottery;
  },
);

export const selectedLotteryNameSelector = createSelector(
  (state: RootState) => state.lottery.selectedLotteryName,
  (selectedLotteryName) => {
    return selectedLotteryName;
  },
);

export const selectedActiveLotterySelector = createSelector(
  (state: RootState) => state.lottery.selectedActiveLottery,
  (selectedActiveLottery) => {
    return selectedActiveLottery;
  },
);

export const selectedLotteryFruitCombinedSelector = createSelector(
  (state: RootState) => state.lottery.selectedLotteryFruitCombined,
  (selectedLotteryFruitCombined) => {
    return selectedLotteryFruitCombined;
  },
);
