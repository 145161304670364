import { useRaffles } from './Raffles.hook';
import Typography from '@mui/material/Typography';

import RaffleList from '@components/RaffleList/RaffleList';
import { ConditionalRendering } from '@components/ConditionalRendering/ConditionalRendering';
import { Input } from '@mui/material';
import Box from '@mui/material/Box';
import { isHotkeyPressed } from 'react-hotkeys-hook';
import { SEARCH_RAFFLES_SHORTCUT } from '@constants/shortcuts.constants';

export function Raffles() {
  const {
    activeRaffles,
    selectRaffle,
    selectedRaffles,
    filterActiveRafflesBySearchInput,
    setSearchRaffleName,
    searchRaffleName,
    searchRaffleRef,
  } = useRaffles();

  const _onSearchRaffleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isHotkeyPressed(SEARCH_RAFFLES_SHORTCUT.split('+'))) {
      setSearchRaffleName(event.target.value);
    }
  };

  const _onKeyUpSearchRaffleName = () => {
    filterActiveRafflesBySearchInput();
  };

  return (
    <Box pl={2}>
      <Typography variant='h6'>Sorteos Activos</Typography>
      <Input
        sx={{ marginTop: 1, marginBottom: 1 }}
        placeholder='Buscar sorteo'
        onChange={_onSearchRaffleName}
        onKeyUp={_onKeyUpSearchRaffleName}
        value={searchRaffleName}
        inputRef={searchRaffleRef}
        tabIndex={0}
      />
      <ConditionalRendering isTrue={activeRaffles.length > 0}>
        <RaffleList
          raffles={activeRaffles}
          onClickRaffle={selectRaffle}
          selectedRaffles={selectedRaffles}
        />
      </ConditionalRendering>
    </Box>
  );
}
