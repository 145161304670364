import { useAppDispatch, useAppSelector } from '@app/hooks';

import { actionSetBet, actionSetChance3BetTicketInfo } from '@features/Ticket/TicketSlice';

import {
  createBetFromCurrentChanceBetTicket,
  deleteLotteryFromTicket,
  deleteRaffleFromTicket,
} from '@helpers/chance3digits.helpers';
import { useEffect, useState } from 'react';
import { amountIsExceedingMaxValue, amountIsNotValid } from '@helpers/animalBet.helpers';
import { ITicketChance3Digits } from '@models/Chance3Digits.models';
import { CHANCE_3_DIGITS_RAFFLES_ID } from '@constants/app.constants';
import { IBet } from '@models/Bet.models';
import { chance3BetTicketInfoSelector, ticketBetsSelector } from '@selectors/ticket.selector';
import { chance3digitsSelector } from '@selectors/chance3digits.selector';
import { selectedCurrencyIdSelector } from '@selectors/animalcurrency.selector';

export const useChance3DigitsTicket = () => {
  const dispatch = useAppDispatch();

  const ticketBets = useAppSelector(ticketBetsSelector);
  const chance3BetTicketInfo = useAppSelector(chance3BetTicketInfoSelector);
  const chance3DigitsLotteries = useAppSelector(chance3digitsSelector);
  const selectedCurrencyId = useAppSelector(selectedCurrencyIdSelector);

  const [formHasErrors, setFormHasErrors] = useState(false);

  const deleteLottery = (lotteryId: number) => {
    dispatch(
      actionSetChance3BetTicketInfo(deleteLotteryFromTicket(chance3BetTicketInfo, lotteryId)),
    );
  };

  const deleteLotteryRaffle = (lotteryId: number, raffleId: number) => {
    dispatch(
      actionSetChance3BetTicketInfo(
        deleteRaffleFromTicket(chance3BetTicketInfo, lotteryId, raffleId),
      ),
    );
  };

  useEffect(() => {
    let copyNewChanceBets: IBet[] = ticketBets.filter(
      (bet) => bet.gameTypeId !== CHANCE_3_DIGITS_RAFFLES_ID,
    );

    if (chance3BetTicketInfo.length > 0) {
      const builtChance3DigitsTicketBets =
        createBetFromCurrentChanceBetTicket(chance3BetTicketInfo);
      copyNewChanceBets = copyNewChanceBets.concat(builtChance3DigitsTicketBets);
    }
    dispatch(actionSetBet(copyNewChanceBets));
  }, [chance3BetTicketInfo]);

  const setEditableRaffle = (indexLottery: number, index: number, finishEdit?: boolean) => {
    const lotteryObject = chance3BetTicketInfo[indexLottery];
    const raffleObject = {
      ...lotteryObject.raffleChance3DigitsBet[index],
      isEditing: !finishEdit,
    };

    const newChanceBetTicketInfo = chance3BetTicketInfo.map((lottery, i) => {
      if (i === indexLottery) {
        return {
          ...lottery,
          raffleChance3DigitsBet: lottery.raffleChance3DigitsBet.map((raffle, j) => {
            if (j === index) {
              return raffleObject;
            }
            return raffle;
          }),
        };
      }
      return lottery;
    });

    dispatch(actionSetChance3BetTicketInfo(newChanceBetTicketInfo));
  };

  const updateChanceBetAmount = (
    indexLottery: number,
    indexRaffle: number,
    indexBet: number,
    amount: string,
  ) => {
    let hasErrorForm = false;
    const newChanceTicketInfo: ITicketChance3Digits[] = JSON.parse(
      JSON.stringify(chance3BetTicketInfo),
    );

    const lotterySetting = chance3DigitsLotteries.find(
      (chanceLotteries) =>
        chanceLotteries.chanceThreeLotteryId === newChanceTicketInfo[indexLottery].lotteryId,
    );
    const raffleSetting = lotterySetting?.chanceLotterySettings.find(
      (raffleSetting) => raffleSetting.currencyId === selectedCurrencyId,
    );

    if (raffleSetting) {
      const exceedMaxAmount = amountIsExceedingMaxValue(
        raffleSetting.maxBetByChance,
        Number(amount),
      );
      const isAmountNotValid = amountIsNotValid(amount);

      let errorMessage = '';
      if (exceedMaxAmount) {
        hasErrorForm = true;
        errorMessage = `Monto máximo es de ${raffleSetting.maxBetByChance}`;
      } else if (Number(amount) <= 0) {
        hasErrorForm = true;
        errorMessage = 'Monto debe ser mayor a 0';
      } else if (isAmountNotValid) {
        hasErrorForm = true;
        errorMessage = 'El número no es válido';
      }

      newChanceTicketInfo[indexLottery].raffleChance3DigitsBet[indexRaffle].chance3DigitsBet[
        indexBet
      ] = {
        ...chance3BetTicketInfo[indexLottery].raffleChance3DigitsBet[indexRaffle].chance3DigitsBet[
          indexBet
        ],
        betAmount: amount,
        error: errorMessage,
        hasError: hasErrorForm,
      };

      if (hasErrorForm) {
        setFormHasErrors(true);
      } else {
        setFormHasErrors(false);
      }

      newChanceTicketInfo[indexLottery].totalBet = newChanceTicketInfo[
        indexLottery
      ].raffleChance3DigitsBet.reduce(
        (acc, chance3Digits) =>
          chance3Digits.chance3DigitsBet.reduce((acc2, bet) => acc2 + Number(bet.betAmount), 0),
        0,
      );

      dispatch(actionSetChance3BetTicketInfo(newChanceTicketInfo));
    }
  };

  return {
    chance3BetTicketInfo,
    deleteLottery,
    deleteLotteryRaffle,
    setEditableRaffle,
    updateChanceBetAmount,
    formHasErrors,
  };
};
