import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { AnimalsView } from '@views/AnimalsView/AnimalsView';
import { useActiveAnimalsByLottery } from './AnimalLotteryController.hook';
import { ConditionalRendering } from '@components/ConditionalRendering/ConditionalRendering';

function AnimalController() {
  const {
    isLoading,
    selectedLottery,
    selectedAnimalTypeGame,
    currentSelectedCurrency,
    selectedRaffles,
  } = useActiveAnimalsByLottery();
  return (
    <>
      <ConditionalRendering isTrue={isLoading}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress size='6rem' />
        </Box>
      </ConditionalRendering>
      <ConditionalRendering isTrue={!isLoading}>
        <AnimalsView
          showAnimalGame={!!selectedLottery || !!selectedAnimalTypeGame}
          currentSelectedCurrency={currentSelectedCurrency}
          selectedRaffles={selectedRaffles}
        />
      </ConditionalRendering>
    </>
  );
}

export default AnimalController;
