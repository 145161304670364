import { ConditionalRendering } from '@components/ConditionalRendering/ConditionalRendering';
import { useAnimalsState } from '@features/AnimalsGame/Animals/Animals.hook';
import AnimalList from '@components/AnimalList/AnimalList';
import FruitList from '@components/AnimalList/FruitList';
import { Input } from '@mui/material';
import { ANIMAL_GAME_TYPE_LABEL } from '@constants/app.constants';
import { isHotkeyPressed } from 'react-hotkeys-hook';
import { SEARCH_ANIMALS_SHORTCUT } from '@constants/shortcuts.constants';

export function Animals() {
  const {
    animals,
    selectedAnimals,
    selectAnimalAction,
    canSelectANewAnimal,
    disableAnimalsCard,
    disableFruitsCard,
    selectedLotteryFruitCombined,
    animalId,
    fruitId,
    selectedAnimalBet,
    selectedAnimalTypeGame,
    searchAnimalFruitRef,
    searchAnimalFruitInput,
    searchAnimalFruit,
    filterAnimalsFruitBySearchInput,
    massiveSelectAnimals,
    selectedRaffles,
  } = useAnimalsState();

  const _onSearchAnimalFruit = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isHotkeyPressed(SEARCH_ANIMALS_SHORTCUT.split('+'))) {
      searchAnimalFruitInput(event.target.value);
    }
  };

  const _onKeyUpSearchAnimalFruit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      massiveSelectAnimals();
    } else {
      filterAnimalsFruitBySearchInput();
    }
  };

  return (
    <>
      <ConditionalRendering isTrue={selectedRaffles.length > 0}>
        <Input
          id={'search-animal-input'}
          placeholder={`Buscar por ${ANIMAL_GAME_TYPE_LABEL.get(selectedAnimalTypeGame)}`}
          sx={{ marginTop: 3, marginBottom: 3, width: '50%' }}
          inputRef={searchAnimalFruitRef}
          value={searchAnimalFruit}
          onChange={_onSearchAnimalFruit}
          onKeyUp={_onKeyUpSearchAnimalFruit}
          tabIndex={1}
        />
      </ConditionalRendering>
      <ConditionalRendering isTrue={animals.length > 0}>
        {!selectedLotteryFruitCombined && (
          <AnimalList
            animals={animals}
            selectedAnimals={selectedAnimals}
            selectAnimalAction={selectAnimalAction}
            canSelectANewAnimal={canSelectANewAnimal}
          />
        )}
        {selectedLotteryFruitCombined && (
          <FruitList
            animals={animals}
            selectedAnimals={selectedAnimals}
            selectAnimalAction={selectAnimalAction}
            canSelectANewAnimal={canSelectANewAnimal}
            disableAnimalsCard={disableAnimalsCard}
            disableFruitsCard={disableFruitsCard}
            selectedLotteryFruitCombined={selectedLotteryFruitCombined}
            animalId={animalId}
            fruitId={fruitId}
            selectedAnimalBet={selectedAnimalBet}
          />
        )}
      </ConditionalRendering>
    </>
  );
}
