import { useAppDispatch, useAppSelector } from '@app/hooks';
import { actionCleanAnimalsState } from '@features/AnimalsGame/Animals/AnimalsSlice';
import { actionCleanAnimalsBetState } from '@features/AnimalsGame/AnimalsBet/AnimalsBetSlice';
import { actionCleanRafflesState } from '@features/AnimalsGame/Raffles/RaffleSlice';
import { batch } from 'react-redux';

import {
  changeCurrencyOpenDialogSelector,
  disabledChangeCurrencySelector,
  raffleSettingsSelector,
} from '@selectors/animalcurrency.selector';
import { selectedAnimalsSelector } from '@selectors/animals.selector';

export const useAnimalCurrency = () => {
  const dispatch = useAppDispatch();

  const disabledChangeCurrency = useAppSelector(disabledChangeCurrencySelector);
  const animalCurrencySetting = useAppSelector(raffleSettingsSelector);
  const changeCurrencyOpenDialog = useAppSelector(changeCurrencyOpenDialogSelector);
  const selectedAnimals = useAppSelector(selectedAnimalsSelector);

  const onSelectLotterySettings = () => {
    batch(() => {
      dispatch(actionCleanAnimalsState());
      dispatch(actionCleanAnimalsBetState());
      dispatch(actionCleanRafflesState());
    });
  };

  const shouldAskBeforeChangeCurrency = () => selectedAnimals.length > 0;

  return {
    shouldAskBeforeChangeCurrency,
    animalCurrencySetting,
    onSelectLotterySettings,
    changeCurrencyOpenDialog,
    disabledChangeCurrency,
  };
};
