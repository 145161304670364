import { useAppDispatch, useAppSelector } from '@app/hooks';
import { getActiveAnimalByLotteryAsync } from './AnimalsLotterySlice';
import {
  animalsLotteryLoadingSelector,
  animalsLotteryStatusSelector,
} from '@selectors/animalsLottery.selector';
import { selectedLotterySelector } from '@selectors/lottery.selector';
import { selectedAnimalTypeGameSelector } from '@selectors/animalTypeGame.selector';
import { selectedRafflesSelector } from '@selectors/raffle.selector';
import { sessionConfirmChangeCurrencySelector } from '@selectors/animalcurrency.selector';

export const useActiveAnimalsByLottery = () => {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(animalsLotteryLoadingSelector);
  const status = useAppSelector(animalsLotteryStatusSelector);
  const selectedLottery = useAppSelector(selectedLotterySelector);
  const selectedAnimalTypeGame = useAppSelector(selectedAnimalTypeGameSelector);
  const selectedRaffles = useAppSelector(selectedRafflesSelector);
  const currentSelectedCurrency = useAppSelector(sessionConfirmChangeCurrencySelector);

  if (status === 'idle' && !isLoading) {
    dispatch(getActiveAnimalByLotteryAsync());
  }

  return {
    selectedLottery,
    isLoading,
    selectedAnimalTypeGame,
    currentSelectedCurrency,
    selectedRaffles,
  };
};
