import React from 'react';
import Box from '@mui/material/Box';

import styles from './LoginView.module.scss';
import Login from '@features/Login/Login';

function LoginView() {
  return (
    <Box className={styles.LoginView}>
      <Box component='form' autoComplete='off' className={styles.Form}>
        <Login />
      </Box>
    </Box>
  );
}

export default LoginView;
