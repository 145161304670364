import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const chanceZodiacLotteriesSelector = createSelector(
  (state: RootState) => state.chanceZodiac.chanceZodiacLotteries,
  (chanceZodiacLotteries) => {
    return chanceZodiacLotteries;
  },
);

export const chanceZodiacLotteriesLoadingSelector = createSelector(
  (state: RootState) => state.chanceZodiac.isLoading,
  (isLoading) => {
    return isLoading;
  },
);

export const chanceZodiacLotteriesStatusSelector = createSelector(
  (state: RootState) => state.chanceZodiac.status,
  (status) => {
    return status;
  },
);
