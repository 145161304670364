import { Chance4DigitsLottery } from '@models/Chance4Digits.models';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getActiveChanceFourRafflesCurrentDate } from '@controllers/Chance4Digits/Chance4Digits.api';

interface Chance4DigitsState {
  chance4DigitsLotteries: Chance4DigitsLottery[];
  isLoading: boolean;
  status: 'idle' | 'failed' | 'success';
}

const initialState: Chance4DigitsState = {
  chance4DigitsLotteries: [],
  isLoading: false,
  status: 'idle',
};

export const getChance4DigitsLotteriesAsync = createAsyncThunk(
  'chance4Digits/getChance4DigitsLotteriesAsync',
  async (filter: number | void) => {
    const response = await getActiveChanceFourRafflesCurrentDate(filter);
    return response as Chance4DigitsLottery[];
  },
);

export const chance4DigitsSlice = createSlice({
  name: 'chance4Digits',
  initialState,
  reducers: {
    actionSetChance4DigitsLotteries: (state, action) => {
      state.chance4DigitsLotteries = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChance4DigitsLotteriesAsync.pending, (state) => {
        state.chance4DigitsLotteries = [];
        state.isLoading = true;
      })
      .addCase(getChance4DigitsLotteriesAsync.fulfilled, (state, action) => {
        state.chance4DigitsLotteries = action.payload;
        state.isLoading = false;
        state.status = 'success';
      })
      .addCase(getChance4DigitsLotteriesAsync.rejected, (state) => {
        state.isLoading = false;
        state.status = 'failed';
        state.chance4DigitsLotteries = [];
      });
  },
});

export const { actionSetChance4DigitsLotteries } = chance4DigitsSlice.actions;

export const chance4DigitsReducer = chance4DigitsSlice.reducer;
