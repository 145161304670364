import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// @models
import { IAnimalBet } from '@models/Animal.models';

// @styles
import styles from './AnimalFormBet.module.scss';
import { IRaffleSetting } from '@models/Raffle.models';
import { ConditionalRendering } from '@components/ConditionalRendering/ConditionalRendering';
import InputNumber from '@components/InputNumber/InputNumber';
import SubmitButton from '@components/SubmitButton/SubmitButton';
import { ANIMAL_TRIPLETA_GAME_ID } from '@constants/app.constants';
import { IconButton, SvgIcon } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface AnimalFormBetProps {
  selectedLotterySetting: IRaffleSetting;
  selectedAnimalsBet: IAnimalBet[];
  onChangeAnimalBet: (animalId: string, animalBet: string) => void;
  onBetToTicket: () => void;
  onChangeBetEveryAnimal: (animalBet: string) => void;
  disabledAddToTicketButton: boolean;
  massiveBetValue: number | string;
  isLoading: boolean;
  selectedGameType: number;
  deleteCombinedLotteryBet: (animalId: string) => void;
}

function AnimalFormBet({
  isLoading,
  selectedLotterySetting,
  selectedAnimalsBet,
  onChangeAnimalBet,
  onBetToTicket,
  onChangeBetEveryAnimal,
  disabledAddToTicketButton,
  massiveBetValue,
  selectedGameType,
  deleteCombinedLotteryBet,
}: AnimalFormBetProps) {
  const animalInputCustomClass = 'set-animal-bet';
  const renderAnimalBet = () => {
    return selectedAnimalsBet.map((animalBet) => (
      <Grid
        className={styles.AnimalForm}
        sx={{
          display: 'flex',
          alignItems: animalBet.hasError ? 'center' : 'flex-end',
          columnGap: 0.5,
        }}
        key={`${animalBet.animalId}-${animalBet.animalName}`}
        item
        xs={12}
      >
        <label className={styles.AnimalLabel}>
          <strong>{animalBet.animalName}</strong>
        </label>
        <InputNumber
          isAmount={true}
          error={animalBet.hasError}
          sx={{ width: '90%' }}
          className={animalInputCustomClass}
          inputProps={{
            sx: { textAlign: 'right', width: '100%', paddingRight: 1 },
            className: animalInputCustomClass,
          }}
          onChange={(value) => onChangeAnimalBet(animalBet.animalId, value)}
          variant='standard'
          name={animalBet.animalName}
          value={animalBet.betAmount}
          onPressBetShortcut={onBetToTicket}
          helperText={
            animalBet.hasError
              ? Number(animalBet.betAmount) <= 0
                ? 'Monto de apuesta debe ser mayor a 0'
                : `Monto máximo es de ${selectedLotterySetting.maxBetByAnimal}`
              : ''
          }
        />
        {selectedGameType === 2 && (
          <IconButton onClick={() => deleteCombinedLotteryBet(`${animalBet.animalId}`)}>
            <SvgIcon component={DeleteIcon} />
          </IconButton>
        )}
      </Grid>
    ));
  };

  return (
    <form className={styles.AnimalForm} autoComplete='off'>
      <Typography sx={{ marginBottom: 2 }} component='p' variant='h4'>
        Apuesta
      </Typography>
      <ConditionalRendering isTrue={selectedAnimalsBet.length > 1}>
        <Box sx={{ display: 'flex', columnGap: 1, alignItems: 'center' }}>
          <ConditionalRendering isTrue={selectedGameType === ANIMAL_TRIPLETA_GAME_ID}>
            <Typography sx={{ flex: 1 }} component='div' variant='body1'>
              <strong>Apuesta por tripleta</strong>
            </Typography>
            <InputNumber
              isAmount
              inputProps={{
                className: animalInputCustomClass,
              }}
              sx={{ width: '60%' }}
              value={massiveBetValue}
              variant='standard'
              name='betEveryAnimal'
              error={selectedAnimalsBet[0].hasError}
              onChange={(value) => onChangeBetEveryAnimal(value)}
              onPressBetShortcut={onBetToTicket}
              helperText={
                selectedAnimalsBet[0].hasError
                  ? Number(selectedAnimalsBet[0].betAmount) <= 0
                    ? 'Monto de apuesta debe ser mayor a 0'
                    : `Monto máximo es de ${selectedLotterySetting.maxBetByAnimal}`
                  : ''
              }
            />
          </ConditionalRendering>
          <ConditionalRendering isTrue={selectedGameType !== ANIMAL_TRIPLETA_GAME_ID}>
            <Typography sx={{ flex: 1 }} component='div' variant='body1'>
              <strong>Apuesta Masiva</strong>
            </Typography>
            <InputNumber
              isAmount
              sx={{ width: '50%' }}
              value={massiveBetValue}
              inputProps={{
                className: animalInputCustomClass,
              }}
              variant='standard'
              name='betEveryAnimal'
              onChange={(value) => onChangeBetEveryAnimal(value)}
              onPressBetShortcut={onBetToTicket}
            />
          </ConditionalRendering>
        </Box>
      </ConditionalRendering>
      <ConditionalRendering isTrue={selectedGameType !== ANIMAL_TRIPLETA_GAME_ID}>
        <Grid sx={{ marginTop: 1 }} container spacing={2}>
          {renderAnimalBet()}
        </Grid>
      </ConditionalRendering>
      <SubmitButton
        disabled={disabledAddToTicketButton}
        sx={{ marginTop: 2, float: 'right' }}
        variant='contained'
        isLoading={isLoading}
        onClick={onBetToTicket}
      />
    </form>
  );
}

export default AnimalFormBet;
