import { AxiosError, AxiosResponse } from 'axios';
import httpCommon from '@config/http-common';
import { ChanceZodiacLottery, ChanceZodiacResponse } from '@models/ChanceZodiac.models';

export const getActiveChanceZodiacByRafflesCurrentDate = async (filter: number | void) => {
  try {
    const response: AxiosResponse<ChanceZodiacResponse<ChanceZodiacLottery[]>> =
      await httpCommon.get(
        `ChanceZodiacRaffle/get-active-chance-zodiac-raffles-for-current-date${
          filter ? '/' + filter : ''
        }`,
      );
    return response?.data?.response || [];
  } catch (error) {
    if (error instanceof AxiosError) {
      return error.response?.data?.message || 'unknown error';
    }
    return 'unknown error';
  }
};
