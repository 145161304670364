import { IAnimalBet } from '@models/Animal.models';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorValidateBet, IBetTicket, IBetTicketResponse } from '@models/Bet.models';
import { validateBetApi } from '@features/AnimalsGame/AnimalsBet/AnimalBet.api';

interface AnimalsBetState {
  selectedAnimalBet: IAnimalBet[];
  status: 'success' | 'failed' | 'idle';
  isLoading: boolean;
  isAlReadyFetched: boolean;
  betErrors: ErrorValidateBet[];
}

const initialState: AnimalsBetState = {
  selectedAnimalBet: [],
  status: 'idle',
  isLoading: false,
  betErrors: [],
  isAlReadyFetched: false,
};

export const validateBetAsync = createAsyncThunk(
  'animalsBet/validateBetAsync',
  async (betData: IBetTicket) => {
    const response = await validateBetApi(betData);
    return response as IBetTicketResponse;
  },
);

export const animalsBetSlice = createSlice({
  name: 'animalsBet',
  initialState,
  reducers: {
    actionCleanAnimalsBetState: () => {
      return initialState;
    },
    actionSetSelectedAnimalBet: (state, action: PayloadAction<IAnimalBet[]>) => {
      state.selectedAnimalBet = action.payload;
    },
    actionSetNewAnimalBetAmount: (state, action: PayloadAction<IAnimalBet[]>) => {
      state.selectedAnimalBet = action.payload;
    },
    actionSetClearAnimalBet: (state, action: PayloadAction<string>) => {
      // Find the index of the bet to remove
      const indexToRemove = state.selectedAnimalBet.findIndex(
        (bet) => bet.animalId === action.payload,
      );

      // If the bet is found, remove it from the array
      if (indexToRemove !== -1) {
        state.selectedAnimalBet.splice(indexToRemove, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateBetAsync.pending, (state) => {
        state.isLoading = true;
        state.isAlReadyFetched = false;
        state.betErrors = [];
      })
      .addCase(validateBetAsync.fulfilled, (state, action: PayloadAction<IBetTicketResponse>) => {
        state.isLoading = false;
        state.status = action.payload.success ? 'success' : 'failed';
        state.isAlReadyFetched = true;
        if (action.payload.success) {
          state.betErrors = [];
        } else if (action.payload.errors) {
          state.betErrors = action.payload.errors;
        }
      })
      .addCase(validateBetAsync.rejected, (state) => {
        state.isLoading = false;
        state.isAlReadyFetched = true;
        state.status = 'failed';
      });
  },
});

export const {
  actionSetSelectedAnimalBet,
  actionSetNewAnimalBetAmount,
  actionCleanAnimalsBetState,
  actionSetClearAnimalBet,
} = animalsBetSlice.actions;

export const animalsBetReducer = animalsBetSlice.reducer;
