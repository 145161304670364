import { Chance4DigitsRaffle } from '@models/Chance4Digits.models';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Chance4RaffleState {
  chance4Raffles: Chance4DigitsRaffle[];
  chance4RaffleMaxBet4: Chance4DigitsRaffle[];
  chance4RaffleMaxBet3: Chance4DigitsRaffle[];
  chance4RaffleMaxBet2: Chance4DigitsRaffle[];
  selectedChance4RaffleMaxBet4: Chance4DigitsRaffle[];
  selectedChance3RaffleMaxBet3: Chance4DigitsRaffle[];
  selectedChance2RaffleMaxBet2: Chance4DigitsRaffle[];
  isRaffleChance4Selected: boolean;
}

const initialState: Chance4RaffleState = {
  chance4Raffles: [],
  chance4RaffleMaxBet4: [],
  chance4RaffleMaxBet3: [],
  chance4RaffleMaxBet2: [],
  selectedChance4RaffleMaxBet4: [],
  selectedChance3RaffleMaxBet3: [],
  selectedChance2RaffleMaxBet2: [],
  isRaffleChance4Selected: false,
};

export const chance4RaffleSlice = createSlice({
  name: 'chance4Raffles',
  initialState,
  reducers: {
    actionSetChance4Raffles: (state, action: PayloadAction<Chance4DigitsRaffle[]>) => {
      state.chance4Raffles = action.payload;
    },
    actionSetChance4RaffleMaxBet4: (state, action: PayloadAction<Chance4DigitsRaffle[]>) => {
      state.chance4RaffleMaxBet4 = action.payload;
    },
    actionSetChance4RaffleMaxBet3: (state, action: PayloadAction<Chance4DigitsRaffle[]>) => {
      state.chance4RaffleMaxBet3 = action.payload;
    },
    actionSetChance4RaffleMaxBet2: (state, action: PayloadAction<Chance4DigitsRaffle[]>) => {
      state.chance4RaffleMaxBet2 = action.payload;
    },
    actionSetChance4MaxBet: (
      state,
      action: PayloadAction<{
        maxBet4?: Chance4DigitsRaffle[];
        maxBet3?: Chance4DigitsRaffle[];
        maxBet2?: Chance4DigitsRaffle[];
      }>,
    ) => {
      state.chance4RaffleMaxBet4 = action.payload.maxBet4 ?? [];
      state.chance4RaffleMaxBet3 = action.payload.maxBet3 ?? [];
      state.chance4RaffleMaxBet2 = action.payload.maxBet2 ?? [];
    },
    actionCleanChance4RaffleState: (state) => {
      state.chance4RaffleMaxBet4 = [];
      state.chance4RaffleMaxBet3 = [];
      state.chance4RaffleMaxBet2 = [];
    },
    actionSetIsRaffleChance4Selected: (state, action: PayloadAction<boolean>) => {
      state.isRaffleChance4Selected = action.payload;
    },
    actionSetSelectedChance4RaffleMaxBet4: (
      state,
      action: PayloadAction<Chance4DigitsRaffle[]>,
    ) => {
      state.selectedChance4RaffleMaxBet4 = action.payload;
    },
    actionSetSelectedChance3RaffleMaxBet3: (
      state,
      action: PayloadAction<Chance4DigitsRaffle[]>,
    ) => {
      state.selectedChance3RaffleMaxBet3 = action.payload;
    },
    actionSetSelectedChance2RaffleMaxBet2: (
      state,
      action: PayloadAction<Chance4DigitsRaffle[]>,
    ) => {
      state.selectedChance2RaffleMaxBet2 = action.payload;
    },
  },
});

export const {
  actionSetChance4Raffles,
  actionSetChance4MaxBet,
  actionSetChance4RaffleMaxBet4,
  actionSetChance4RaffleMaxBet3,
  actionSetChance4RaffleMaxBet2,
  actionCleanChance4RaffleState,
  actionSetIsRaffleChance4Selected,
  actionSetSelectedChance4RaffleMaxBet4,
  actionSetSelectedChance3RaffleMaxBet3,
  actionSetSelectedChance2RaffleMaxBet2,
} = chance4RaffleSlice.actions;

export const chance4RaffleReducer = chance4RaffleSlice.reducer;
