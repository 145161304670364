import { bolivarTheme, dollarTheme } from '../theme/theme';

export const ANIMAL_RAFFLES_ID = 1;
export const CHANCE_3_DIGITS_RAFFLES_ID = 2;
export const CHANCE_ZODIAC_RAFFLES_ID = 3;
export const CHANCE_4_DIGITS_RAFFLES_ID = 4;
export const ANIMAL_TRIPLETA_GAME_ID = 5;
export const ANIMAL_GAME_TYPE_LABEL = new Map([
  [1, 'Animalitos'],
  [2, 'Animalitos + Frutas'],
  [3, 'Frutas'],
  [5, 'Tripleta'],
]);

export const ANIMAL_FRUITS_GAME_TYPE = 2;

export const appThemes = [dollarTheme, bolivarTheme];
