import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { logInAction } from '@features/Login/LoginApi';

interface LoginSliceState {
  isLoggedIn: boolean;
  isLoading: boolean;
  status: 'success' | 'failed' | 'idle';
  hasErrors: boolean;
  errorMessage: string;
}

export const initialState: LoginSliceState = {
  isLoggedIn: false,
  isLoading: false,
  status: 'idle',
  hasErrors: false,
  errorMessage: '',
};

export const loginActionAsync = createAsyncThunk('login/logInAction', async () => {
  const response = await logInAction();
  // The value we return becomes the `fulfilled` action payload
  return response.token;
});

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginActionAsync.pending, (state) => {
      state.isLoading = true;
      state.status = 'idle';
      state.hasErrors = false;
      state.errorMessage = '';
    });
    builder.addCase(loginActionAsync.fulfilled, (state) => {
      state.isLoading = false;
      state.status = 'success';
      state.isLoggedIn = true;
      state.hasErrors = false;
      state.errorMessage = '';
    });
    builder.addCase(loginActionAsync.rejected, (state, action) => {
      state.isLoading = false;
      state.status = 'failed';
      state.hasErrors = true;
      state.errorMessage = action.payload as string;
    });
  },
});

export const loginReducer = loginSlice.reducer;
