import { IRaffleDetail } from '@models/Raffle.models';
import { IAnimalBet, IAnimalRaffleBet } from '@models/Animal.models';
import { ErrorValidateBet, IBet, IBetTicket } from '@models/Bet.models';
import { ANIMAL_RAFFLES_ID, ANIMAL_TRIPLETA_GAME_ID } from '@constants/app.constants';
import { ITicketAnimal } from '@models/Ticket.models';
import { groupBy, sortBy, uniqWith } from 'lodash';
import { error } from 'console';

export const buildBetHelperFromRaffles = (
  lotteryId: number,
  currencyId: number,
  selectedRaffles: IRaffleDetail[],
  animalsBet: IAnimalBet[],
  selectedGameType: number,
) => {
  const betsTicket: IBetTicket = {} as IBetTicket;
  const bets: IBet[] = [];

  selectedRaffles.forEach((raffle) => {
    const bet: IBet = {} as IBet;
    bet.lotteryId = lotteryId;
    bet.gameTypeId = selectedGameType === ANIMAL_TRIPLETA_GAME_ID ? ANIMAL_TRIPLETA_GAME_ID : 1;
    bet.animalGameTypeId = selectedGameType;
    bet.raffleId = raffle.animalitosRaffleId;
    bet.raffleName = raffle.animalitosRaffleName;
    if (selectedGameType === ANIMAL_TRIPLETA_GAME_ID) {
      bets.push({
        ...bet,
        betValue: animalsBet.map((animal) => animal.animalId).join('-'),
        betTotal: Number(animalsBet[0].betAmount),
      });
    } else {
      animalsBet.forEach((animal) => {
        bets.push({
          ...bet,
          betValue: animal.animalId.toString(),
          betTotal: Number(animal.betAmount),
        });
      });
    }
  });
  betsTicket.bets = bets;
  betsTicket.currencyId = currencyId;
  betsTicket.salePointId = 1;
  return betsTicket;
};

export const buildBetHelperFromAnimalRaffleBet = (
  lotteryId: number,
  currencyId: number,
  animalRaffleBet: IAnimalRaffleBet[],
) => {
  const betsTicket: IBetTicket = {} as IBetTicket;
  const bets: IBet[] = [];
  const groupAnimalRaffleByRaffle = groupBy(animalRaffleBet, 'raffleId');
  Object.keys(groupAnimalRaffleByRaffle).forEach((raffleId) => {
    const raffle = groupAnimalRaffleByRaffle[raffleId][0];
    const bet: IBet = {} as IBet;
    bet.lotteryId = lotteryId;
    bet.gameTypeId = 1;
    bet.raffleId = raffle.raffleId;
    bet.raffleName = raffle.raffleName;
    groupAnimalRaffleByRaffle[raffleId].forEach((animal) => {
      bets.push({
        ...bet,
        betValue: animal.animalId.toString(),
        betTotal: Number(animal.betAmount),
      });
    });
  });
  betsTicket.bets = bets;
  betsTicket.currencyId = currencyId;
  betsTicket.salePointId = 1;
  return betsTicket;
};

export const buildBetFromTicket = (currencyId: number, ticketAnimal: ITicketAnimal[]) => {
  const betsTicket: IBetTicket = {} as IBetTicket;
  const bets: IBet[] = [];

  ticketAnimal.forEach((animalTicket) => {
    animalTicket.rafflesAnimalBet.forEach((raffle) => {
      raffle.animalsBet.forEach((animal) => {
        bets.push({
          lotteryId: animalTicket.lotteryId,
          gameTypeId: ANIMAL_RAFFLES_ID,
          raffleId: raffle.raffleId,
          raffleName: raffle.raffleName,
          betValue: animal.animalId.toString(),
          betTotal: Number(animal.betAmount),
        });
      });
    });
  });
  betsTicket.bets = bets;
  betsTicket.currencyId = currencyId;
  betsTicket.salePointId = 1;
  return betsTicket;
};

export const buildAnimalBetWithError = (
  selectedAnimalBet: IAnimalBet[],
  errorBetList: ErrorValidateBet[],
) => {
  return selectedAnimalBet.map((animalBet) => {
    const error = errorBetList.find((error) => {
      return error.errorList.find((errorDetail) => {
        return (
          errorDetail.betValue === animalBet.animalId.toString() &&
          errorDetail.gameTypeId === ANIMAL_RAFFLES_ID
        );
      });
    });
    if (error) {
      const errorTextFromErrorList = getErrorFromListToAnimalBet(error, animalBet);
      return {
        ...animalBet,
        error: errorTextFromErrorList,
        hasError: true,
      };
    }

    return animalBet;
  });
};

export const buildRaffleAnimalBetWithError = (
  selectedRaffles: IRaffleDetail[],
  selectedAnimalBet: IAnimalBet[],
  errorBetList: ErrorValidateBet[],
): IAnimalRaffleBet[] => {
  const raffleAnimalBet: IAnimalRaffleBet[] = [];
  selectedRaffles.forEach((raffle) => {
    selectedAnimalBet.forEach((animalBet) => {
      const error = errorBetList.find((error) => {
        return error.errorList.find((errorDetail) => {
          return (
            errorDetail.raffleId === raffle.animalitosRaffleId &&
            errorDetail.betValue === animalBet.animalId.toString() &&
            errorDetail.gameTypeId === ANIMAL_RAFFLES_ID
          );
        });
      });
      
      if (error) {
        const errorTextFromErrorList = getErrorFromListToAnimalBet(error, animalBet);
        const betValueFixed = getFixedValueFromListToAnimalBet(error, animalBet);
        raffleAnimalBet.push({
          raffleId: raffle.animalitosRaffleId,
          raffleName: raffle.animalitosRaffleName,
          ...animalBet,
          betAmount: betValueFixed && betValueFixed > 0 ? betValueFixed : animalBet.betAmount,
          error: errorTextFromErrorList,
          hasError: true,
          betValueFixed: betValueFixed
        });
      } else {
        raffleAnimalBet.push({
          raffleId: raffle.animalitosRaffleId,
          raffleName: raffle.animalitosRaffleName,
          ...animalBet,
          betValueFixed: 0
        });
      }
    });
  });
  
  return raffleAnimalBet;
};

export const getFixedValueFromListToAnimalBet = (errorList: ErrorValidateBet, animalBet: IAnimalBet) => {
  const error = errorList.errorList.find(
    (error) =>
      error.betValue === animalBet.animalId.toString() && error.gameTypeId === ANIMAL_RAFFLES_ID,
  );
  return error?.betValueFixed;
};

export const getErrorFromListToAnimalBet = (errorList: ErrorValidateBet, animalBet: IAnimalBet) => {
  const error = errorList.errorList.find(
    (error) =>
      error.betValue === animalBet.animalId.toString() && error.gameTypeId === ANIMAL_RAFFLES_ID,
  );
  return error?.error;
};

export const mergeAnimalBets = (currentBet: IBet[], selectedBet: IBet[]) => {
  const concatBet = selectedBet.concat(currentBet);
  return sortBy(
    uniqWith(
      concatBet,
      (currentVal, otherVal) =>
        currentVal.gameTypeId === otherVal.gameTypeId &&
        currentVal.raffleId === otherVal.raffleId &&
        currentVal.betValue === otherVal.betValue,
    ),
    'raffleId',
  );
};

export const getOnlyBetsFromAnimalGame = (betTicket: IBetTicket) => {
  return {
    ...betTicket,
    bets: betTicket.bets.filter(
      (bet) => bet.gameTypeId === ANIMAL_RAFFLES_ID || bet.gameTypeId === ANIMAL_TRIPLETA_GAME_ID,
    ),
  };
};

export const joinAnimalValuesForTripleta = (arrayString: string[]): string =>
  arrayString.reduce((acc, animal) => `${acc}-${animal}`, '').slice(1);
