import React from 'react';
import Dialog from '@mui/material/Dialog';
import { CircularProgress, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

interface ConfirmSignOutProps {
  openDialogSignOut: boolean;
  onConfirmSignOut: (confirm: boolean) => void;
  isLoading: boolean;
}
function ConfirmSignOut({ openDialogSignOut, onConfirmSignOut, isLoading }: ConfirmSignOutProps) {
  return (
    <Dialog open={openDialogSignOut}>
      <DialogTitle>Cierre de sesión</DialogTitle>
      <DialogContent>
        <Typography>Está seguro que desea cerrar sesión?</Typography>
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={() => onConfirmSignOut(false)}>
          No
        </Button>
        <Button
          disabled={isLoading}
          color='error'
          variant='contained'
          onClick={() => onConfirmSignOut(true)}
        >
          Si
          {isLoading && <CircularProgress size={24} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmSignOut;
