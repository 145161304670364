import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chance3BetArray } from '@models/Chance3Digits.models';

interface IChancePayloadAction {
  tripleSeries: string[];
  betAmount: number;
}

const initialState: Chance3BetArray = {
  arrayNumbers: [],
  betAmount: 0,
};

export const tripleSeriesBetSlice = createSlice({
  name: 'tripleSeriesBet',
  initialState,
  reducers: {
    actionSetTripleSeriesArray: (state, action: PayloadAction<IChancePayloadAction>) => {
      state.arrayNumbers = action.payload.tripleSeries;
      state.betAmount = action.payload.betAmount;
    },
    actionCleanTripleSeriesState: (state) => {
      state.arrayNumbers = [];
      state.betAmount = 0;
    },
  },
});

export const { actionSetTripleSeriesArray } = tripleSeriesBetSlice.actions;

export const tripleSeriesChance4BetReducer = tripleSeriesBetSlice.reducer;
