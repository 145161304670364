import React from 'react';
import { useAnimalsBet } from '@features/AnimalsGame/AnimalsBet/AnimalBet.hook';
import AnimalFormBet from '@components/AnimalFormBet/AnimalFormBet';

import { ConditionalRendering } from '@components/ConditionalRendering/ConditionalRendering';

function AnimalsBet() {
  const {
    selectedLotterySetting,
    selectedAnimalBet,
    isLoading,
    onChangeAnimalBet,
    onBetToTicket,
    onChangeBetEveryAnimal,
    disabledAddToTicketButton,
    massiveBet,
    showAnimalBet,
    selectedGameType,
    deleteCombinedLotteryBet,
  } = useAnimalsBet();
  return (
    <ConditionalRendering isTrue={showAnimalBet}>
      <AnimalFormBet
        isLoading={isLoading}
        selectedLotterySetting={selectedLotterySetting}
        selectedAnimalsBet={selectedAnimalBet}
        onChangeAnimalBet={onChangeAnimalBet}
        onBetToTicket={onBetToTicket}
        onChangeBetEveryAnimal={onChangeBetEveryAnimal}
        disabledAddToTicketButton={disabledAddToTicketButton}
        massiveBetValue={massiveBet}
        selectedGameType={selectedGameType}
        deleteCombinedLotteryBet={deleteCombinedLotteryBet}
      />
    </ConditionalRendering>
  );
}

export default AnimalsBet;
