import { batch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  buildBetHelperFromAnimalRaffleBet,
  buildBetHelperFromRaffles,
  buildRaffleAnimalBetWithError,
  getOnlyBetsFromAnimalGame,
  mergeAnimalBets,
} from '@helpers/bet.helpers';
import { actionBetToTicket, actionSetAnimalBetTicketInfo } from '@features/Ticket/TicketSlice';
import {
  actionCleanAnimalsBetState,
  actionSetClearAnimalBet,
  actionSetNewAnimalBetAmount,
  validateBetAsync,
} from '@features/AnimalsGame/AnimalsBet/AnimalsBetSlice';
import {
  buildAnimalTicketInfo,
  buildAnimalTicketInfoFromAnimalRaffleBet,
} from '@helpers/ticket.helpers';
import {
  actionCleanRafflesState,
  actionSetActiveRaffles,
} from '@features/AnimalsGame/Raffles/RaffleSlice';

import { actionCleanAnimalsState } from '@features/AnimalsGame/Animals/AnimalsSlice';
import { actionSetDisabledChangeCurrency } from '@features/Currency/Currency.slice';
import { IBetTicket } from '@models/Bet.models';
import { amountIsExceedingMaxValue, amountIsNotValid } from '@helpers/animalBet.helpers';
import { IAnimalRaffleBet } from '@models/Animal.models';
import { ANIMAL_TRIPLETA_GAME_ID } from '@constants/app.constants';
import { filterActiveRaffles } from '@helpers/raffle.helpers';
import { useHotkeys } from 'react-hotkeys-hook';
import { BET_TO_TICKET_SHORTCUT } from '@constants/shortcuts.constants';
import { animalsLotterySelector } from '@selectors/animalsLottery.selector';
import {
  betErrorsAnimalBetSelector,
  isAlReadyFetchedAnimalBetSelector,
  isLoadingAnimalBetSelector,
  selectedAnimalBetSelector,
} from '@selectors/animalsBet.selector';
import {
  raffleSettingsSelector,
  selectedCurrencyIdSelector,
} from '@selectors/animalcurrency.selector';
import { selectedRafflesSelector } from '@selectors/raffle.selector';
import { selectedActiveLotterySelector } from '@selectors/lottery.selector';
import { animalBetTicketInfoSelector, ticketBetsSelector } from '@selectors/ticket.selector';
import { selectedAnimalTypeGameSelector } from '@selectors/animalTypeGame.selector';

export const useAnimalsBet = () => {
  const dispatch = useAppDispatch();

  const activeAnimalsByLottery = useAppSelector(animalsLotterySelector);
  const selectedAnimalBet = useAppSelector(selectedAnimalBetSelector);
  const selectedLotterySetting = useAppSelector(raffleSettingsSelector);
  const selectedRaffles = useAppSelector(selectedRafflesSelector);
  const selectedActiveLottery = useAppSelector(selectedActiveLotterySelector);
  const betErrors = useAppSelector(betErrorsAnimalBetSelector);
  const isAlReadyFetched = useAppSelector(isAlReadyFetchedAnimalBetSelector);
  const ticketBets = useAppSelector(ticketBetsSelector);
  const animalBetTicketInfo = useAppSelector(animalBetTicketInfoSelector);
  const isLoading = useAppSelector(isLoadingAnimalBetSelector);
  const selectedGameType = useAppSelector(selectedAnimalTypeGameSelector);
  const selectedCurrencyId = useAppSelector(selectedCurrencyIdSelector);

  const [betDataState, setBetDataState] = useState({} as IBetTicket);
  const [massiveBet, setMassiveBet] = useState<string>('');
  const showAnimalBet =
    selectedGameType === ANIMAL_TRIPLETA_GAME_ID
      ? selectedLotterySetting.maxAnimalsByTicket === selectedAnimalBet.length
      : selectedAnimalBet.length > 0;

  const disabledAddToTicketButton = selectedAnimalBet.some(
    (animal) =>
      !animal.betAmount ||
      amountIsNotValid(animal.betAmount) ||
      amountIsExceedingMaxValue(selectedLotterySetting.maxBetByAnimal, Number(animal.betAmount)),
  );

  useHotkeys(
    BET_TO_TICKET_SHORTCUT,
    () => {
      if (!disabledAddToTicketButton) {
        onBetToTicket();
      }
    },
    {
      enabled: disabledAddToTicketButton,
    },
  );

  const onChangeBetEveryAnimal = (betAmount: string) => {
    const newAnimalBet = selectedAnimalBet.map((animal) => ({
      ...animal,
      betAmount,
      hasError:
        (betAmount &&
          (amountIsExceedingMaxValue(selectedLotterySetting.maxBetByAnimal, Number(betAmount)) ||
            Number(betAmount) <= 0)) ||
        false,
    }));
    dispatch(actionSetNewAnimalBetAmount(newAnimalBet));
    setMassiveBet(betAmount);
  };

  const onChangeAnimalBet = (animalId: string, animalBet: string) => {
    const newAnimalBet = selectedAnimalBet.map((animal) => {
      if (animal.animalId === animalId) {
        return {
          ...animal,
          betAmount: animalBet,
          hasError:
            (animalBet &&
              (amountIsExceedingMaxValue(
                selectedLotterySetting.maxBetByAnimal,
                Number(animalBet),
              ) ||
                Number(animalBet) <= 0)) ||
            false,
        };
      }
      return animal;
    });
    dispatch(actionSetNewAnimalBetAmount(newAnimalBet));
  };

  const buildBetToTicket = (betData: IBetTicket) => {
    batch(() => {
      dispatch(actionBetToTicket(betData));
      const animalTicketInfo = buildAnimalTicketInfo(
        selectedLotterySetting.currencyId,
        selectedRaffles,
        selectedAnimalBet,
        selectedActiveLottery,
        animalBetTicketInfo,
        selectedGameType,
      );
      dispatch(actionSetAnimalBetTicketInfo(animalTicketInfo));
    });
  };

  const buildBetToTicketFromAnimalRaffleBet = (
    animalRaffleBet: IAnimalRaffleBet[],
    betData: IBetTicket,
  ) => {
    batch(() => {
      dispatch(actionBetToTicket(betData));
      const animalTicketInfo = buildAnimalTicketInfoFromAnimalRaffleBet(
        selectedLotterySetting.currencyId,
        animalRaffleBet,
        selectedActiveLottery,
        animalBetTicketInfo,
        selectedGameType,
      );
      dispatch(actionSetAnimalBetTicketInfo(animalTicketInfo));
    });
  };

  const cleanAnimalRaffleStates = () => {
    batch(() => {
      dispatch(actionCleanAnimalsBetState());
      dispatch(actionCleanRafflesState());
      dispatch(actionCleanAnimalsState());
      dispatch(actionSetDisabledChangeCurrency(true));
    });
    const activeRafflesFiltered = filterActiveRaffles(
      activeAnimalsByLottery,
      selectedGameType,
      selectedCurrencyId,
    );
    dispatch(actionSetActiveRaffles(activeRafflesFiltered));
    setMassiveBet('');
  };

  const deleteCombinedLotteryBet = (animalId: string) => {
    dispatch(actionSetClearAnimalBet(animalId));
  };

  useEffect(() => {
    if (betErrors.length > 0 && isAlReadyFetched) {
      const animalBetErrors = buildRaffleAnimalBetWithError(
        selectedRaffles,
        selectedAnimalBet,
        betErrors,
      );
      const betHasError = animalBetErrors.some((animal) => animal.hasError);
      if (betHasError) {
        const animalsWithOutError = animalBetErrors.filter(
          (animal) =>
            !animal.hasError ||
            (animal.hasError && animal.betValueFixed && animal.betValueFixed > 0),
        );
        if (animalsWithOutError.length > 0) {
          const betData = buildBetHelperFromAnimalRaffleBet(
            selectedActiveLottery.animalitosLotteryId,
            selectedLotterySetting.currencyId,
            animalsWithOutError,
          );
          buildBetToTicketFromAnimalRaffleBet(animalsWithOutError, betData);
          cleanAnimalRaffleStates();
        }
      }
    }

    if (betErrors.length === 0 && isAlReadyFetched && betDataState.bets?.length > 0) {
      buildBetToTicket(betDataState);
      cleanAnimalRaffleStates();
    }
  }, [betErrors, isAlReadyFetched]);

  useEffect(() => {
    setMassiveBet('');
  }, [selectedActiveLottery, selectedLotterySetting.currencyId]);

  const onBetToTicket = () => {
    const betData = buildBetHelperFromRaffles(
      selectedActiveLottery.animalitosLotteryId,
      selectedLotterySetting.currencyId,
      selectedRaffles,
      selectedAnimalBet,
      selectedGameType,
    );
    betData.bets = mergeAnimalBets(ticketBets, betData.bets);
    setBetDataState(betData);
    dispatch(validateBetAsync(getOnlyBetsFromAnimalGame(betData)));
  };

  return {
    amountIsExceedingMaxValue,
    disabledAddToTicketButton,
    selectedLotterySetting,
    onChangeBetEveryAnimal,
    selectedAnimalBet,
    onBetToTicket,
    onChangeAnimalBet,
    massiveBet,
    isLoading,
    showAnimalBet,
    selectedGameType,
    deleteCombinedLotteryBet,
    cleanAnimalRaffleStates,
    betDataState,
    setBetDataState,
  };
};
