import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useEffect, useRef, useState } from 'react';
import {
  actionSetChanceZodiacMaxBet,
  actionSetChanceZodiacRaffleSelected,
  actionSetSelectedChanceZodiacRaffleMaxBet2,
  actionSetSelectedChanceZodiacRaffleMaxBet3,
} from '@features/ChanceZodiac/ChanceZodiacRaffles/ChanceZodiacRaffle.slice';
import { ChanceZodiacRaffle } from '@models/ChanceZodiac.models';
import {
  actionSetActiveChanceZodiacSettings,
  actionSetSelectedChanceZodiacSetting,
} from '@features/Currency/Currency.slice';
import { filterRaffleZodiacByMaxBet } from '@helpers/chanceZodiac.helpers';
import { ChanceSettings } from '@models/chance.models';
import { useHotkeys } from 'react-hotkeys-hook';
import { SEARCH_RAFFLES_SHORTCUT } from '@constants/shortcuts.constants';
import { cloneDeep } from 'lodash';
import { CHANCE_ZODIACAL_PATH } from '@constants/url.constants';
import { chanceZodiacLotteriesSelector } from '@selectors/chanceZodiac.selector';
import {
  chanceZodiacRaffleMaxBet2Selector,
  chanceZodiacRaffleMaxBet3Selector,
  selectedChanceZodiacRaffleMaxBet2Selector,
  selectedChanceZodiacRaffleMaxBet3Selector,
} from '@selectors/chanceZodiacRaffles.selector';
import { selectedCurrencyIdSelector } from '@selectors/animalcurrency.selector';

export const useChanceZodiacRaffles = () => {
  const dispatch = useAppDispatch();

  const chanceZodiacLotteries = useAppSelector(chanceZodiacLotteriesSelector);
  const chanceZodiacRaffleMaxBet3 = useAppSelector(chanceZodiacRaffleMaxBet3Selector);
  const chanceZodiacRaffleMaxBet2 = useAppSelector(chanceZodiacRaffleMaxBet2Selector);
  const selectedCurrencyId = useAppSelector(selectedCurrencyIdSelector);
  const selectedChanceZodiacRaffleMaxBet3 = useAppSelector(
    selectedChanceZodiacRaffleMaxBet3Selector,
  );
  const selectedChanceZodiacRaffleMaxBet2 = useAppSelector(
    selectedChanceZodiacRaffleMaxBet2Selector,
  );

  const searchInputRef = useRef<HTMLInputElement>(null);
  const [searchInput, setSearchInput] = useState('');
  const [rafflesMax3Digits, setRafflesMax3Digits] =
    useState<ChanceZodiacRaffle[]>(chanceZodiacRaffleMaxBet3);
  const [rafflesMax2Digits, setRafflesMax2Digits] =
    useState<ChanceZodiacRaffle[]>(chanceZodiacRaffleMaxBet2);

  useEffect(() => {
    setRafflesMax3Digits(chanceZodiacRaffleMaxBet3);
    setRafflesMax2Digits(chanceZodiacRaffleMaxBet2);
  }, [chanceZodiacRaffleMaxBet3, chanceZodiacRaffleMaxBet2]);

  useHotkeys(
    SEARCH_RAFFLES_SHORTCUT,
    () => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    },
    {
      enabled: window.location.pathname === CHANCE_ZODIACAL_PATH,
    },
  );

  const disabledRaffles = (
    chanceZodiacRaffleMaxBet: ChanceZodiacRaffle[],
    setDisabledValue: boolean,
  ) => {
    return chanceZodiacRaffleMaxBet.map((raffle) => ({
      ...raffle,
      chanceZodiacRaffleDisabled: setDisabledValue,
    }));
  };

  const searchForRaffles = () => {
    if (searchInput.length > 0) {
      const auxChanceZodiacRaffleMaxBet3 = cloneDeep(chanceZodiacRaffleMaxBet3);
      const auxChanceZodiacRaffleMaxBet2 = cloneDeep(chanceZodiacRaffleMaxBet2);

      const filterRaffle3Digits = auxChanceZodiacRaffleMaxBet3.filter((raffle) =>
        raffle.chanceZodiacLotteryName.toLowerCase().includes(searchInput.toLowerCase()),
      );

      const filterRaffle2Digits = auxChanceZodiacRaffleMaxBet2.filter((raffle) =>
        raffle.chanceZodiacLotteryName.toLowerCase().includes(searchInput.toLowerCase()),
      );

      selectedChanceZodiacRaffleMaxBet3.forEach((raffle) => {
        const indexChange3Digits = filterRaffle3Digits.findIndex(
          (raffleOriginal) => raffleOriginal.chanceZodiacRaffleId === raffle.chanceZodiacRaffleId,
        );
        if (indexChange3Digits !== -1) {
          filterRaffle3Digits[indexChange3Digits].chanceZodiacRaffleSelected = true;
        }
      });

      selectedChanceZodiacRaffleMaxBet2.forEach((raffle) => {
        const indexChange2Digits = filterRaffle2Digits.findIndex(
          (raffleOriginal) => raffleOriginal.chanceZodiacRaffleId === raffle.chanceZodiacRaffleId,
        );
        if (indexChange2Digits !== -1) {
          filterRaffle2Digits[indexChange2Digits].chanceZodiacRaffleSelected = true;
        }
      });

      setRafflesMax3Digits(filterRaffle3Digits);
      setRafflesMax2Digits(filterRaffle2Digits);
    }

    if (searchInput.length === 0) {
      const auxChanceZodiacRaffleMaxBet3 = cloneDeep(chanceZodiacRaffleMaxBet3);
      const auxChanceZodiacRaffleMaxBet2 = cloneDeep(chanceZodiacRaffleMaxBet2);

      selectedChanceZodiacRaffleMaxBet3.forEach((raffle) => {
        const indexChange3Digits = auxChanceZodiacRaffleMaxBet3.findIndex(
          (raffleOriginal) => raffleOriginal.chanceZodiacRaffleId === raffle.chanceZodiacRaffleId,
        );
        if (indexChange3Digits !== -1) {
          auxChanceZodiacRaffleMaxBet3[indexChange3Digits].chanceZodiacRaffleSelected = true;
        }
      });

      selectedChanceZodiacRaffleMaxBet2.forEach((raffle) => {
        const indexChange2Digits = auxChanceZodiacRaffleMaxBet2.findIndex(
          (raffleOriginal) => raffleOriginal.chanceZodiacRaffleId === raffle.chanceZodiacRaffleId,
        );
        if (indexChange2Digits !== -1) {
          auxChanceZodiacRaffleMaxBet2[indexChange2Digits].chanceZodiacRaffleSelected = true;
        }
      });

      setRafflesMax3Digits(chanceZodiacRaffleMaxBet3);
      setRafflesMax2Digits(chanceZodiacRaffleMaxBet2);
    }
  };

  const setRaffleSettings = (
    isMaxBet3: boolean,
    settings3Digits: ChanceSettings[] | undefined,
    settings2Digits: ChanceSettings[] | undefined,
  ) => {
    let raffleSettings;
    if (isMaxBet3 && settings3Digits) {
      raffleSettings = settings3Digits.find((setting) => setting.currencyId === selectedCurrencyId);
    } else if (!isMaxBet3 && settings2Digits) {
      raffleSettings = settings2Digits.find((setting) => setting.currencyId === selectedCurrencyId);
    }
    if (raffleSettings) {
      dispatch(actionSetSelectedChanceZodiacSetting(raffleSettings));
    }
  };

  const setChanceZodiacLotteriesSettings = (lotteryId: number, isMaxBet3: boolean) => {
    const settings3Digits = chanceZodiacLotteries.find(
      (lottery) =>
        lottery.chanceZodiacLotteryId === lotteryId &&
        lottery.chanceZodiacLotteryMaxDigitsByBet === 3,
    );
    const settings2Digits = chanceZodiacLotteries.find(
      (lottery) =>
        lottery.chanceZodiacLotteryId === lotteryId &&
        lottery.chanceZodiacLotteryMaxDigitsByBet === 2,
    );
    dispatch(
      actionSetActiveChanceZodiacSettings({
        settings3Digits: settings3Digits?.chanceLotterySettings,
        settings2Digits: settings2Digits?.chanceLotterySettings,
      }),
    );
    setRaffleSettings(
      isMaxBet3,
      settings3Digits?.chanceLotterySettings,
      settings2Digits?.chanceLotterySettings,
    );
  };

  const checkIfRaffleIsSelected = (
    raffles: ChanceZodiacRaffle[],
    isMax3Bet: boolean,
    raffleId: number,
  ) => {
    const raffleSelected = raffles.find((raffle) => raffle.chanceZodiacRaffleSelected);
    let raffleOtherBetSelected;
    let alreadySelectedRaffles: ChanceZodiacRaffle[] = [];
    if (isMax3Bet) {
      alreadySelectedRaffles = selectedChanceZodiacRaffleMaxBet3.filter(
        (raffle) => raffle.chanceZodiacRaffleId !== raffleId,
      );
      alreadySelectedRaffles = Array.from(
        new Set([
          ...selectedChanceZodiacRaffleMaxBet3.filter(
            (raffle) => raffle.chanceZodiacRaffleId !== raffleId,
          ),
          ...raffles.filter((raffle) => raffle.chanceZodiacRaffleId),
        ]),
      );
      raffleOtherBetSelected = !!rafflesMax2Digits.find(
        (raffle) => raffle.chanceZodiacRaffleSelected,
      );

      dispatch(actionSetSelectedChanceZodiacRaffleMaxBet3([...alreadySelectedRaffles]));
    } else {
      alreadySelectedRaffles = selectedChanceZodiacRaffleMaxBet3.filter(
        (raffle) => raffle.chanceZodiacRaffleId !== raffleId,
      );
      raffleOtherBetSelected = !!rafflesMax3Digits.find(
        (raffle) => raffle.chanceZodiacRaffleSelected,
      );

      dispatch(
        actionSetSelectedChanceZodiacRaffleMaxBet2([
          ...alreadySelectedRaffles,
          ...raffles.filter((raffle) => !!raffle.chanceZodiacRaffleSelected),
        ]),
      );
    }

    if (raffleSelected || raffleOtherBetSelected) {
      dispatch(actionSetChanceZodiacRaffleSelected(true));
    } else {
      dispatch(actionSetChanceZodiacRaffleSelected(false));
    }
  };

  const onSelectChanceRaffle = (raffleId: number, isMaxBet3: boolean) => {
    if (isMaxBet3) {
      let raffleSelected = false;
      let lotterySelectedId = 0;
      const updatedRaffles = rafflesMax3Digits.map((raffle) => {
        if (raffle.chanceZodiacRaffleId === raffleId) {
          lotterySelectedId = raffle.chanceZodiacLotteryId;
          if (!raffle.chanceZodiacRaffleSelected) {
            raffleSelected = true;
          }
          return { ...raffle, chanceZodiacRaffleSelected: !raffle.chanceZodiacRaffleSelected };
        }
        if (raffle.chanceZodiacRaffleSelected) {
          raffleSelected = true;
        }
        return raffle;
      });
      setRafflesMax3Digits(updatedRaffles);
      setRafflesMax2Digits(disabledRaffles(rafflesMax2Digits, raffleSelected));

      if (raffleSelected) {
        setChanceZodiacLotteriesSettings(lotterySelectedId, isMaxBet3);
      }
      checkIfRaffleIsSelected(updatedRaffles, isMaxBet3, raffleId);
    } else {
      let raffleSelected = false;
      let lotterySelectedId = 0;
      const updatedRaffles = rafflesMax2Digits.map((raffle) => {
        if (raffle.chanceZodiacRaffleId === raffleId) {
          lotterySelectedId = raffle.chanceZodiacLotteryId;
          if (!raffle.chanceZodiacRaffleSelected) {
            raffleSelected = true;
          }
          return { ...raffle, chanceZodiacRaffleSelected: !raffle.chanceZodiacRaffleSelected };
        }
        if (raffle.chanceZodiacRaffleSelected) {
          raffleSelected = true;
        }
        return raffle;
      });

      setRafflesMax2Digits(updatedRaffles);
      setRafflesMax2Digits(disabledRaffles(rafflesMax3Digits, raffleSelected));
      if (raffleSelected) {
        setChanceZodiacLotteriesSettings(lotterySelectedId, isMaxBet3);
      }
      checkIfRaffleIsSelected(updatedRaffles, isMaxBet3, raffleId);
      dispatch(
        actionSetSelectedChanceZodiacRaffleMaxBet2(
          updatedRaffles.filter((raffle) => raffle.chanceZodiacRaffleSelected),
        ),
      );
    }
  };

  const setChanceMaxBetBy = () => {
    const raffles = filterRaffleZodiacByMaxBet(chanceZodiacLotteries);
    dispatch(actionSetChanceZodiacMaxBet({ maxBet3: raffles.maxBet3, maxBet2: raffles.maxBet2 }));
  };

  useEffect(() => {
    setChanceMaxBetBy();
  }, [chanceZodiacLotteries]);

  return {
    onSelectChanceRaffle,
    chanceZodiacRaffleMaxBet3: rafflesMax3Digits,
    chanceZodiacRaffleMaxBet2: rafflesMax2Digits,
    setChanceMaxBetBy,
    searchInputRef,
    setSearchInput,
    searchForRaffles,
    searchInput,
  };
};
