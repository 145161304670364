import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/store';

export const chanceZodiacRafflesSelector = createSelector(
  (state: RootState) => state.chanceZodiacRaffles.chanceZodiacRaffles,
  (chanceZodiacRaffles) => {
    return chanceZodiacRaffles;
  },
);

export const chanceZodiacRaffleMaxBet3Selector = createSelector(
  (state: RootState) => state.chanceZodiacRaffles.chanceZodiacRaffleMaxBet3,
  (chanceZodiacRaffleMaxBet3) => {
    return chanceZodiacRaffleMaxBet3;
  },
);

export const chanceZodiacRaffleMaxBet2Selector = createSelector(
  (state: RootState) => state.chanceZodiacRaffles.chanceZodiacRaffleMaxBet2,
  (chanceZodiacRaffleMaxBet2) => {
    return chanceZodiacRaffleMaxBet2;
  },
);

export const selectedChanceZodiacRaffleMaxBet3Selector = createSelector(
  (state: RootState) => state.chanceZodiacRaffles.selectedChanceZodiacRaffleMaxBet3,
  (selectedChanceZodiacRaffleMaxBet3) => {
    return selectedChanceZodiacRaffleMaxBet3;
  },
);

export const selectedChanceZodiacRaffleMaxBet2Selector = createSelector(
  (state: RootState) => state.chanceZodiacRaffles.selectedChanceZodiacRaffleMaxBet2,
  (selectedChanceZodiacRaffleMaxBet2) => {
    return selectedChanceZodiacRaffleMaxBet2;
  },
);

export const isChanceZodiacRaffleSelectedSelector = createSelector(
  (state: RootState) => state.chanceZodiacRaffles.isChanceZodiacRaffleSelected,
  (isChanceZodiacRaffleSelected) => {
    return isChanceZodiacRaffleSelected;
  },
);
