import { ITicketCancel, ITicketCancelResponse } from '@models/TicketCancel.models';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { cancelTicketApi } from './TicketCancel.api';
import { ITicket } from '@models/Ticket.models';

export interface TicketCancelItemState {
  selectedCancellableTicket: ITicket;
  openDialog: boolean;
  error: string;
  isLoading: boolean;
  status: 'success' | 'failed' | 'idle' | 'pending';
}

export const initialState: TicketCancelItemState = {
  selectedCancellableTicket: {} as ITicket,
  openDialog: false,
  error: '',
  isLoading: false,
  status: 'idle',
};

export const cancelTicketAsync = createAsyncThunk(
  'ticketCancellation/cancel-ticket-by-id-and-guid',
  async (ticketData: ITicketCancel) => {
    const response = await cancelTicketApi(ticketData);
    return response as ITicketCancelResponse;
  },
);

export const ticketCancelItemSlice = createSlice({
  name: 'ticketCancelItem',
  initialState,
  reducers: {
    actionCleanTicketCancelItem: () => {
      return initialState;
    },
    actionShowTicketCancelDialogConfirm: (state) => {
      state.openDialog = true;
    },
    actionHideTicketCancelDialogConfirm: (state) => {
      state.openDialog = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(cancelTicketAsync.pending, (state) => {
        state.isLoading = true;
        state.status = 'pending';
        state.error = '';
      })
      .addCase(
        cancelTicketAsync.fulfilled,
        (state, action: PayloadAction<ITicketCancelResponse>) => {
          state.isLoading = false;
          state.status = action.payload.success ? 'success' : 'failed';
          if (action.payload.success) {
            state.error = '';
          } else {
            state.error = action.payload.message;
          }
        },
      )
      .addCase(cancelTicketAsync.rejected, (state) => {
        state.isLoading = false;
        state.status = 'failed';
        state.error = '';
      });
  },
});

export const {
  actionShowTicketCancelDialogConfirm,
  actionHideTicketCancelDialogConfirm,
  actionCleanTicketCancelItem,
} = ticketCancelItemSlice.actions;

export const ticketCancelItemReducer = ticketCancelItemSlice.reducer;
