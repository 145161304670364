import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import { IRaffleDetail } from '@models/Raffle.models';

interface RaffleItemProps {
  raffle: IRaffleDetail;
  isRaffleSelected: boolean;
  raffleHasError: IRaffleDetail | undefined;
  onClickRaffle: (raffle: number) => void;
}
function RaffleItem({ raffle, raffleHasError, onClickRaffle, isRaffleSelected }: RaffleItemProps) {
  const labelId = `checkbox-list-label-${raffle.animalitosRaffleId}`;
  const textColor = raffleHasError?.animalitosRaffleError ? 'error' : 'default';
  const checkBoxColor = raffleHasError?.animalitosRaffleError ? 'error' : 'primary';

  const onClickRaffleItem = () => {
    onClickRaffle(raffle.animalitosRaffleId);
  };

  return (
    <ListItem key={`raffle-${raffle.animalitosRaffleId}`} disablePadding tabIndex={-1}>
      <ListItemButton role={undefined} onClick={onClickRaffleItem} dense>
        <ListItemIcon sx={{ minWidth: '30px' }}>
          <Checkbox
            color={checkBoxColor}
            edge='start'
            checked={isRaffleSelected}
            tabIndex={-1}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText id={labelId}>
          <Typography variant='body2' color={textColor}>
            {raffle.animalitosRaffleName}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

export default RaffleItem;
