import { useAppDispatch, useAppSelector } from '@app/hooks';
import { batch } from 'react-redux';
import { actionSetSelectedChanceZodiacSetting } from '@features/Currency/Currency.slice';
import { useChanceZodiacRaffles } from '@features/ChanceZodiac/ChanceZodiacRaffles/ChanceZodiacRaffle.hook';
import { actionCleanChanceZodiacRaffleState } from '@features/ChanceZodiac/ChanceZodiacRaffles/ChanceZodiacRaffle.slice';
import { actionCleanTicketState } from '@features/Ticket/TicketSlice';
import {
  chanceZodiacRaffleMaxBet2Selector,
  chanceZodiacRaffleMaxBet3Selector,
} from '@selectors/chanceZodiacRaffles.selector';

export const useChanceZodiacCurrency = () => {
  const dispatch = useAppDispatch();
  const { setChanceMaxBetBy } = useChanceZodiacRaffles();

  const chanceZodiacRaffleMaxBet3 = useAppSelector(chanceZodiacRaffleMaxBet3Selector);
  const chanceZodiacRaffleMaxBet2 = useAppSelector(chanceZodiacRaffleMaxBet2Selector);

  const onSelectChanceSetting = () => {
    batch(() => {
      dispatch(actionCleanChanceZodiacRaffleState());
      setChanceMaxBetBy();
      dispatch(actionSetSelectedChanceZodiacSetting({}));
      dispatch(actionCleanTicketState());
    });
  };

  const shouldAskBeforeChangeCurrency = () => {
    const raffleMaxBet3Selected = chanceZodiacRaffleMaxBet3.find(
      (raffle) => raffle.chanceZodiacRaffleSelected,
    );
    const raffleMaxBet2Selected = chanceZodiacRaffleMaxBet2.find(
      (raffle) => raffle.chanceZodiacRaffleSelected,
    );

    return raffleMaxBet3Selected || raffleMaxBet2Selected;
  };

  return {
    shouldAskBeforeChangeCurrency,
    onSelectChanceSetting,
  };
};
