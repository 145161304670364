import { AxiosError, AxiosResponse } from 'axios';
import { IBetTicket, IBetTicketResponse } from '@models/Bet.models';
import httpCommon from '@config/http-common';

export const addTicketApi = async (data: IBetTicket) => {
  try {
    const response: AxiosResponse<IBetTicketResponse> = await httpCommon.post(
      'Ticket/add-ticket',
      data,
    );
    return response?.data || [];
  } catch (error) {
    throw (error as AxiosError).response?.data;
  }
};
