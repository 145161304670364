import { AxiosError, AxiosResponse } from 'axios';
import httpCommon from '@config/http-common';
import { DuplicateTicketResponse, IBet } from '@models/Bet.models';

export const duplicateTicketAsync = async (ticketNumber: string) => {
  try {
    const response: AxiosResponse<DuplicateTicketResponse> = await httpCommon.get(
      `Ticket/duplicate-ticket/ticketNumber/${ticketNumber}`,
    );
    return response?.data || [];
  } catch (error) {
    throw (error as AxiosError).response?.data;
  }
};
