import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AnimalTypeGameState {
  selectedAnimalTypeGame: number;
}

const initialState: AnimalTypeGameState = {
  selectedAnimalTypeGame: 1,
};

export const animalTypeGameSlice = createSlice({
  name: 'animalTypeGame',
  initialState,
  reducers: {
    actionSelectAnimalTypeGame: (state, action: PayloadAction<number>) => {
      state.selectedAnimalTypeGame = action.payload;
    },
  },
});

export const { actionSelectAnimalTypeGame } = animalTypeGameSlice.actions;

export const animalTypeGameReducer = animalTypeGameSlice.reducer;
