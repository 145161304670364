import React from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useAppSelector } from '@app/hooks';
import { capitalize } from 'lodash';
import { green, red } from '@mui/material/colors';

interface ConfirmChangeCurrencyProps {
  open: boolean;
  onConfirmChangeCurrency: (confirm: boolean) => void;
  selectedCurrencyId: number;
  currencyToChangeId: number;
}
function ConfirmChangeCurrency({
  open,
  onConfirmChangeCurrency,
  currencyToChangeId,
  selectedCurrencyId,
}: ConfirmChangeCurrencyProps) {
  const currencies = useAppSelector((state) => state.currency.currencyList);
  const selectedCurrency = currencies.find((currency) => currency.id === selectedCurrencyId);
  const currencyIdToChange = currencies.find((currency) => currency.id === currencyToChangeId);

  return (
    <Dialog open={open}>
      <DialogTitle>Cambio de moneda</DialogTitle>
      <DialogContent>
        <Typography>
          Desea cambiar el tipo de moneda{' '}
          <strong>{capitalize(selectedCurrency?.name ?? '')}</strong> a{' '}
          <strong>{capitalize(currencyIdToChange?.name ?? '')}</strong>, esto eliminará el proceso
          actual
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color={undefined}
          variant='contained'
          onClick={() => onConfirmChangeCurrency(false)}
          sx={{
            backgroundColor: red[700],
            color: 'white',
            '&:hover': {
              backgroundColor: red[900],
            },
          }}
        >
          No
        </Button>
        <Button
          color={undefined}
          variant='contained'
          onClick={() => onConfirmChangeCurrency(true)}
          sx={{
            backgroundColor: green[700],
            color: 'white',
            '&:hover': {
              backgroundColor: green[900],
            },
          }}
        >
          Si
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmChangeCurrency;
