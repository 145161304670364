import { useAppDispatch, useAppSelector } from '@app/hooks';

import { actionSetBet, actionSetChanceZodiacBetTicketInfo } from '@features/Ticket/TicketSlice';

import {
  createBetFromCurrentChanceZodiacBetTicket,
  deleteLotteryFromTicket,
  deleteRaffleFromTicket,
} from '@helpers/chanceZodiac.helpers';
import { useEffect, useState } from 'react';
import { amountIsExceedingMaxValue, amountIsNotValid } from '@helpers/animalBet.helpers';
import { ITicketChanceZodiac } from '@models/ChanceZodiac.models';
import { CHANCE_ZODIAC_RAFFLES_ID } from '@constants/app.constants';
import { IBet } from '@models/Bet.models';
import { chanceZodiacBetTicketInfoSelector, ticketBetsSelector } from '@selectors/ticket.selector';
import { chanceZodiacLotteriesSelector } from '@selectors/chanceZodiac.selector';
import { selectedCurrencyIdSelector } from '@selectors/animalcurrency.selector';

export const useChanceZodiacTicket = () => {
  const dispatch = useAppDispatch();

  const ticketBets = useAppSelector(ticketBetsSelector);
  const chanceZodiacBetTicketInfo = useAppSelector(chanceZodiacBetTicketInfoSelector);
  const ChanceZodiacLotteries = useAppSelector(chanceZodiacLotteriesSelector);
  const selectedCurrencyId = useAppSelector(selectedCurrencyIdSelector);

  const [formHasErrors, setFormHasErrors] = useState(false);

  const deleteLottery = (lotteryId: number) => {
    dispatch(
      actionSetChanceZodiacBetTicketInfo(
        deleteLotteryFromTicket(chanceZodiacBetTicketInfo, lotteryId),
      ),
    );
  };

  const deleteLotteryRaffle = (lotteryId: number, raffleId: number) => {
    dispatch(
      actionSetChanceZodiacBetTicketInfo(
        deleteRaffleFromTicket(chanceZodiacBetTicketInfo, lotteryId, raffleId),
      ),
    );
  };

  useEffect(() => {
    let copyNewChanceBets: IBet[] = ticketBets.filter(
      (bet) => bet.gameTypeId !== CHANCE_ZODIAC_RAFFLES_ID,
    );

    if (chanceZodiacBetTicketInfo.length > 0) {
      const builtChance3DigitsTicketBets =
        createBetFromCurrentChanceZodiacBetTicket(chanceZodiacBetTicketInfo);
      copyNewChanceBets = copyNewChanceBets.concat(builtChance3DigitsTicketBets);
    }
    dispatch(actionSetBet(copyNewChanceBets));
  }, [chanceZodiacBetTicketInfo]);

  const setEditableRaffle = (indexLottery: number, index: number, finishEdit?: boolean) => {
    const lotteryObject = chanceZodiacBetTicketInfo[indexLottery];
    const raffleObject = {
      ...lotteryObject.raffleChanceZodiacBet[index],
      isEditing: !finishEdit,
    };

    const newChanceBetTicketInfo = chanceZodiacBetTicketInfo.map((lottery, i) => {
      if (i === indexLottery) {
        return {
          ...lottery,
          raffleChanceZodiacBet: lottery.raffleChanceZodiacBet.map((raffle, j) => {
            if (j === index) {
              return raffleObject;
            }
            return raffle;
          }),
        };
      }
      return lottery;
    });

    dispatch(actionSetChanceZodiacBetTicketInfo(newChanceBetTicketInfo));
  };

  const updateChanceBetAmount = (
    indexLottery: number,
    indexRaffle: number,
    indexBet: number,
    amount: string,
  ) => {
    let hasErrorForm = false;
    const newChanceTicketInfo: ITicketChanceZodiac[] = JSON.parse(
      JSON.stringify(chanceZodiacBetTicketInfo),
    );
    const lotterySetting = ChanceZodiacLotteries.find(
      (chanceLotteries) =>
        chanceLotteries.chanceZodiacLotteryId === newChanceTicketInfo[indexLottery].lotteryId,
    );
    const raffleSetting = lotterySetting?.chanceLotterySettings.find(
      (raffleSetting) => raffleSetting.currencyId === selectedCurrencyId,
    );

    if (raffleSetting) {
      const exceedMaxAmount = amountIsExceedingMaxValue(
        raffleSetting.maxBetByChance,
        Number(amount),
      );
      const isAmountNotValid = amountIsNotValid(amount);

      let errorMessage = '';
      if (exceedMaxAmount) {
        hasErrorForm = true;
        errorMessage = `Monto máximo es de ${raffleSetting.maxBetByChance}`;
      } else if (Number(amount) <= 0) {
        hasErrorForm = true;
        errorMessage = 'Monto debe ser mayor a 0';
      } else if (isAmountNotValid) {
        hasErrorForm = true;
        errorMessage = 'El número no es válido';
      }

      newChanceTicketInfo[indexLottery].raffleChanceZodiacBet[indexRaffle].ChanceZodiacBet[
        indexBet
      ] = {
        ...chanceZodiacBetTicketInfo[indexLottery].raffleChanceZodiacBet[indexRaffle]
          .ChanceZodiacBet[indexBet],
        betAmount: amount,
        error: errorMessage,
        hasError: hasErrorForm,
      };

      if (hasErrorForm) {
        setFormHasErrors(true);
      } else {
        setFormHasErrors(false);
      }

      newChanceTicketInfo[indexLottery].totalBet = newChanceTicketInfo[
        indexLottery
      ].raffleChanceZodiacBet.reduce(
        (acc, ChanceZodiac) =>
          ChanceZodiac.ChanceZodiacBet.reduce((acc2, bet) => acc2 + Number(bet.betAmount), 0),
        0,
      );

      dispatch(actionSetChanceZodiacBetTicketInfo(newChanceTicketInfo));
    }
  };

  return {
    chanceZodiacBetTicketInfo,
    deleteLottery,
    deleteLotteryRaffle,
    setEditableRaffle,
    updateChanceBetAmount,
    formHasErrors,
  };
};
